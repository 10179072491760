<template>
  <div>
    <ForgotPasswordDialog ref="forgotPasswordDialog" :client="client" />
    <LoadingOverlay :loading="client.loading" />
    <v-text-field
      :label="$t('mail_or_name')"
      maxlength="60"
      hide-details="auto"
      v-model="input.auth"
      @keydown="onInputKeydown($event)"
    ></v-text-field>
    <v-text-field
      :label="$t('password')"
      :type="showPass ? 'text' : 'password'"
      maxlength="50"
      v-model="input.pass"
      @keydown="onInputKeydown($event)"
      hide-details="auto"
      :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
      @click:append="showPass = !showPass"
    ></v-text-field>
    <v-checkbox
      v-model="input.keepSession"
      :label="$t('keep_session')"
    ></v-checkbox>
    <div class="d-flex mt-3">
      <v-btn small text @click="forgotPass()">{{ $t("forgot_pass") }}</v-btn>
      <v-spacer />
      <v-btn
        id="login-btn"
        color="success"
        @click="submit()"
        :disabled="!valid"
        >{{ $t("login") }}</v-btn
      >
    </div>
  </div>
</template>

<script>
import ForgotPasswordDialog from "./ForgotPasswordDialog";
import LoadingOverlay from "../generic/LoaderOverlay";

export default {
  name: "LoginDialog",
  props: ["client"],
  components: {
    ForgotPasswordDialog,
    LoadingOverlay,
  },
  data: () => ({
    showPass: false,
    sending: false,
    submitted: false,
    input: {
      auth: "",
      pass: "",
      keepSession: true,
    },
  }),
  computed: {
    authValid() {
      return this.input.auth.length >= 4 && this.input.auth.length <= 60;
    },
    passValid() {
      return this.input.pass.length >= 6 && this.input.pass.length <= 50;
    },
    valid() {
      return this.authValid && this.passValid;
    },
  },
  methods: {
    forgotPass() {
      this.$refs.forgotPasswordDialog.show();
    },
    submit() {
      this.input.auth = this.input.auth.trim();
      this.sending = true;
      this.client.account
        .login(this.input.auth, this.input.pass, this.input.keepSession)
        .then((response) => {
          if (!response.success) {
            this.input.pass = "";
            this.$gtag.event("login_fail", {
              event_category: "account",
              event_label: "Login failed",
              value: 1,
            });
          } else {
            this.$gtag.event("login", {
              event_category: "account",
              event_label: "Logged in",
              value: 0,
            });
          }
        });
    },
    onInputKeydown(event) {
      if (event.key === "Enter" && this.valid) {
        this.submit();
      }
    },
  },
};
</script>

<i18n>
{
    "en": {
        "mail_or_name": "E-mail address or username",
        "password": "Password",
        "login": "Login",
        "forgot_pass": "Forgot password?",
        "keep_session": "Save login"
    },
    "de": {
        "mail_or_name": "E-Mail Adresse oder Nutzername",
        "password": "Passwort",
        "login": "Anmelden",
        "forgot_pass": "Passwort vergessen?",
        "keep_session": "Login speichern"
    }
}
</i18n>