<template>
    <SimpleDialog :visible="visible" :title="$t('title')" @hide="hide()" icon="mdi-magnify">
        <LoadingOverlay :loading="client.loading" />
        <PublicGearSearch ref="search" :client="client" @select="$emit('select', $event)"/>
    </SimpleDialog>
</template>

<script>
import SimpleDialog from "../../../generic/SimpleDialog";
import PublicGearSearch from "./PublicGearSearch";
import LoadingOverlay from "../../../generic/LoaderOverlay";

export default {
    name: 'PublicGearSearchDialog',
    props: ['client'],
    components: {
        SimpleDialog,
        PublicGearSearch,
        LoadingOverlay
    },
    data: () => ({
        visible : false
    }),
    methods: {
        show() {
            this.visible = true;
        },
        hide() {
            this.visible = false;
            this.$refs.search.reset();
        }
    }
}
</script>

<i18n>
{
    "en": {
        "title": "Public gear library"
    },
    "de": {
        "title": "Öffentliche Bibliothek"
    }
}
</i18n>