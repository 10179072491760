<template>
    <v-card flat>
        <LoadingOverlay :loading="client.loading" />
        <v-card-title>
            {{ $t('title') }}
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12" md="6" lg="4">
                    <GenericSettings :client="client" />
                </v-col>
                <v-col cols="12" md="6" lg="4">
                    <AccountSettings :client="client" />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import GenericSettings from "./panels/GenericSettings";
import LoadingOverlay from "../generic/LoaderOverlay";
import AccountSettings from "./panels/AccountSettings";

export default {
    name: 'SettingsEditor',
    props: ['client'],
    components: {
        GenericSettings,
        LoadingOverlay,
        AccountSettings
    }
}
</script>

<i18n>
{
    "en": {
        "title": "Settings"
    },
    "de": {
        "title": "Einstellungen"
    }
}
</i18n>