<template>
  <v-simple-table>
    <CreateGearDialog :client="client" :list="list" ref="editDialog" />
    <thead>
      <tr>
        <th></th>
        <th class="text-left">{{ $t("manufacturer") }}</th>
        <th class="text-left">{{ $t("name") }}</th>
        <th class="text-left">{{ $t("category") }}</th>
        <th class="text-left">{{ $t("weight") }}</th>
        <th class="text-left">{{ $t("count") }}</th>
        <th class="text-left">{{ $t("worn") }}</th>
        <th class="text-left">{{ $t("pack") }}</th>
      </tr>
    </thead>
    <tbody>
      <!--Image-->
      <tr v-for="(item, index) of items" :key="item.id">
        <td>
          <img
            v-if="item.img && item.img.length > 0"
            :src="item.img"
            class="icon"
            @click="$emit('showImage', item)"
          />
        </td>
        <!--Manufacturer-->
        <v-menu offset-y @input="onMenuToggle($event)">
          <template v-slot:activator="{ on, attrs }">
            <td
              class="edit-cell"
              v-on="on"
              v-bind="attrs"
              :class="{
                'active-cell': edit.item === item && edit.field === 'brand',
              }"
              @click="setEdit(item, 'brand', index)"
            >
              {{ item.brand }}
            </td>
          </template>
          <v-card
            v-if="!smallView && edit.item === item && edit.field === 'brand'"
            @click.stop=""
          >
            <v-card-text>
              {{ $t("manufacturer") }}:
              <ManufacturerPicker
                style="margin-top: -10px; margin-bottom: -10px"
                :value="item.brand"
                :hidelabel="true"
                :initfocus="true"
                ref="manufacturerPicker"
                @blur="cancelEdit()"
                @input="editValue('brand', $event)"
              />
            </v-card-text>
          </v-card>
        </v-menu>

        <!--Name-->

        <v-menu offset-y @input="onMenuToggle($event)">
          <template v-slot:activator="{ on, attrs }">
            <td
              class="edit-cell"
              v-on="on"
              v-bind="attrs"
              :class="{
                'active-cell': edit.item === item && edit.field === 'name',
              }"
              @click="setEdit(item, 'name', index)"
            >
              {{ item.name }}
            </td>
          </template>
          <v-card
            @click.stop=""
            v-if="!smallView && edit.item === item && edit.field === 'name'"
          >
            <v-card-text>
              {{ $t("name") }}:
              <RestrictedInlineTextfield
                :value="edit.item.name"
                style="margin-top: -10px"
                @input="editValue('name', $event)"
                @blur="cancelEdit()"
                :minlength="2"
                :maxlength="50"
              />
            </v-card-text>
          </v-card>
        </v-menu>

        <!--Category-->

        <v-menu offset-y @input="onMenuToggle($event)">
          <template v-slot:activator="{ on, attrs }">
            <td
              class="edit-cell"
              v-on="on"
              v-bind="attrs"
              :class="{
                'active-cell': edit.item === item && edit.field === 'category',
              }"
              @click="setEdit(item, 'category', index)"
            >
              <div class="d-flex" style="flex-wrap: nowrap">
                <i
                  class="mdi fast-icon"
                  :class="item.category[0]"
                  :style="{ color: getIconColor(item.category) }"
                ></i>
                <div class="my-auto">
                  {{ item.category[1] }}
                </div>
              </div>
            </td>
          </template>
          <v-card
            @click.stop=""
            v-if="!smallView && edit.item === item && edit.field === 'category'"
          >
            <v-card-text>
              {{ $t("category") }}:
              <CategoryPicker
                :list="list"
                :client="client"
                :value="item.category"
                :initfocus="true"
                :standalone="true"
                @input="editValue('category', $event)"
              />
            </v-card-text>
          </v-card>
        </v-menu>

        <!--Weight-->

        <v-menu offset-y @input="onMenuToggle($event)">
          <template v-slot:activator="{ on, attrs }">
            <td
              class="edit-cell"
              v-on="on"
              v-bind="attrs"
              :class="{
                'active-cell': edit.item === item && edit.field === 'weight',
              }"
              @click="setEdit(item, 'weight', index)"
            >
              {{ formatWeight(item.weight) }}
              <template v-if="item.count > 1"
                >({{ formatWeight(item.weight * item.count) }})</template
              >
            </td>
          </template>
          <v-card
            v-if="!smallView && edit.item === item && edit.field === 'weight'"
            @click.stop=""
          >
            <v-card-text>
              {{ $t("weight") }}:
              <WeightInput
                :value="item.weight"
                :standalone="true"
                :unit="unit"
                @input="editValue('weight', $event)"
                @blur="cancelEdit()"
              />
            </v-card-text>
          </v-card>
        </v-menu>

        <!--Count-->

        <v-menu offset-y @input="onMenuToggle($event)">
          <template v-slot:activator="{ on, attrs }">
            <td
              class="edit-cell"
              v-on="on"
              v-bind="attrs"
              :class="{
                'active-cell': edit.item === item && edit.field === 'count',
              }"
              @click="setEdit(item, 'count', index)"
            >
              {{ item.count }}
            </td>
          </template>
          <v-card
            v-if="!smallView && edit.item === item && edit.field === 'count'"
            @click.stop=""
          >
            <v-card-text>
              {{ $t("count") }}:
              <CountInput
                :value="edit.item.count"
                @input="editValue('count', $event)"
              />
            </v-card-text>
          </v-card>
        </v-menu>

        <td>
          <div
            @click="$emit('toggleWear', setItemIndex(item, index))"
            class="checkbox"
          >
            <i
              class="mdi mdi-checkbox-marked info--text fast-icon"
              v-if="item.wear"
            ></i>
            <i class="mdi mdi-checkbox-blank-outline fast-icon" v-else></i>
          </div>
        </td>
        <td>
          <div
            icon
            @click="$emit('togglePack', setItemIndex(item, index))"
            class="checkbox"
          >
            <i
              class="mdi mdi-checkbox-marked success--text fast-icon"
              v-if="item.pack"
            ></i>
            <i class="mdi mdi-checkbox-blank-outline fast-icon" v-else></i>
          </div>
        </td>
        <td>
          <v-btn icon @click="$emit('delete', item)">
            <i class="mdi mdi-trash-can fast-icon"></i>
          </v-btn>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import Globals from "../../../lib/globals";
import CategoryPicker from "../input/CategoryPicker";
import ManufacturerPicker from "../input/ManufacturerPicker";
import UnitConverter from "../../../lib/unit";
import RestrictedInlineTextfield from "../input/RestrictedInlineTextfield";
import WeightInput from "../input/WeightInput";
import CountInput from "../input/CountInput";
import CreateGearDialog from "./CreateGearDialog";

export default {
  name: "GearDisplayTable",
  props: ["list", "editable", "unit", "addable", "client"],
  components: {
    CategoryPicker,
    ManufacturerPicker,
    RestrictedInlineTextfield,
    WeightInput,
    CountInput,
    CreateGearDialog,
  },
  data: () => ({
    edit: { item: undefined, field: undefined }
  }),
  computed: {
    smallView() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return true;
        default:
          return false;
      }
    },
    items() {
      return this.list.items;
    },
    categories() {
      const categories = [];
      for (const item of this.items) {
        let includes = false;
        for (const category of categories) {
          if (category[1] === item.category[1]) {
            includes = true;
            break;
          }
        }
        if (!includes) {
          categories.push(item.category);
        }
      }
      return categories;
    },
    categoryKeys() {
      return this.categories.map(category => category[1]);
    }
  },
  methods: {
    onMenuToggle(value) {
      if (!value) {
        this.cancelEdit();
      }
    },
    getIconColor(category) {
      return Globals.getCategoryColor(this.categoryKeys.indexOf(category[1]));
    },
    formatWeight(weight) {
      return UnitConverter.formatString(weight, this.unit);
    },
    setItemIndex(item, index) {
      return Object.assign(item, { index });
    },
    setEdit(item, field, itemIndex) {
      if (this.smallView) {
        console.log("refs", this.$refs["editDialog"]);
        this.$refs.editDialog.showEdit(item, itemIndex);
      } else {
        this.edit = { item, field };
      }
    },
    submitEdit() {
      this.client.editGearItem(
        this.list,
        this.edit.item,
        this.edit.item,
        this.items.indexOf(this.edit.item)
      );
    },
    editValue(key, value) {
      this.edit.item[key] = value;
      this.submitEdit();
    },
    cancelEdit() {
      this.edit = { item: undefined, field: undefined };
    },
  },
};
</script>

<style scoped>
.edit-cell {
  cursor: pointer;
  border-style: dashed;
  border-width: 1px;
  border-color: transparent;
  user-select: none;
}
.edit-cell:hover {
  border-color: black;
  margin: 0;
}
.active-cell {
  border: 1px dashed blue !important;
  background-color: lightgray;
}
.fast-icon {
  font-size: 1.5rem;
}
.checkbox {
  cursor: pointer;
}
.icon {
  max-width: 2rem;
  max-height: 2rem;
  cursor: zoom-in;
}
</style>

<i18n>
{
    "en": {
        "manufacturer": "Manufacturer",
        "name": "Name",
        "category": "Category",
        "weight": "Weight",
        "count": "Count",
        "worn": "Worn",
        "pack": "Packed"
    },
    "de": {
        "manufacturer": "Hersteller",
        "name": "Name",
        "category": "Kategorie",
        "weight": "Gewicht",
        "count": "Anzahl",
        "worn": "Getragen",
        "pack": "Gepackt"
    }
}
</i18n>