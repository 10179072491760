<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon color="info" v-on="on" v-bind="attrs" class="menu-button">
        <v-icon v-text="value"></v-icon>
        <v-icon class="menu-icon">mdi-menu-down</v-icon>
      </v-btn>
    </template>
    <v-card class="py-2 px-2" style="max-width: 416px">
      <div>{{ $t("title") }}</div>
      <div class="icon-list">
        <div
          v-for="icon of suggested"
          :key="icon"
          :title="getIconName(icon)"
          class="icon-wrapper"
          @click="onSelect(icon)"
        >
          <v-icon v-text="icon" :color="icon === value ? 'success' : ''"></v-icon>
        </div>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "IconPicker",
  props: ["value"],
  data: () => ({
    suggested: [
      "mdi-bag-personal",
      "mdi-tent",
      "mdi-sleep",
      "mdi-wallet-travel",
      "mdi-tshirt-v",
      "mdi-food-apple",
      "mdi-bottle-soda",
      "mdi-airplane",
      "mdi-alert-box",
      "mdi-anchor",
      "mdi-axe",
      "mdi-bag-suitcase",
      "mdi-baguette",
      "mdi-bandage",
      "mdi-apple-safari",
      "mdi-map",
      "mdi-battery-charging",
      "mdi-food-fork-drink",
      "mdi-foot-print",
      "mdi-hammer-wrench",
      "mdi-terrain",
      "mdi-toothbrush",
      "mdi-hanger",
      "mdi-sail-boat",
      "mdi-ferry",
      "mdi-tennis-ball",
      "mdi-run",
      "mdi-ski",
      "mdi-walk",
      "mdi-lifebuoy",
      "mdi-lingerie",
      "mdi-key-chain",
      "mdi-weather-rainy",
      "mdi-wifi",
      "mdi-wheelchair-accessibility",
      "mdi-heart-flash",
      "mdi-bed",
      "mdi-power-plug",
      "mdi-camera",
      "mdi-campfire",
      "mdi-beer",
      "mdi-bike",
      "mdi-car",
      "mdi-train",
      "mdi-umbrella",
      "mdi-account-details",
      "mdi-cellphone-text",
      "mdi-binoculars",
      "mdi-book",
      "mdi-bowl-mix",
      "mdi-briefcase",
      "mdi-bus",
      "mdi-cable-data",
      "mdi-calendar-text",
      "mdi-home",
      "mdi-hospital-box",
      "mdi-cloud",
      "mdi-weather-sunny",
      "mdi-island",
      "mdi-antenna",
      "mdi-city",
      "mdi-dice-6",
      "mdi-star",
      "mdi-flash",
      "mdi-flashlight",
      "mdi-delete",
      "mdi-emoticon-happy",
      "mdi-emoticon-sad",
      "mdi-arm-flex",
      "mdi-arrow-right",
      "mdi-asterisk",
      "mdi-check",
    ],
  }),
  methods: {
    onSelect(icon) {
      this.$emit("input", icon);
    },
    getIconName(iconId) {
        return iconId.substring(4);
    }
  },
};
</script>

<style scoped>
.icon-list {
  display: flex;
  flex-wrap: wrap;
}
.icon-wrapper {
  margin: 0.5rem;
  cursor: pointer;
}
.icon-wrapper:hover * {
  color: blue;
}
.menu-icon {
  position: absolute;
  bottom: -14px;
  right: 0px;
  color: black !important;
}
.menu-button {
  position: relative;
}
</style>

<i18n>
{
    "en": {
        "title": "Select icon",
        "suggestions": "Suggestions"
    },
    "de": {
        "title": "Symbol auswählen",
        "suggestions": "Vorschläge"
    }
}
</i18n>