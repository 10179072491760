<template>
    <div class="imprint-footer" :class="{'large': large}">
        <ImprintDialog ref="imprintDialog" />
        <PrivacyDialog ref="privacyDialog" />
        <div @click="$refs.imprintDialog.show()">
            {{ $t('imprint') }}
        </div>
        <div @click="$refs.privacyDialog.show()">
            {{ $t('privacy') }}
        </div>
    </div>
</template>

<script>
import ImprintDialog from "./imprint/ImprintDialog";
import PrivacyDialog from "./imprint/PrivacyDialog";

export default {
    name: 'ImprintFooter',
    props: ['large'],
    components: {
        ImprintDialog,
        PrivacyDialog
    }
}
</script>

<style scoped>
.imprint-footer {
    display: flex;
    justify-content: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    color: white;
    background-color: rgba(0,0,0,0.5);
    border-radius: .3rem;
}
.large {
    width: 100% !important;
}
.imprint-footer > div {
    margin-left: 1rem;
    margin-right: 1rem;
    cursor: pointer;
}
</style>

<i18n>
{
    "en": {
        "imprint": "Imprint",
        "privacy": "Privacy"
    },
    "de": {
        "imprint": "Impressum",
        "privacy": "Datenschutz"
    }
}
</i18n>