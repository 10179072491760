<template>
  <v-card>
    <v-card-subtitle
      ><v-btn icon @click="$emit('back')" color="info"
        ><v-icon>mdi-arrow-left</v-icon></v-btn
      >
      <span class="my-auto">{{ $t("title") }}</span></v-card-subtitle
    >
    <v-card-text>
      <v-list>
        <v-list-item
          two-line
          v-for="list of results"
          :key="list._id"
          @click="select(list)"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ list.title }}
            </v-list-item-title>
            <v-list-item-subtitle v-html="formatKeywords(list.keywords)">
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <div style="max-width: 52px" class="mb-0">
              <ProfileImageDisplay
                :image="list.account[0].image"
                :userid="list.creator"
                :icon="true"
              />
            </div>
            <small class="text-center mt-0" style="width: 100%">{{
              list.account[0].name
            }}</small>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import ProfileImageDisplay from "../profile/image/ProfileImageDisplay";

export default {
  name: "DiscoverSearchResults",
  props: ["results", "query"],
  components: {
    ProfileImageDisplay,
  },
  methods: {
    formatKeywords(keywords) {
      return (
        "#" +
        keywords
          .join(", #")
          .replace("#" + this.query, "<b>#" + this.query + "</b>")
      );
    },
    select(list) {
      this.$emit('select', list);
    },
  },
};
</script>

<i18n>
{
    "en": {
        "title": "Search results"
    },
    "de": {
        "title": "Suchergebnisse"
    }
}
</i18n>