<template>
  <v-menu offset-y v-if="visible">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" style="width: 32px">
        <ProfileImageDisplay
          :image="client.account.profile.image"
          :userid="client.account.profile.id"
          :icon="true"
        />
      </div>
    </template>
    <v-list dense>
      <v-subheader>
        {{ client.account.profile.name }}
      </v-subheader>
      <v-list-item @click="toDashboard()">
        <v-list-item-icon>
          <v-icon>mdi-account</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          {{ $t("profile") }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="client.account.logout()">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          {{ $t('logout') }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import ProfileImageDisplay from "../profile/image/ProfileImageDisplay";

export default {
  name: "AccountDropdown",
  props: ["client"],
  components: {
    ProfileImageDisplay,
  },
  computed: {
    visible() {
      return this.client.account.profile.image !== undefined;
    },
  },
  methods: {
    toDashboard() {
        window.location.hash = '';
        this.client.app.setTitle('Dashboard');
        this.client.app.setPage('editor');
    },
  },
};
</script>

<i18n>
{
    "en": {
        "profile": "Dashboard",
        "logout": "Logout"
    },
    "de": {
        "profile": "Dashboard",
        "Logout": "Abmelden"
    }
}
</i18n>