<template>
  <v-list>
    <v-list-item v-for="(item, index) of list" :key="index">
        <v-list-item-icon>
            <v-icon color="info" v-text="item.category[0]"></v-icon>
        </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.brand }} {{ item.name }} <template v-if="item.category[1] !== 'Import'">({{item.category[1]}})</template>
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ formatWeight(item.weight, item.count) }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn icon color="error" @click="$emit('remove', index)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import UnitConverter from "../../../lib/unit";
import Globals from "../../../lib/globals";

export default {
  name: "ImportListEdit",
  props: ["list", "unit"],
  methods: {
    formatWeight(weight, count) {
      if(count <= 1) {
        return UnitConverter.formatString(weight, this.unit);
      } else {
        return UnitConverter.formatString(weight * count, this.unit) + ' (' + count + 'x ' + UnitConverter.formatString(weight, this.unit) + ')';
      }
    },
    getCategoryIcon(category) {
        console.log("Gettin icon for", category)
      return Globals.category_icons[category];
    },
    getCategoryColor(category) {
      return Globals.category_colors[category];
    },
  },
};
</script>