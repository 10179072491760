<template>
  <div class="go-banner bg-0">
    <v-btn class="btn" large color="info" @click="$emit('click')"
      ><v-icon left>mdi-arrow-right</v-icon>{{ $t("label") }}</v-btn
    >
    <div class="footer">
      <ImprintFooter />
    </div>
  </div>
</template>

<script>
import ImprintFooter from "./ImprintFooter";

export default {
  name: "GoBanner",
  components: {
    ImprintFooter,
  },
};
</script>

<style scoped>
.go-banner {
  width: 100vw;
  height: 300px;
  position: relative;
  background-position: center;
  background-size: cover;
  background-image: url("../../assets/bg/bg_2.webp");
  mask-image: linear-gradient(to bottom, transparent, black 50px, black);
}
.btn {
  margin-left: 50%;
  margin-top: 150px;
  transform: translate(-50%, -50%);
  transition: 300ms;
}
.btn:hover {
  transform: translate(-50%, -50%) scale(1.2);
}
.footer {
    position: absolute;
    bottom: .5rem;
    width: 100%;
}
</style>

<i18n>
{
    "en": {
        "label": "Get started"
    },
    "de": {
        "label": "Los geht`s"
    }
}
</i18n>