<template>
    <div>
        <v-tabs v-model="tab" @change="tabChange($event)">
            <v-tab>
                {{ $t('chart') }}
            </v-tab>
            <v-tab>
                {{ $t('table') }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item>
                <WeightPieChart :list="list" :unit="unit" ref="pieChart"/>
            </v-tab-item>
            <v-tab-item>
                <WeightTableChart v-if="tab === 1" :list="list" :unit="unit"/>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import WeightPieChart from "./WeightPieChart";
import WeightTableChart from "./WeightTableChart";

export default {
    name: 'WeightChart',
    props: ['list', 'unit'],
    components: {
        WeightPieChart,
        WeightTableChart
    },
    data: () => ({
        tab: 0
    }),
    methods: {
        renderAfter(delay) {
            if(this.tab === 0) {
                this.$refs.pieChart.renderAfter(delay);
            }
        },
        tabChange(tab) {
            if(tab === 0) {
                this.renderAfter(100);
            }
        }
    }
}
</script>

<i18n>
{
    "en": {
        "chart": "Chart",
        "table": "Table"
    },
    "de": {
        "chart": "Diagramm",
        "table": "Tabelle"
    }
}
</i18n>