<template>
    <SimpleDialog :title="$t('title') + ' (' + value.length + ')'" :visible="visible" @hide="hide()" icon="mdi-pencil">
        <LoadingOverlay :loading="client.loading" />
        <KeywordSelector v-model="value" class="mt-3"/>
        <template slot="actions">
            <v-btn color="success"  @click="save()">
                <v-icon left>mdi-content-save</v-icon> {{ $t('save') }}
            </v-btn>
        </template>
    </SimpleDialog>
</template>

<script>
import SimpleDialog from "../../generic/SimpleDialog";
import KeywordSelector from "../input/KeywordSelector";
import LoadingOverlay from "../../generic/LoaderOverlay";

export default {
    name: 'EditKeywordsDialog',
    props: ['list', 'client'],
    components: {
        SimpleDialog,
        KeywordSelector,
        LoadingOverlay
    },
    data: () => ({
        value: [],
        visible: false
    }),
    methods: {
        hide() {
            this.visible = false;
        },
        show() {
            this.value = [...this.list.keywords];
            this.visible = true;
        },
        save() {
            this.client.lists.changeSetting(this.list._id, 'keywords', this.value, false).then(response => {
                if(response.success) {
                    this.list.keywords = this.value;
                    this.hide();
                }
            });
        }
    }
}
</script>

<i18n>
{
    "en": {
        "title": "Edit keywords",
        "save": "Save"
    },
    "de": {
        "title": "Schlüsselwörter bearbeiten",
        "save": "Speichern"
    }
}
</i18n>