<template>
  <v-combobox
    :value="value"
    @input="$emit('input', $event)"
    :items="list"
    @blur="$emit('blur')"
    :label="hidelabel ? '' : $t('label')"
    :validate-on-blur="false"
    @keydown="$emit('keydown', $event)"
    :disabled="disabled"
    ref="combobox"
  ></v-combobox>
</template>

<script>
import Manufacturers from "../../../lib/manufacturers";

export default {
  name: "ManufacturerPicker",
  props: ["value", "disabled", "hidelabel", "initfocus"],
  created() {
    if (this.value) {
      this.inputValue = this.value;
    }
  },
  mounted() {
    if(this.initfocus) {
      this.$refs.combobox.focus();
    }
  },
  computed: {
    list() {
      return Manufacturers;
    },
  },
};
</script>

<i18n>
{
    "en": {
        "label": "Manufacturer (Optional)"
    },
    "de": {
        "label": "Hersteller (Optional)"
    }
}
</i18n>