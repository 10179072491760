
const Config = {
    Discover: {
        ReloadMinDelay: 5, //Mins
        KeywordLength: {
            Min: 2,
            Max: 25
        }
    },
    Account: {
        PasswordLength: {
            Min: 6,
            Max: 50
        }
    }
}

export default Config;