<template>
  <v-card :flat="!list.imgs">
    <ImageDisplayOverlay ref="imageDisplay" />
    <v-card-text>
      <v-row>
        <v-col
          v-for="(img, index) of images"
          :key="img"
          cols="12"
          md="4"
          class="mx-auto"
        >
          <div
            class="image"
            @click="$refs.imageDisplay.show(img + '?t=' + timecode)"
          >
            <LoaderImage class="img" :src="img + '?t=' + timecode" />
          </div>
          <v-row>
            <v-spacer />
            <v-btn icon @click="remove(index)"
              ><v-icon>mdi-delete</v-icon></v-btn
            >
            <v-spacer />
          </v-row>
        </v-col>
      </v-row>
      <AddImageSelector
        @error="client.app.showError($t('image_error'))"
        class="mt-3"
        v-if="editable"
        :list="list"
        :client="client"
        @input="add($event)"
        :disabled="list.imgs >= 3"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import AddImageSelector from "../../input/AddImageSelector";
import LoaderImage from "../../../generic/LoaderImage";
import ImageDisplayOverlay from "./ImageDisplayOverlay";

export default {
  name: "ImageListView",
  props: ["list", "client", "editable"],
  components: {
    AddImageSelector,
    LoaderImage,
    ImageDisplayOverlay,
  },
  data: () => ({
    timecode: Date.now(),
  }),
  computed: {
    images() {
      const images = [];
      for (let i = 0; i < this.list.imgs; i++) {
        images.push(`images/list/${this.list._id}/${i}.jpg`);
      }
      return images;
    },
  },
  methods: {
    remove(index) {
      this.client.lists.removeImage(this.list._id, index).then((result) => {
        if (result.success) {
          this.list.imgs--;
          if (result.orderChanged) {
            this.onImagesChanged();
          }
        }
      });
    },
    add(image) {
      this.client.lists.addImage(this.list._id, image).then((response) => {
        if (response.success) {
          this.list.imgs++;
          this.onImagesChanged();
        }
      });
    },
    onImagesChanged() {
      this.timecode = Date.now();
    },
  },
};
</script>

<style scoped>
.image .img {
  max-width: 100%;
  cursor: zoom-in;
}
</style>

<i18n>
{
  "en": {
    "image_error": "Could not read image file."
  },
  "de": {
    "image_error": "Bilddatei konnte nicht gelesen werden."
  }
}
</i18n>