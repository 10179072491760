<template>
  <div class="d-flex justify-center">
    <v-row class="px-2 descriptions">
      <v-col cols="12" md="4">
        <v-card flat>
          <v-card-title style="flex-wrap: nowrap;">
              <v-icon class="mr-2">mdi-lightbulb</v-icon>
            {{ $t("organize_header") }}
          </v-card-title>
          <v-card-text>
            <DescriptionOrganize />
            <img src="../../assets/clipart/person_0.webp" class="clipart"/>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card flat>
          <v-card-title style="flex-wrap: nowrap;">
              <v-icon class="mr-2">mdi-bag-personal-outline</v-icon>
            {{ $t("packing_header") }}
          </v-card-title>
          <v-card-text>
            <DescriptionPacking />
            <img src="../../assets/clipart/person_1.webp" class="clipart"/>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card flat>
          <v-card-title style="flex-wrap: nowrap;">
              <v-icon class="mr-2">mdi-account-group</v-icon>
            {{ $t("share_header") }}
          </v-card-title>
          <v-card-text>
            <DescriptionInspire />
            <img src="../../assets/clipart/person_2.webp" class="clipart"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DescriptionOrganize from "./descriptions/DescriptionOrganize";
import DescriptionInspire from "./descriptions/DescriptionInspire";
import DescriptionPacking from "./descriptions/DescriptionPacking";

export default {
  name: "DescriptionText",
  components: {
    DescriptionOrganize,
    DescriptionInspire,
    DescriptionPacking
  }
};
</script>

<style scoped>
.clipart {
  max-width: 100%;
}
.descriptions {
  max-width: 1200px;
}
</style>

<i18n>
{
    "en": {
        "headline": "Plan your adventure.",
        "organize_header": "Organize your gear",
        "packing_header": "Forget nothing",
        "share_header": "Inspire others"
    },
    "de": {
        "headline": "Plane dein Abenteuer",
        "organize_header": "Organisiere deine Ausrüstung",
        "packing_header": "Vergiss nichts mehr",
        "share_header": "Inspiriere Andere"
    }
}
</i18n>