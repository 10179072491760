<template>
    <div style="position: relative;">
        <LoadingOverlay :loading="loading" />
        <img :src="src" @loadstart="loading=true" @load="loading=false" style="max-width: 100%;" />
    </div>
</template>

<script>
import LoadingOverlay from "./LoaderOverlay";

export default {
    name: 'LoaderImage',
    props: ['src'],
    components: {
        LoadingOverlay
    },
    data: () => ({
        loading: true
    })
}
</script>