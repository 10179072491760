<template>
  <v-card>
    <DeleteAccountDialog ref="deleteAccountDialog" :client="client" />
    <v-card-title>
      {{ $t("title") }}
    </v-card-title>
    <v-card-text>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t("change_pass") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ChangePasswordForm :client="client" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-btn small text color="error" @click="$refs.deleteAccountDialog.show()" class="mt-2">
        <v-icon left>mdi-trash-can</v-icon> {{ $t("delete_account") }}
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import DeleteAccountDialog from "./DeleteAccountDialog";
import ChangePasswordForm from "../input/ChangePasswordForm";

export default {
  name: "AccountSettings",
  props: ["client"],
  components: {
    DeleteAccountDialog,
    ChangePasswordForm,
  },
  methods: {
    onConfirmDeleteAccount() {
      this.client.account.delete();
      this.$gtag.event('delete_account', {
        'event_category': 'account',
        'event_label': 'Account deleted',
        'value': 0
      });
    },
  },
};
</script>

<i18n>
{
    "en": {
        "title": "Account",
        "delete_account": "Delete my account",
        "delete_account_confirm": "Do you really want to delete your account forever? ",
        "change_pass": "Change password"
    },
    "de": {
        "title": "Account",
        "delete_account": "Meinen Account löschen",
        "delete_account_confirm": "Möchten sie ihren Benutzeraccount wirklich unwiederruflich löschen?",
        "change_pass": "Passwort ändern"
    }
}
</i18n>