<template>
  <div class="splash-title">
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-spacer />
          <v-col>
            <v-row>
              <v-spacer />
              <img
                class="big-logo mb-3"
                src="../../assets/logo/logo_small.png"
              >
                            <v-chip info class="beta-badge" small>
                BETA
              </v-chip>
              <v-spacer />
            </v-row>
          </v-col>
          <v-spacer />
        </v-row>
      </v-col>
      <v-spacer />
      <v-col cols="12" class="px-5">
        <LoginRegisterCard
          :client="client"
          class="mx-auto"
          ref="loginRegister"
          style="max-width: 600px; margin-bottom: 100px"
        />
      </v-col>
      <v-spacer />
    </v-row>
    <div class="d-flex justify-center">
      <PWAInstallButton />
    </div>
    <div class="down-arrow">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="$emit('down')"
            v-on="on"
            v-bind="attrs"
            class="down-btn"
          >
            <v-icon color="white">mdi-arrow-down</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("more") }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import LoginRegisterCard from "../account/LoginRegisterCard";
import PWAInstallButton from "./PWAInstallButton";

export default {
  name: "SplashTitle",
  props: ["client"],
  components: {
    LoginRegisterCard,
    PWAInstallButton,
  },
  methods: {
    onLogin() {
      this.client.app.setPage("login");
    },
    showRegister() {
      this.$refs.loginRegister.showRegister();
    },
  },
};
</script>

<style scoped>
.splash-title {
  width: 100%;
  min-height: calc(100vh + 46px);
  background-position: center;
  background-size: cover;
  mask-image: linear-gradient(to bottom, black calc(100% - 46px), transparent);
  position: relative;
  background-image: url("../../assets/bg/bg_0.webp");
  background-size: cover;
}
.big-logo {
  width: 300px;
}
.down-arrow {
  position: absolute;
  bottom: 56px;
  left: 50%;
  transform: translateX(-50%);
}
.down-arrow * {
  font-size: 2rem;
}
.down-btn {
  transition: 300ms;
  transform-origin: center;
}
.down-btn:hover {
  transform: scale(1.6);
}
.beta-badge {
  position: absolute;
  margin-left: 50%;
  left: 150px;
  top: 8.5rem;
}
</style>

<i18n>
{
    "en": {
        "slogan": "Plan your bag.",
        "login": "Login",
        "test_first": "Try without account",
        "more": "More information"
    },
    "de": {
        "slogan": "Plane dein Gepäck",
        "login": "Einloggen",
        "test_first": "Ohne Account testen",
        "more": "Mehr Informationen"
    }
}
</i18n>