<template>
  <div class="d-flex">
    <v-spacer />
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn small v-on="on" v-bind="attrs" :disabled="disabled">
          <v-icon left>mdi-plus</v-icon> {{ $t("label") }}
        </v-btn>
      </template>
      <v-card>
        <v-card-text>
          <input type="file" accept="image/*" @input="onInput($event)" />
        </v-card-text>
      </v-card>
    </v-menu>

    <v-spacer />
  </div>
</template>

<script>
import ImageUtil from "../../../lib/image";

export default {
  name: "AddImageSelector",
  props: ['disabled'],
  data: () => ({
    error: false,
    image: undefined,
  }),
  methods: {
    fileReadError() {
      this.error = true;
      this.$emit('error');
    },
    fileLoad(event) {
      this.image = new Image();
      this.image.src = event.currentTarget.result;
      this.image.onload = this.imageLoad;
    },
    imageLoad() {
      const data = ImageUtil.fitSize(this.image, 720, 720);
      this.$emit("input", data);
    },
    onInput(event) {
      const fileReader = new FileReader();
      fileReader.onerror = this.fileReadError;
      fileReader.onload = this.fileLoad;
      fileReader.readAsDataURL(event.target.files[0]);
    },
  },
};
</script>

<i18n>
{
    "en": {
        "label": "Add image"
    },
    "de": {
        "label": "Bild hinzufügen"
    }
}
</i18n>