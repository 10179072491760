<template>
  <SimpleDialog
    :visible="visible"
    @hide="hide()"
    :title="edit ? $t('title_edit') : $t('title')"
    :icon="edit ? 'mdi-pencil' : 'mdi-plus'"
  >
    <LoadingOverlay :loading="client.loading" />
    <v-tabs-items :value="addImage ? 1 : 0" :touchless="true" class="mt-3">
      <v-tab-item>
        <v-card v-if="suggestedItem !== undefined && !suggestionAccepted">
          <v-card-text>
            {{ $t("do_you_mean") }}?
            <v-list-item dense>
              <v-list-item-icon
                v-if="suggestedItem.img && suggestedItem.img.length > 0"
              >
                <img
                  style="max-width: 2rem; max-height: 2rem"
                  :src="suggestedItem.img"
                />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ suggestedItem.brand }} {{ suggestedItem.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ formatWeight(suggestedItem.weight) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-row>
              <v-spacer />
              <v-btn small text @click="denySuggestion()">
                {{ $t("no") }}
              </v-btn>
              <v-btn small text @click="acceptSuggestion()" color="success">
                {{ $t("yes") }}
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
        <v-row class="mt-4">
          <v-col cols="12" sm="6">
            <v-text-field
              :label="$t('item_name')"
              v-model="input.name"
              @blur="onNameBlur()"
              @keydown="inputKeydown($event)"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <ManufacturerPicker
              v-model="input.brand"
              @keydown="inputKeydown($event)"
            />
          </v-col>
        </v-row>
        <CategoryPicker
          v-model="input.category"
          @keydown="inputKeydown($event)"
          :list="list"
          :client="client"
        ></CategoryPicker>
        <!--
    <v-select
      :items="categories"
      v-model="input.category"
      item-text="label"
      item-value="key"
      :label="$t('category')"
      
    >
      <v-icon
        slot="append"
        color="primary"
        v-text="getIcon(input.category)"
      ></v-icon>
    </v-select>
    -->
        <v-row class="mt-2">
          <v-col cols="12" sm="6">
            <WeightInput
              :unit="client.account.profile.unit"
              :value="weightInput"
              @input="onWeightInput($event)"
              @keydown="inputKeydown($event)"
              ref="weightInput"
            />
          </v-col>
          <v-col v-if="canPickCount" cols="12" sm="6">
            <v-text-field
              :label="$t('item_count')"
              v-model="input.count"
              type="number"
              @keydown="inputKeydown($event)"
              min="1"
              max="1000"
            >
              <div slot="append">
                <v-icon v-if="input.count > 1" @click="countMinus()"
                  >mdi-minus</v-icon
                >
                <v-icon v-if="input.count < 1000" @click="countPlus()"
                  >mdi-plus</v-icon
                >
              </div>
            </v-text-field>
          </v-col>
        </v-row>
        <v-expansion-panels class="mx-1" accordion multiple>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-icon left style="max-width: min-content">mdi-image</v-icon>
              {{ $t("image") }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
               <div
          v-if="input.img && input.img.length > 0"
          class="image-preview mt-2"
        >
          <img :src="input.img" />
          <v-btn
            fab
            absolute
            style="bottom: 0; right: -26px"
            @click="addImage = true"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            fab
            small
            absolute
            style="bottom: 64px; right: -18px"
            color="error"
            @click="
              checkDeleteImage();
              input.img = '';
            "
          >
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </div>
              <v-btn small @click="addImage = true" class="mt-2 mb-2" v-else
                ><v-icon left>mdi-plus</v-icon> {{ $t("add_image") }}</v-btn
              >
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-icon left style="max-width: min-content"
                >mdi-note-outline</v-icon
              >
              {{ $t("notes") }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-textarea
                :label="$t('enter_notes')"
                rows="5"
                solo
                maxlength="200"
                v-model="input.note"
                :hint="`${input.note.length}/200`"
              ></v-textarea>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-checkbox
          v-if="canPickSave"
          :disabled="edit"
          :label="$t('add_to_collection')"
          v-model="save"
        />
        <div class="d-flex">
          <v-spacer />
          <v-btn color="success" :disabled="!valid" @click="submit()">
            <v-icon left>mdi-plus</v-icon>
            {{ edit ? $t("save") : $t("confirm") }}
          </v-btn>
        </div>
      </v-tab-item>
      <v-tab-item>
        <AddImageForm
          @abort="addImage = false"
          :client="client"
          @input="onImageInput($event)"
        />
      </v-tab-item>
    </v-tabs-items>
  </SimpleDialog>
</template>

<script>
import SimpleDialog from "../../generic/SimpleDialog";
import LoadingOverlay from "../../generic/LoaderOverlay";
import WeightInput from "../input/WeightInput";
import ManufacturerPicker from "../input/ManufacturerPicker";
import Globals from "../../../lib/globals";
import UnitFormatter from "../../../lib/unit";
import CategoryPicker from "../input/CategoryPicker";
import AddImageForm from "./image/AddImageForm";

export default {
  name: "CreateGearDialog",
  props: ["client", "mode", "list"],
  components: {
    SimpleDialog,
    LoadingOverlay,
    WeightInput,
    ManufacturerPicker,
    CategoryPicker,
    AddImageForm,
  },
  data: () => ({
    visible: false,
    edit: false,
    editItem: undefined,
    editIndex: -1,
    addImage: false,
    weightInput: 0,
    input: {
      name: "",
      brand: "",
      weight: 0,
      category: ["mdi-bag-personal", ""],
      count: 1,
      img: "",
      note: "",
    },
    imageId: undefined,
    deniedSuggestions: [],
    save: true,
    suggestedItem: undefined,
    suggestionAccepted: false,
  }),
  methods: {
    denySuggestion() {
      this.deniedSuggestions.push(this.input.name.toLowerCase());
      this.suggestedItem = undefined;
    },
    acceptSuggestion() {
      this.suggestionAccepted = true;
      this.input.name = this.suggestedItem.name;
      this.input.brand = this.suggestedItem.brand;
      this.$refs.weightInput.applyGrams(this.suggestedItem.weight);
      this.input.img = this.suggestedItem.img;
    },
    revertSuggestion() {
      this.suggestedItem = undefined;
      this.suggestionAccepted = false;
      this.input.brand = "";
      this.input.weight = 0;
      this.weightInput = 0;
      this.input.category = ["mdi-bag-personal", ""];
    },
    formatWeight(weight) {
      return UnitFormatter.formatString(
        weight,
        this.client.account.profile.unit
      );
    },
    onNameBlur() {
      if (
        this.input.name.trim().length >= 6 &&
        !this.deniedSuggestions.includes(this.input.name.trim().toLowerCase())
      ) {
        this.client.gear
          .queryPublicMatchingName(this.input.name.trim())
          .then((response) => {
            if (response.success && response.found) {
              this.suggestedItem = response.item;
            }
          });
      }
    },
    hide() {
      this.visible = false;
      this.reset();
    },
    show() {
      this.edit = false;
      this.visible = true;
      this.client.app.backMethod = () => {
        this.hide();
      };
    },
    countMinus() {
      if (this.input.count > 1) this.input.count--;
    },
    countPlus() {
      if (this.input.count < 1000) this.input.count++;
    },
    getIcon(category) {
      return Globals.category_icons[category];
    },
    getColor(category) {
      return Globals.category_colors[category];
    },
    inputKeydown(event) {
      if (event.key === "Enter" && this.valid) {
        this.submit();
      }
    },
    showEdit(item, index) {
      this.input.name = item.name;
      this.input.brand = item.brand;
      this.save = item._id !== undefined;
      this.input.category = item.category;
      console.log("Edit category", item.category);
      this.input.count = item.count;
      this.weightInput = item.weight;
      this.input.weight = item.weight;
      this.input.img = item.img;
      this.input.note = item.note ? item.note : '';
      this.editItem = item;
      this.editIndex = index;
      this.edit = true;
      this.visible = true;
      this.client.app.backMethod = () => {
        this.hide();
      };
    },
    onWeightInput(weightData) {
      if (!isNaN(weightData.value)) {
        this.weightInput = weightData.value;
        this.input.weight = weightData.grams;
      }
    },
    onCreateCategory(category) {
      this.client.account.addCategory(category);
    },
    onRemoveCategory(category) {
      this.client.account.removeCategory(category);
    },
    checkDeleteImage() {
      if (
        this.input.img &&
        this.input.img.length > 0 &&
        this.input.img.startsWith(
          `${window.location.protocol}//${window.location.host}`
        )
      ) {
        const delImg = this.input.img.substring(
          this.input.img.lastIndexOf("/") + 1,
          this.input.img.length - 4
        );
        this.client.gear.deleteImage(delImg);
      }
    },
    onImageInput(evt) {
      this.checkDeleteImage();
      this.input.img = evt.url;
      this.imageId = evt.id;
      this.addImage = false;
    },
    reset() {
      this.input.name = "";
      this.input.img = "";
    },
    submit() {
      this.input.name = this.input.name.trim();
      this.input.brand = this.input.brand.trim();
      if (this.edit) {
        this.client.editGearItem(
          this.list,
          this.editItem,
          this.input,
          this.editIndex
        );
      } else {
        /*if (this.suggestionAccepted && !this.save) {
          this.$emit("createdItem", {
            id: this.suggestedItem._id,
            count: this.input.count,
          });
        } else 
        */
        if (this.save) {
          this.client.gear
            .create(
              this.input.name,
              this.input.brand,
              this.input.category,
              this.input.weight,
              this.input.img,
              this.input.note,
              !(this.suggestionAccepted && this.save)
            )
            .then((response) => {
              if (response.success) {
                if (
                  this.input.img &&
                  this.input.img.length > 0 &&
                  this.imageId !== undefined
                ) {
                  this.client.gear.setImage(response.item, this.imageId);
                }
                this.$emit("createdItem", {
                  id: response.item,
                  count: this.input.count,
                });
                this.reset();
              }
            });
        } else {
          this.$emit("createdItem", { ...this.input });
          this.reset();
        }
      }
      this.$refs.weightInput.reset();
      this.visible = false;
    },
  },
  computed: {
    categories() {
      const categories = [];
      for (const key of Globals.gear_categories) {
        categories.push({ key, label: this.$i18n.t("category_" + key) });
      }
      return categories;
    },
    nameValid() {
      return (
        this.input.name.trim().length >= 2 &&
        this.input.name.trim().length <= 50
      );
    },
    brandValid() {
      return (
        this.input.brand.trim().length >= 1 &&
        this.input.brand.trim().length <= 50
      );
    },
    valid() {
      return this.nameValid && this.input.category[1].length > 0;
    },
    canPickSave() {
      return this.mode !== "gear";
    },
    canPickCount() {
      return this.mode !== "gear";
    },
  },
};
</script>

<style scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.image-preview {
  position: relative;
  width: fit-content;
}
.image-preview img {
  max-width: 256px;
  border: 2px solid gray;
}
</style>

<i18n>
{
    "en": {
        "title": "Add gear item",
        "title_edit": "Edit gear item",
        "item_name": "Item name",
        "category": "Category",
        "weight": "Weight",
        "add_to_collection": "Add to my gear collection",
        "confirm": "Add",
        "item_brand": "Brand (optional)",
        "item_count": "Amount",
        "do_you_mean": "Accept suggestion",
        "no": "No",
        "yes": "Yes",
        "suggestion_accepted": "Suggestion accepted",
        "undo": "Undo",
        "save": "Save",
        "image": "Image (optional)",
        "add_image": "Select image",
        "image_url": "Image URL (Copy it from a store page for example)",
        "notes": "Notes (optional)",
        "enter_notes": "Enter notes..."
    },
    "de": {
        "title": "Neue Ausrüstung hinzufügen",
        "title_edit": "Ausrüstung bearbeiten",
        "item_name": "Bezeichnung",
        "category": "Kategorie",
        "weight": "Gewicht",
        "add_to_collection": "In meiner Ausrüstung speichern",
        "confirm": "Hinzufügen",
        "item_brand": "Hersteller (Optional)",
        "item_count": "Anzahl",
        "do_you_mean": "Vorschlag übernehmen",
        "no": "Nein",
        "yes": "Ja",
        "suggestion_accepted": "Vorschlag übernommen",
        "undo": "Rückgängig",
        "save": "Speichern",
        "image": "Foto (optional)",
        "add_image": "Foto auswählen",
        "image_url": "Bild-URL (z.B. kopiert von der Hersteller-Website)",
        "notes": "Notizen (optional)",
        "enter_notes": "Notizen eingeben..."
    }
}
</i18n>