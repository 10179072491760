var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('abort')}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t("title"))+" ")])],1),_c('v-card',{attrs:{"flat":""}},[_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.$t("title_mode"))+" ")]),_c('v-card-text',[_c('v-radio-group',{on:{"change":function($event){_vm.loaded = false;
          _vm.loading = false;
          _vm.error = false;}},model:{value:(_vm.mode),callback:function ($$v) {_vm.mode=$$v},expression:"mode"}},[_c('v-radio',{attrs:{"value":"url","label":_vm.$t('url')}}),_c('v-radio',{attrs:{"value":"upload","label":_vm.$t('upload')}})],1)],1)],1),_c('v-card',{attrs:{"flat":""}},[_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.$t(_vm.mode))+" ")]),_c('v-card-text',[(_vm.mode === 'url')?[_vm._v(" "+_vm._s(_vm.$t("url_explain"))+" "),_c('v-text-field',{attrs:{"label":_vm.$t('paste_url'),"prepend-icon":"mdi-web","disabled":_vm.loading},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}},[(_vm.loading)?_c('template',{slot:"append"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"info"}})],1):_vm._e(),(_vm.error)?_c('template',{slot:"append"},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-alert-circle ")])],1):_vm._e(),(_vm.loaded)?_c('template',{slot:"append"},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")])],1):_vm._e()],2),_c('img',{staticClass:"img-preview",attrs:{"src":_vm.url},on:{"load":function($event){_vm.loaded = true;
            _vm.loading = false;
            _vm.error = false;},"loadstart":function($event){_vm.loading = true;
            _vm.loaded = false;
            _vm.error = false;},"error":function($event){_vm.loading = false;
            _vm.loaded = false;
            _vm.error = true;}}})]:_vm._e(),(_vm.mode === 'upload')?[_vm._v(" "+_vm._s(_vm.$t("upload_explain"))+" "),_c('br'),_c('br'),(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"info"}}):_c('input',{attrs:{"type":"file","accept":"image/*"},on:{"input":function($event){return _vm.onImageInput($event)}}}),(_vm.loaded)?_c('img',{staticClass:"mt-1",attrs:{"src":_vm.data}}):_vm._e()]:_vm._e()],2),_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"success","disabled":!_vm.loaded},on:{"click":function($event){return _vm.submit()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check")]),_vm._v(" "+_vm._s(_vm.$t("save"))+" ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }