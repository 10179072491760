<template>
  <v-overlay :value="visible" opacity="0.8" @click="hide()">
    <div @click="hide()">
      <img :src="src"/>
      <br />
      <div class="d-flex">
        <v-spacer />
        <v-btn @click="hide()">
          <v-icon left>mdi-close</v-icon> {{ $t("close") }}
        </v-btn>
        <v-spacer />
      </div>
    </div>
  </v-overlay>
</template>

<script>
export default {
  name: "ImageDisplayOverlay",
  data: () => ({
    visible: false,
    src: undefined,
  }),
  methods: {
    show(src) {
      this.src = src;
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
  },
};
</script>

<style scoped>
img {
    max-width: 100vw;
    max-height: 80vh;
}
</style>

<i18n>
{
    "en": {
        "close": "Close"
    },
    "de": {
        "close": "Schließen"
    }
}
</i18n>