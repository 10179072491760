<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-on="on" v-bind="attrs">
        <v-icon>mdi-download</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item @click="downloadCSV()">
        <v-list-item-icon>
          <v-icon>mdi-table-arrow-down</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("table") }} (.csv)</v-list-item-title>
          <v-list-item-subtitle>
            {{ $t("for_office_programs") }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import UnitConverter from "../../../lib/unit";
export default {
  name: "DownloadMenu",
  props: ["client", "list"],
  methods: {
    downloadCSV() {
      let csv = `${this.$i18n.t("name")}, ${this.$i18n.t(
        "brand"
      )}, ${this.$i18n.t("weight_per_item")}, ${this.$i18n.t(
        "amount"
      )}, ${this.$i18n.t("total_weight")}, ${this.$i18n.t(
        "category"
      )}, ${this.$i18n.t('icon')}, ${this.$i18n.t("wear")}, ${this.$i18n.t("pack")}, ${this.$i18n.t("image")}\n`;
      for (const item of this.list.items) {
        if (this.client.account.profile.unit === "pound") {
          csv += `${item.name}, ${item.brand}, ${UnitConverter.toOunce(
            item.weight
          )}, ${item.count}, ${
            UnitConverter.toOunce(item.weight) * item.count
          }, ${item.category[1]}, ${item.category[0]}, ${item.wear}, ${item.pack}, ${item.img ? item.img : ''}`;
        } else {
          csv += `${item.name}, ${item.brand}, ${item.weight}, ${item.count}, ${
            item.weight * item.count
          }, ${item.category[1]}, ${item.category[0]}, ${item.wear}, ${item.pack}, ${item.img ? item.img : ''}`;
        }
        csv += "\n";
      }
      this.download(this.list.title + ".csv", csv);
    },
    download(filename, text) {
      while (filename.includes(" ")) {
        filename = filename.replace(" ", "_");
      }
      var pom = document.createElement("a");
      pom.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
      );
      pom.setAttribute("download", filename);

      if (document.createEvent) {
        var event = document.createEvent("MouseEvents");
        event.initEvent("click", true, true);
        pom.dispatchEvent(event);
      } else {
        pom.click();
      }
    },
  },
};
</script>

<i18n>
{
    "en": {
        "table": "Download table",
        "name": "Name",
        "brand": "Brand",
        "weight_per_item": "Weight (per item)",
        "amount": "Count",
        "total_weight": "Total weight",
        "for_office_programs": "For office programs",
        "category": "Category",
        "image": "Image",
        "icon": "Icon",
        "wear": "Worn on body",
        "pack": "Packed"
    },
    "de": {
        "table": "Tabelle herunterladen",
        "name": "Name",
        "brand": "Hersteller",
        "weight_per_item": "Gewicht (pro Stück)",
        "amount": "Anzahl",
        "total_weight": "Gesamtgewicht",
        "for_office_programs": "Für Office-Programme",
        "category": "Kategorie",
        "image": "Foto",
        "icon": "Icon",
        "wear": "Am Körper getragen",
        "pack": "Gepackt"
    }
}
</i18n>