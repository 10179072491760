<template>
  <div class="edit-panel">
    <EditorToolbar @toggleMenu="$refs.menu.toggle()" />
    <div class="content-view">
      <EditorMenu
        ref="menu"
        :small="small"
        :client="client"
        @nav="navigate($event)"
      />
      <ListsEditor
        v-if="page === 'lists'"
        class="main-content"
        :client="client"
        ref="listEditor"
      />
      <GearListEditor
        v-if="page === 'gear'"
        class="main-content"
        :client="client"
      />
      <ProfileEditor
        v-if="page === 'profile'"
        class="main-content"
        :client="client"
      />
      <SettingsEditor
        v-if="page === 'settings'"
        class="main-content"
        :client="client"
      />
      <FeedbackView
        v-if="page === 'feedback'"
        :client="client"
        class="main-content"
      />
      <DiscoverView
        v-if="page === 'discover'"
        :client="client"
        class="main-content"
      />
    </div>
  </div>
</template>

<script>
import EditorMenu from "./EditorMenu";
import EditorToolbar from "./EditorToolbar";
import GearListEditor from "./gear/GearListEditor";
import ListsEditor from "./lists/ListsEditor";
import ProfileEditor from "../profile/ProfileEditor";
import SettingsEditor from "../settings/SettingsEditor";
import FeedbackView from "../feedback/FeedbackView";
import DiscoverView from "../discover/DiscoverView";

export default {
  name: "EditorPanel",
  props: ["client"],
  components: {
    EditorMenu,
    EditorToolbar,
    GearListEditor,
    ListsEditor,
    ProfileEditor,
    SettingsEditor,
    FeedbackView,
    DiscoverView
  },
  data: () => ({
    page: "lists",
    small: window.innerWidth < 750,
  }),
  created() {
    window.addEventListener("resize", () => {
      this.small = window.innerWidth < 750;
    });
  },
  methods: {
    navigate(page) {
      this.page = page;
    },
    openList(list) {
      this.$refs.menu.onInputTab("lists");
      setTimeout(() => {
        this.$refs.listEditor.onSelectList(list._id);
      }, 200);
    },
  },
};
</script>

<style scoped>
.edit-panel {
  width: 100vw;
  height: 100vh;
}
.main-content {
  flex: 1;
  max-height: calc(100vh - 56px);
  overflow-y: auto;
}
.content-view {
  display: flex;
  align-items: stretch;
}
</style>

<i18n>
{
    "en": {
        "lists": "Lists",
        "gear": "Gear"
    },
    "de": {
        "lists": "Listen",
        "gear": "Ausrüstung"
    }
}
</i18n>