<template>
  <div>
    <v-toolbar dense color="transparent" collapse style="z-index: 1" absolute>
      <LanguageSelector />
    </v-toolbar>
    <div class="scroll-content" ref="scrollContent">
      <SplashTitle :client="client" ref="splashTitle" @down="scrollDown()" />
      <br />
      <Headline>{{ $t("plan_adventure") }}</Headline>
      <ScreenshotDisplay />
      <br /><br />
      <Headline>{{ $t("efficient") }}</Headline>
      <DescriptionText />
      <br /><br />
      <div class="d-flex justify-center">
        <a href="https://traum.me" target="_blank">
          <img src="../../assets/logo/traumsoft.png" style="max-width: 200px" />
        </a>
      </div>
      <br />
      <GoBanner @click="showRegister()" />
    </div>
  </div>
</template>

<script>
import SplashTitle from "./SplashTitle";
import LanguageSelector from "../generic/LanguageSelector";
import DescriptionText from "./DescriptionText";
import ScreenshotDisplay from "./ScreenshotDisplay";
import Headline from "./Headline";
import GoBanner from "./GoBanner";

export default {
  name: "Home",
  props: ["client"],
  components: {
    SplashTitle,
    LanguageSelector,
    DescriptionText,
    ScreenshotDisplay,
    Headline,
    GoBanner,
  },
  methods: {
    scrollToTop() {
      this.$refs.scrollContent.scrollTo(0, 0);
    },
    showRegister() {
      this.scrollToTop();
      this.$refs.splashTitle.showRegister();
      this.$gtag.event('click_letsgo', {
        'event_category': 'home',
        'event_label': 'Clicked start button',
        'value': 0
      });
    },
    scrollDown() {
      this.$refs.scrollContent.scrollTo(0, window.innerHeight);
    },
  },
};
</script>

<style scoped>
.scroll-content {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh);
  scroll-behavior: smooth;
}
</style>

<i18n>
{
  "en": {
    "lets_go": "Get started",
    "plan_adventure": "Plan your adventure.",
    "efficient": "Efficient organization"
  },
  "de": {
    "lets_go": "Los geht's",
    "plan_adventure": "Plane dein Abenteuer.",
    "efficient": "Effiziente Ordnung"
  }
}
</i18n>