<template>
  <div>
    <template v-if="changed">
        <v-chip color="success">
            <v-icon>mdi-check</v-icon> {{ $t('changed') }}
        </v-chip>
    </template>
    <template v-else>
      <v-text-field
        :label="$t('current')"
        type="password"
        hide-details
        v-model="current"
      >
      </v-text-field>
      <v-text-field
        :label="$t('new')"
        type="password"
        hide-details
        v-model="input"
      >
      </v-text-field>
      <v-text-field
        :label="$t('repeat')"
        type="password"
        hide-details
        v-model="repeat"
      >
      </v-text-field>
      <v-btn color="success" :disabled="!valid" small class="mt-4" @click="submit()">
        <v-icon left>mdi-check</v-icon> {{ $t("confirm") }}
      </v-btn>
    </template>
  </div>
</template>

<script>
export default {
  name: "ChangePasswordForm",
  props: ["client"],
  data: () => ({
    current: "",
    input: "",
    repeat: "",
    changed: false
  }),
  computed: {
    currentValid() {
      return (
        this.current.length >= 6 &&
        this.current.length <= 50 &&
        !this.current.includes(" ")
      );
    },
    inputValid() {
      console.log(this.values);
      return (
        this.input.length >= 6 &&
        this.input.length <= 50 &&
        !this.input.includes(" ") &&
        this.input === this.repeat
      );
    },
    valid() {
      return this.currentValid && this.inputValid;
    },
  },
  methods: {
    submit() {
      this.client.account
        .changePassword(this.current, this.input)
        .then((response) => {
          if (response.success) {
            this.changed = true;
          }
        });
    },
  },
};
</script>

<i18n>
{
    "en": {
        "current": "Current password",
        "new": "New password",
        "repeat": "Repeat new password",
        "confirm": "Change",
        "changed": "Password changed successfully"
    },
    "de": {
        "current": "Aktuelles Passwort",
        "new": "Neues Passwort",
        "repeat": "Neues Passwort wiederholen",
        "confirm": "Ändern",
        "changed": "Passwort erfolgreich geändert"
    }
}
</i18n>