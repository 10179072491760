
export default class ListClient {
    constructor(client) {
        this.client = client;
        this.lists = [];
    }

    updateLists(lists) {
        this.lists = lists;
    }

    create(title, keywords) {
        return new Promise((resolve) => {
            this.client.post('list', {
                action: 'create',
                title,
                keywords
            }, true).then(response => {
                if (response.success) {
                    this.updateLists(response.lists);
                    resolve(response);
                } else {
                    this.client.app.handleServerError(response);
                }
            });
        });
    }

    load(id) {
        return this.client.post('list', {
            action: 'get',
            list: id
        }, true);
    }

    changeSetting(id, key, value, getList = true, hidden = false) {
        return this.client.post('list', {
            action: 'setting',
            list: id,
            key,
            value,
            get: getList
        }, true, hidden);
    }

    changeSettingMulti(ids, key, value) {
        return this.client.post('list', {
            action: 'setting-multi',
            lists: ids,
            key,
            value
        }, true);
    }

    loadInformationList() {
        return new Promise((resolve) => {
            this.client.post('list', {
                action: 'list'
            }, true).then(response => {
                if (response.success) {
                    this.lists = response.lists;
                }
                resolve(response);
            });
        });
    }

    delete(id) {
        return this.client.post('list', {
            action: 'delete',
            lists: [id]
        }, true);
    }

    deleteMulti(ids) {
        console.log("Delete multi", ids);
        return this.client.post('list', {
            action: 'delete',
            lists: ids
        }, true);
    }

    addGearItem(listId, item) {
        return this.client.post('list', {
            action: 'add-gear',
            list: listId,
            item: item
        }, true)
    }

    removeGearItem(listId, itemIndex) {
        return this.client.post('list', {
            action: 'remove-gear',
            list: listId,
            index: itemIndex
        }, true);
    }

    addFromLibrary(listId, items) {
        return this.client.post('list', {
            action: 'add-library',
            list: listId,
            items: items
        }, true);
    }

    updateGear(listId, itemIndex, item) {
        return this.client.post('list', {
            action: 'update-gear',
            list: listId,
            index: itemIndex,
            item
        }, true);
    }

    resetPack(listId) {
        return this.client.post('list', {
            action: 'reset-pack',
            list: listId
        }, true);
    }

    getPublic(listId) {
        return this.client.post('list', {
            action: 'get-public',
            list: listId
        });
    }

    import(listId, items, save) {
        return this.client.post('list', {
            action: 'import',
            list: listId,
            items,
            save
        }, true);
    }

    addCategory(listId, category) {
        return this.client.post('list', {
            action: 'add-category',
            list: listId,
            category
        }, true);
    }

    removeCategory(listId, index) {
        return this.client.post('list', {
            action: 'remove-category',
            list: listId,
            index
        }, true);
    }

    editGear(listId, itemIndex, values) {
        return this.client.post('list', {
            action: 'edit-gear',
            list: listId,
            index: itemIndex,
            values
        }, true);
    }

    addImage(listId, image) {
        return this.client.post('list', {
            action: 'add-image',
            list: listId,
            image
        }, true);
    }

    removeImage(listId, index) {
        return this.client.post('list', {
            action: 'remove-image',
            list: listId,
            index
        }, true);
    }
}