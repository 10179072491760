<template>
  <v-card v-if="list">
    <CreateGearDialog
      ref="createGearDialog"
      :list="list"
      :client="client"
      @createdItem="onCreatedItem($event)"
      @listChanged="$emit('listChanged', $event)"
    />
    <PickGearDialog
      ref="pickGearDialog"
      :client="client"
      @pick="onAddItems($event)"
    />
    <PublicGearSearchDialog
      ref="publicGearDialog"
      :client="client"
      @select="onAddPublicItem($event)"
    />
    <ImportDialog
      :client="client"
      ref="importDialog"
      :listId="list._id"
      @listChanged="$emit('listChanged', $event)"
    />
    <GearImageDialog ref="gearImageDialog" />
    <EditKeywordsDialog ref="editKeywords" :client="client" :list="list" />
    <v-card-title>
      <v-toolbar style="max-width: 90vw">
        <v-btn @click="$emit('goBack')" icon>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-text-field
          v-if="editTitle"
          v-model="titleInput"
          maxlength="50"
          style="max-width: 300px"
          hide-details
          @keydown="editTitleKeydown($event)"
        >
        </v-text-field>
        <v-toolbar-title v-else>{{ list.title }}</v-toolbar-title>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="toggleEditTitle()"
              :disabled="!editTitleValid"
              v-on="on"
              v-bind="attrs"
            >
              <v-icon
                v-text="editTitle ? 'mdi-content-save' : 'mdi-pencil'"
                :color="editTitle ? 'success' : 'info'"
              ></v-icon>
            </v-btn>
          </template>
          <span v-if="editTitle">{{ $t("save") }}</span>
          <span v-else>{{ $t("rename") }}</span>
        </v-tooltip>

        <v-spacer />
        <ShareMenu :client="client" :list="list" />
        <SettingsMenu
          :client="client"
          :list="list"
          @deleted="$emit('goBack')"
          @editKeywords="$refs.editKeywords.show()"
        />
        <DownloadMenu :client="client" :list="list" />
      </v-toolbar>
    </v-card-title>
    <v-card-text>
      <DualContentRowReverse>
        <template slot="large">
          <v-card>
            <AddGearMenu
              :client="client"
              :list="list"
              @listChanged="listUpdate($event)"
              @createNew="onCreateNew()"
              @pick="onPickGear()"
              @public="$refs.publicGearDialog.show()"
              @import="$refs.importDialog.show()"
              v-if="smallView"
              class="mb-2"
            />
          </v-card>
          <v-card>
            <div class="gear-display">
            <div class="d-flex" style="position: sticky; top: 0; z-index: 1;">
              <v-spacer />
              <ViewModeSelector
                :client="client"
                :list="list"
                @listChanged="$emit('listChanged', $event)"
              />
            </div>
            <GearDisplayList
              :list="list"
              :editable="true"
              @delete="removeItem($event)"
              @edit="editItem($event)"
              @togglePack="togglePack($event)"
              @toggleWear="toggleWear($event)"
              :unit="client.account.profile.unit"
              @showImage="$refs.gearImageDialog.show($event)"
              v-if="list.items.length > 0 && list.view === 'list'"
            />
            <GearDisplayTable
              :list="list"
              :client="client"
              :editable="true"
              @delete="removeItem($event)"
              @edit="editItem($event)"
              @togglePack="togglePack($event)"
              @toggleWear="toggleWear($event)"
              @listChanged="$emit('listChanged', $event)"
              :unit="client.account.profile.unit"
              @showImage="$refs.gearImageDialog.show($event)"
              v-if="list.items.length > 0 && list.view === 'table'"
            />
            <InfoIconCard
              v-if="list.items.length <= 0"
              icon="mdi-information-outline"
              color="info"
            >
              {{ $t("list_empty") }}
            </InfoIconCard>
            </div>
            <PackingProgressBar :list="list" @reset="resetPack()" />
          </v-card>
          <ImageListView
            :list="list"
            :client="client"
            class="mt-2"
            :editable="true"
            v-if="!smallView"
          />
        </template>
        <template slot="small">
          <v-card>
            <AddGearMenu
              :client="client"
              :list="list"
              @listChanged="listUpdate($event)"
              @createNew="onCreateNew()"
              @public="$refs.publicGearDialog.show()"
              @pick="onPickGear()"
              @import="$refs.importDialog.show()"
              v-if="!smallView"
            />
          </v-card>
          <WeightChart 
            class="mt-3"
            ref="chart"
            :list="list"
            :unit="client.account.profile.unit"
            v-if="list.items.length > 0"
            />
          <TotalWeightLabel
            class="mt-2"
            :list="list"
            :unit="client.account.profile.unit"
          />
          <ImageListView
            :list="list"
            :client="client"
            class="mt-2"
            :editable="true"
            v-if="smallView"
          />
        </template>
      </DualContentRowReverse>
    </v-card-text>
  </v-card>
</template>

<script>
import AddGearMenu from "./AddGearMenu";
import GearDisplayList from "../gear/GearDisplayList";
import CreateGearDialog from "../gear/CreateGearDialog";
import PickGearDialog from "../gear/PickGearDialog";
import TotalWeightLabel from "./TotalWeightLabel";
import DownloadMenu from "./DownloadMenu";
import DualContentRowReverse from "../../generic/layout/DualContentRowReverse";
import PublicGearSearchDialog from "../gear/public/PublicGearSearchDialog";
import InfoIconCard from "../../generic/layout/InfoIconCard";
import SettingsMenu from "./SettingsMenu";
import PackingProgressBar from "./PackingProgressBar";
import ImportDialog from "../import/ImportDialog";
import ShareMenu from "./ShareMenu";
import ViewModeSelector from "./ViewModeSelector";
import GearDisplayTable from "../gear/GearDisplayTable";
import EditKeywordsDialog from "./EditKeywordsDialog";
import ImageListView from "./image/ImageListView";
import WeightChart from "./WeightChart";
import GearImageDialog from "../gear/GearImageDialog";

export default {
  name: "ListEditor",
  props: ["list", "client"],
  components: {
    AddGearMenu,
    GearDisplayList,
    CreateGearDialog,
    PickGearDialog,
    DownloadMenu,
    DualContentRowReverse,
    PublicGearSearchDialog,
    InfoIconCard,
    SettingsMenu,
    PackingProgressBar,
    TotalWeightLabel,
    ImportDialog,
    ShareMenu,
    ViewModeSelector,
    GearDisplayTable,
    EditKeywordsDialog,
    ImageListView,
    WeightChart,
    GearImageDialog
  },
  data: () => ({
    editTitle: false,
    titleInput: "",
    scrollHeight: window.innerWidth - 550,
  }),
  computed: {
    editTitleValid() {
      return (
        !this.editTitle ||
        (this.titleInput.trim().length >= 2 &&
          this.titleInput.trim().length <= 50)
      );
    },
    smallView() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return true;
        default:
          return false;
      }
    },
  },
  methods: {
    toggleEditTitle() {
      this.editTitle = !this.editTitle;
      if (this.editTitle) {
        this.titleInput = this.list.title;
      } else if (this.titleInput !== this.list.title) {
        this.client.lists
          .changeSetting(this.list._id, "title", this.titleInput)
          .then((response) => {
            if (response.success) {
              this.$emit("listChanged", response.list);
            }
          });
      }
    },
    editTitleKeydown(event) {
      if (event.key === "Enter" && this.editTitleValid) {
        this.toggleEditTitle();
      }
    },
    removeItem(item) {
      this.client.lists
        .removeGearItem(this.list._id, this.list.items.indexOf(item))
        .then((response) => {
          if (response.success) {
            this.$emit("listChanged", response.details);
            this.$refs.chart.renderAfter(100);
          }
        });
    },
    editItem(info) {
      this.$refs.createGearDialog.showEdit(info.item, info.index);
    },
    togglePack(item) {
      item.pack = !item.pack;
      this.client.lists
        .updateGear(this.list._id, item.index, item)
        .then((response) => {
          if (!response.success) {
            item.pack = !item.pack;
          }
        });
    },
    toggleWear(item) {
      item.wear = !item.wear;
      this.client.lists
        .updateGear(this.list._id, item.index, item)
        .then((response) => {
          if (!response.success) {
            item.wear = !item.wear;
          } else {
            this.$refs.chart.renderAfter(100);
          }
        });
    },
    onCreatedItem(item) {
      this.client.lists.addGearItem(this.list._id, item).then((response) => {
        if (response.success) {
          this.$emit("listChanged", response.details);
          this.$refs.chart.renderAfter(100);
          this.$gtag.event('item_created', {
            'event_category': 'list',
            'event_label': 'Created Item',
            'value': 0
          });
        }
      });
    },
    onAddPublicItem(itemId) {
      this.client.lists
        .addGearItem(this.list._id, { count: 1, id: itemId })
        .then((response) => {
          if (response.success) {
            this.$refs.publicGearDialog.hide();
            this.$emit("listChanged", response.details);
          }
        });
    },
    resetPack() {
      this.client.lists.resetPack(this.list._id).then((response) => {
        if (response.success) {
          for (const item of this.list.items) {
            item.pack = false;
          }
        }
      });
    },
    onAddItems(items) {
      let data = [];
      for (let item of items) {
        data.push({ id: item._id, count: 1 });
      }
      this.client.lists.addFromLibrary(this.list._id, data).then((response) => {
        if (response.success) {
          this.listUpdate(response.list);
          this.$refs.pickGearDialog.hide();
        }
      });
    },
    onCreateNew() {
      this.$refs.createGearDialog.show();
    },
    onPickGear() {
      this.$refs.pickGearDialog.show();
    },
    listUpdate(list) {
      this.$emit("listChanged", list);
      this.$refs.chart.renderAfter(100);
    },
    onAddImage(image) {
      this.client.lists.addImage(this.list._id, image).then((response) => {
        console.log(response);
      });
    },
  },
};
</script>

<style scoped>
.title-text[contenteditable] {
  outline: 0px solid transparent;
  border-bottom: 2px dashed gray;
}
.title-text {
  border-bottom: 2px solid transparent;
}
.list-container {
  flex: 1;
  overflow-y: auto;
}
@media (min-width: 1000px) {
  .gear-display {
    max-height: 60vh;
    overflow-y: auto;
  }
}
</style>

<i18n>
{
  "en": {
    "list_empty": "This list is empty.",
    "rename": "Rename list",
    "save": "Save",
    "view": "View",
    "view_list": "List",
    "view_table": "Table"
  },
  "de": {
    "list_empty": "Diese Liste enthält noch keine Gegenstände.",
    "rename": "Liste umbenennen",
    "save": "Speichern",
    "view": "Ansicht",
    "view_list": "Liste",
    "view_table": "Tabelle"
  }
}
</i18n>