<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn :small="small" :icon="!showLabel" v-bind="attrs" v-on="on" color="error">
        <v-icon :left="showLabel === true">mdi-trash-can</v-icon> <span v-if="showLabel">{{question}}</span>
      </v-btn>
    </template>
    <v-list subheader dense>
      <v-subheader>{{ question }}</v-subheader>
      <v-list-item @click="$emit('confirm')">
        <v-list-item-icon>
          <v-icon color="error">mdi-trash-can</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          {{ $t("confirm") }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$emit('abort')">
        <v-list-item-icon>
          <v-icon>mdi-close</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          {{ $t("abort") }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "DeleteButton",
  props: ["question", "showLabel", "small"],
};
</script>

<i18n>
{
    "en": {
        "confirm": "Yes",
        "abort": "No"
    },
    "de": {
        "confirm": "Ja",
        "abort": "Nein"
    }
}
</i18n>