<template>
  <v-dialog max-width="600" v-model="visible">
    <LoadingOverlay :loading="client.loading" />
    <template>
      <v-card>
        <v-toolbar
          >{{ $t("title") }}
          <v-spacer />
          <v-btn icon @click="hide()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text v-if="submitted" class="pt-4">
          <v-icon color="success">mdi-information-outline</v-icon>
          {{ $t("submitted") }}
        </v-card-text>
        <v-card-text v-else>
          <v-text-field
            class="mt-2 mb-0"
            @keydown="onInputKeydown($event)"
            v-model="input.pass"
            :type="showPass ? 'text' : 'password'"
            :label="$t('label') + '...'"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPass = !showPass"
          ></v-text-field>
          <v-text-field
            class="mt-0"
            v-model="repeatPass"
            @keydown="onInputKeydown($event)"
            type="password"
            :label="$t('label_retype') + '...'"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="hide()" v-if="submitted">{{ $t("close") }}</v-btn>
          <v-btn
            text
            v-else
            :disabled="!valid || sending"
            color="success"
            @click="submit()"
          >
            {{ $t("submit") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import LoadingOverlay from "../generic/LoaderOverlay";
import Config from "../../config";

export default {
  name: "EditPasswordDialog",
  props: ["client"],
  components: {
    LoadingOverlay
  },
  data: () => ({
    visible: false,
    sending: false,
    submitted: false,
    secret: undefined,
    id: undefined,
    input: {
      pass: "",
    },
    repeatPass: "",
    showPass: false,
  }),
  computed: {
    valid() {
      return this.input.pass.length >= Config.Account.PasswordLength.Min && this.input.pass.length <= Config.Account.PasswordLength.Max && this.input.pass === this.repeatPass;
    },
  },
  methods: {
    show(secret, id) {
      this.visible = true;
      this.secret = secret;
      this.id = id;
    },
    onInputKeydown(event) {
      if(event.key === 'Enter' && this.valid) {
        this.submit();
      }
    },
    submit() {
      this.sending = true;
      this.client.account
        .confirmResetPassword(this.secret, this.id, this.input.pass)
        .then((response) => {
          this.input.pass = "";
          this.sending = false;
          if (response.success) {
            this.submitted = true;
          } else {
            this.hide();
          }
        });
    },
    hide() {
      this.visible = false;
    },
  },
};
</script>

<i18n>
{
    "en": {
        "title": "Reset password",
        "submit": "Confirm",
        "close": "Done",
        "label": "Enter your new password",
        "label_retype": "Repeat your new passwort",
        "submitted": "Your password has been changed successfully."
    },
    "de": {
        "title": "Passwort zurücksetzen",
        "submit": "Senden",
        "close": "Fertig",
        "label": "Gebe dein neues Passwort ein",
        "label_retype": "Wiederhole dein neues Passwort",
        "submitted": "Dein Passwort wurde erfolgreich geändert."
    }
}
</i18n>