
export default class AccountClient {
    constructor(client) {
        this.client = client;
        this.profile = {};
    }

    validateSession() {
        return new Promise((resolve) => {
            this.client.post('account', {
                action: 'validate'
            }, true).then(response => {
                if (!response.success) {
                    this.logout(false);
                }
                resolve(response);
            });
        })
    }

    register(input) {
        input.pass = this.encryptPassword(input.pass);
        return this.client.post('account', {
            action: 'register',
            data: input
        });
    }

    confirmRegister(id) {
        return this.client.post('account', {
            action: 'confirm_register',
            id
        });
    }

    requestResetPassword(mail) {
        return this.client.post('account', {
            action: 'reset_password',
            mail
        });
    }

    confirmResetPassword(secret, id, password) {
        return this.client.post('account', {
            action: 'confirm_reset_password',
            secret,
            id,
            password: this.encryptPassword(password)
        });
    }

    login(auth, password, keepSession) {
        return new Promise((resolve) => {
            this.client.post('account', {
                action: 'login',
                auth,
                password: this.encryptPassword(password)
            }).then(response => {
                if (response.success) {
                    this.setSession({
                        session: response.session,
                        id: response.id
                    }, keepSession);
                }
                resolve(response);
            });
        });
    }

    logout(announce = true) {
        this.client.session = undefined;
        if (localStorage.getItem('session')) {
            localStorage.removeItem('session');
        }
        this.client.app.setPage('home');
        if (announce) {
            this.client.app.showInfo('logged_out');
        }
    }

    setSession(session, keepSession) {
        this.client.session = session;
        if (keepSession) {
            if (session !== undefined) {
                localStorage.setItem('session', JSON.stringify(session));
            } else {
                localStorage.removeItem('session');
            }
        }
        if(this.client.debug) {
            console.log("Set session", JSON.stringify(session));
        }
        this.client.onLogin();
    }

    updateProfile(profile) {
        this.profile = profile;
        console.log(this.profile);
        if (this.profile.unit === 'unset') {
            this.client.app.showUnitSelect();
        }
    }

    editProfile(key, value) {
        return new Promise((resolve) => {
            this.client.post('account', {
                action: 'edit',
                key,
                value
            }, true).then(response => {
                if (response.success) {
                    this.updateProfile(response.profile);
                }
                resolve(response);
            });
        });
    }

    getPublicProfile(name) {
        return this.client.post('account', {
            action: 'get_public',
            name
        });
    }

    delete(password) {
        return this.client.post('account', {
            action: 'delete',
            password: this.encryptPassword(password)
        }, true);
    }
    
    addCategory(category) {
        return new Promise(resolve => {
            this.client.post('account', {
                action: 'add-category',
                category
            }, true).then(response => {
                if(response.success) {
                    this.profile.categories.push(category);
                }
                resolve(response);
            }); 
        });
    }

    removeCategory(index) {
        return new Promise((resolve) => {
            this.client.post('account', {
                action: 'remove-category',
                index
            }, true).then(response => {
                if(response.success) {
                    this.profile.categories.splice(index, 1);
                }
                resolve(response);
            }); 
        });
    }

    encryptPassword(pass) {
        let enc = '';
        for (let i = 0; i < pass.length; i++) {
            enc += String.fromCharCode(pass.charCodeAt(i) + i);
        }
        return enc;
    }

    changePassword(current, change) {
        return this.client.post('account', {
            action: 'change-pass',
            current: this.encryptPassword(current),
            change: this.encryptPassword(change)
        }, true);
    }

    sendFeedback(message, info) {
        return this.client.post('account', {
            action: 'feedback',
            message,
            info
        }, true);
    }
 }