

const ImageUtil = {
    fitSize(image, targetWidth, targetHeight) {
        const landscape = image.width > image.height;
        const scaleFactor = landscape ? targetWidth / image.width : targetHeight / image.height;
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = landscape ? targetWidth : image.width * scaleFactor;
        canvas.height = landscape ? image.height * scaleFactor : targetHeight;
        context.drawImage(image, 0,0, canvas.width, canvas.height);
        return canvas.toDataURL("image/jpeg"); 
    }
}

export default ImageUtil;