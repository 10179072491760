
export default class GearClient {
    constructor(client) {
        this.client = client;
        this.list = [];
        this.listLoaded = false;
    }

    updateList(gear) {
        this.list = gear;
        this.listLoaded = true;
        console.log("List updated", gear)
    }

    create(name, brand, category, weight, img, note, applyForCheck = true) {
        return new Promise((resolve) => {
            this.client.post('gear', {
                action: 'add',
                item: { name, brand, category, weight, img, note },
                apply: applyForCheck
            }, true).then(response => {
                if (response.success) {
                    this.updateList(response.gear);
                }
                resolve(response);
            })
        })
    }

    loadList() {
        return new Promise((resolve) => {
            this.client.post('gear', {
                action: 'list'
            }, true).then(response => {
                console.log("Response")
                if (response.success) {
                    this.updateList(response.gear);
                }
                resolve(response);
            });
        });
    }

    deleteItems(items) {
        return new Promise((resolve) => {
            this.client.post('gear', {
                action: 'delete',
                items
            }, true).then(response => {
                if (response.success) {
                    this.updateList(response.gear);
                }
                resolve(response);
            })
        });
    }

    editGear(id, name, brand, category, weight, img) {
        return this.client.post('gear', {
            action: 'edit',
            id,
            values: { name, brand, category, weight, img }
        }, true);
    }

    queryPublic(query) {
        return this.client.post('gear', {
            action: 'query-public',
            query
        }, true);
    }

    queryPublicMatchingName(name) {
        return this.client.post('gear', {
            action: 'query-public-name',
            name: name.toLowerCase()
        }, true);
    }

    uploadImage(image) {
        return this.client.post('gear', {
            action: 'upload-image',
            image
        }, true);
    }

    deleteImage(image) {
        return this.client.post('gear', {
            action: 'delete-image',
            image
        }, true);
    }

    setImage(gear, image) {
        return this.client.post('gear', {
            action: 'set-image',
            gear,
            image
        }, true);
    }
}