<template>
  <canvas
    ref="canvas"
    :class="{'canvas-mouse-down': mouseDown}"
    :width="calculatedWidth"
    :height="calculatedHeight"
    @mousemove="onMouseMove($event)"
    @touchmove="onMouseMove($event, true)"
    @mousedown="mouseDown = true; onMouseMove($event)"
    @mouseup="mouseDown = false"
    @mouseout="mouseDown = false"
  />
</template>

<script>
export default {
  name: "ImageCropSelector",
  props: ["image"],
  data: () => ({
    context: undefined,
    maxWidth: window.innerWidth < 450 ? window.innerWidth - 80 : 450,
    cropPos: { x: 0, y: 0 },
    mouseDown: false,
  }),
  mounted() {
    this.context = this.$refs.canvas.getContext("2d");
    this.render();
    this.updateCrop();
  },
  computed: {
    wide() {
      return this.image && this.image.width > this.image.height;
    },
    calculatedHeight() {
      if (this.image === undefined) {
        return 0;
      } else {
        if (this.wide) {
          return (this.image.height / this.image.width) * this.maxWidth;
        } else {
          return this.maxWidth;
        }
      }
    },
    calculatedWidth() {
      if (this.image === undefined) {
        return 0;
      } else {
        if (this.wide) {
          return this.maxWidth;
        } else {
          return (this.image.width / this.image.height) * this.maxWidth;
        }
      }
    },
    cropHeight() {
      if (this.wide) {
        return this.calculatedHeight;
      } else {
        return this.calculatedWidth;
      }
    },
    cropWidth() {
      if (this.wide) {
        return this.calculatedHeight;
      } else {
        return this.calculatedWidth;
      }
    },
  },
  methods: {
    updateCrop() {
      this.$emit("cropChange", {
        x: this.cropPos.x,
        y: this.cropPos.y,
        w: this.cropWidth,
        h: this.cropHeight,
      });
    },
    render() {
      this.context.drawImage(
        this.image,
        0,
        0,
        this.calculatedWidth,
        this.calculatedHeight
      );
      this.context.strokeStyle = "yellow";
      this.context.lineWidth = 4;
      this.context.strokeRect(
        this.cropPos.x + 2,
        this.cropPos.y + 2,
        this.cropWidth - 2,
        this.cropHeight - 2
      );
      this.context.globalAlpha = 0.5;
      this.context.fillStyle = "black";
      if (this.wide) {
        this.context.fillRect(
          this.cropPos.x + this.cropWidth,
          0,
          this.calculatedWidth,
          this.calculatedHeight
        );
        this.context.fillRect(0, 0, this.cropPos.x, this.calculatedHeight);
      } else {
        this.context.fillRect(
          0,
          this.cropPos.y + this.cropHeight,
          this.calculatedWidth,
          this.calculatedHeight
        );
        this.context.fillRect(0, 0, this.calculatedWidth, this.cropPos.y);
      }
      this.context.globalAlpha = 1;
    },
    onMouseMove(event, touch=false) {
      if (this.mouseDown || touch) {
        const bounds = this.$refs.canvas.getBoundingClientRect();
        let eventX = touch ? event.changedTouches[0].pageX : event.pageX;
        let eventY = touch ? event.changedTouches[0].pageY : event.pageY;
        let x = eventX - bounds.left - this.cropWidth * 0.5;
        let y = eventY - bounds.top - this.cropHeight * 0.5;
        if (x < 0) x = 0;
        if (y < 0) y = 0;
        if (x > this.calculatedWidth - this.cropWidth)
          x = this.calculatedWidth - this.cropWidth;
        if (y > this.calculatedHeight - this.cropHeight)
          y = this.calculatedHeight - this.cropHeight;
        if (this.wide) {
          this.cropPos.x = x;
        } else {
          this.cropPos.y = y;
        }
        this.render();
        this.updateCrop();
      }
    },
    getImageCropBounds() {
      return {
        x: (this.cropPos.x / this.calculatedWidth) * this.image.width,
        y: (this.cropPos.y / this.calculatedHeight) * this.image.height,
        w: (this.cropWidth / this.calculatedWidth) * this.image.width,
        h: (this.cropHeight / this.calculatedHeight) * this.image.height,
      };
    },
    getCroppedImageData(width) {
      const subCanvas = document.createElement("canvas");
      const subContext = subCanvas.getContext("2d");
      subCanvas.width = width;
      subCanvas.height = width;
      const imageBounds = this.getImageCropBounds();
      subContext.drawImage(
        this.image,
        imageBounds.x,
        imageBounds.y,
        imageBounds.w,
        imageBounds.h,
        0,
        0,
        width,
        width
      );
      return subCanvas.toDataURL("image/jpeg");
    },
  },
};
</script>

<style scoped>
canvas {
  box-shadow: 0 0 5px black;
}
.canvas-mouse-down {
    cursor: move;
}
</style>