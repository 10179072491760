<template>
  <div class="d-flex justify-center">
    <v-row class="screenshot-row">
      <v-col cols="12" md="6">
        <v-img
          src="../../assets/screenshots/list_editor.webp"
          alt="Screenshot"
          style="max-width: 95vw"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-card flat>
          <v-card-text>
            <template v-if="$i18n.locale === 'de'">
              Mit PackTrek wird das Planen von Gepäck und Ausrüstung zum Reisen
              und Wandern zum Kinderspiel.<br />
              PackTrek erscheint in modernem Design, bei welchem viel Wert auf
              Übersichtlichkeit und Effizienz gelegt wurde.<br />
              Profis können ihre Ausrüstung verwalten und Planen, während
              Anfänger Inspiration sammeln können.<br />
              Durch das Teilen von Listen und den Entdecken-Bereich entsteht
              eine interessante soziale Komponente und vielfältiger
              Austausch.<br />
              Die gesamte Anwendung ist kostenlos nutzbar und läuft gleichwertig
              auf jedem Gerät - egal ob Computer, Tablet oder Handy.<br />
              Schluss mit mühseligen Tabellen - PackTrek ist genau auf den
              Anwendungsfall zugeschnitten.<br>
              Die Server werden vollständig mit Strom aus erneuerbaren Energiequellen betrieben.
            </template>
            <template v-else>
              PackTrek makes planning luggage and equipment for travel and
              hiking a breeze. <br />
              PackTrek appears in a modern design, which is very important
              Clarity and efficiency was placed. <br />
              Professionals can manage and plan their equipment, while beginners
              can gather inspiration. <br />
              By sharing lists and the discover area, a interesting social
              component and diverse exchange. <br />
              The entire application can be used free of charge and runs on an
              equivalent basis any device - whether it's a computer, tablet or
              mobile phone. <br />
              No more tedious tables - PackTrek is tailored precisely to the
              application.<br>
              The Servers run completely on renewable energy sources.
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>


<script>
export default {
  name: "ScreenshotDisplay",
};
</script>

<style scoped>
.screenshot-row {
  max-width: 1200px;
}
</style>