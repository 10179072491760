import AccountClient from './account_client';
import GearClient from './gear_client';
import ListClient from './list_client';
import DiscoverClient from './discover_client';

export default class Client {
    constructor(app) {
        this.app = app;
        this.HOST_COUNT = window.location.host === 'localhost' ? 1 : 1;
        this.session = undefined;
        this.loading = false;
        this.debug = false;
        this.mobileApp = false;
        this.account = new AccountClient(this);
        this.gear = new GearClient(this);
        this.lists = new ListClient(this);
        this.discover = new DiscoverClient(this);
    }

    getRandomHostRequest(url) {
        const host = Math.floor((Math.random() * this.HOST_COUNT));
        return `${location.protocol}//${location.host}/server${host}/${url}`
    }

    post(url, request, auth = false, hidden = false) {
        if (window.location.hostname.includes('localhost') || this.debug) {
            console.log("Post", url, request);
        }
        if (!hidden) {
            this.loading = true;
        }
        return new Promise((resolve, reject) => {
            const req = new XMLHttpRequest();
            req.addEventListener('load', () => {
                this.loading = false;
                try {
                    const parsed = JSON.parse(req.responseText);
                    if (parsed.success !== undefined && parsed.success === false) {
                        this.app.handleServerError(parsed);
                    }
                    if (this.debug) {
                        console.log("Response", url, request, parsed);
                    }
                    resolve(parsed);
                } catch (e) {
                    this.app.handleServerError('no-connection');
                    console.error(e);
                    reject(req.responseText);
                }
            });
            req.addEventListener('error', () => {
                this.app.handleServerError('no-connection');
                reject(req.responseText);
            });
            req.open('POST', this.getRandomHostRequest(url));
            request.lang = this.app.$i18n.locale;
            if (auth) {
                request.session = this.session;
            }
            req.send(JSON.stringify(request));
        });
    }

    onLogin() {
        this.app.setPage('editor');
        this.requestInterface();
    }

    requestInterface() {
        this.post('data', {
            action: 'interface',
            session: this.session
        }).then(interfaceData => {
            if (interfaceData.success) {
                this.account.updateProfile(interfaceData.profile);
                this.lists.updateLists(interfaceData.lists);
                this.app.showInfo('login_successful');
            } else {
                this.account.logout(false);
            }
        });
    }

    editGearItem(list, item, values, itemIndex = -1) {
        if (item._id !== undefined) {
            //Is reference
            return new Promise((resolve) => {
                this.gear.editGear(
                    item._id,
                    values.name,
                    values.brand,
                    values.category,
                    values.weight,
                    values.img,
                ).then(response => {
                    if (response.success) {
                        const gear = this.gear.list.find(gear => gear._id === item._id);
                        if (list !== undefined) {
                            this.lists.editGear(list._id, itemIndex, {
                                count: values.count
                            });
                        }
                        for (const key in values) {
                            if ((gear[key] !== undefined || key === 'img') && gear[key] !== values[key]) {
                                gear[key] = values[key];
                            }
                        }
                        console.log("Modify", this.gear.list);
                        this.gear.list = JSON.parse(JSON.stringify(this.gear.list));
                        if (list !== undefined) {
                            for (const listItem of list.items) {
                                if (listItem._id === item._id) {
                                    console.log("List item found")
                                    for (const key in values) {
                                        if ((listItem[key] !== undefined || key === 'img') && listItem[key] !== values[key]) {
                                            listItem[key] = values[key];
                                            console.log("List item", key, values[key])
                                        }
                                    }
                                }
                            }
                        }
                    }
                    resolve(response);
                });
            });
        } else {
            //Is static
            return new Promise((resolve) => {
                this.lists.editGear(list._id, itemIndex, values).then(response => {
                    if (response.success) {
                        for (const key in values) {
                            if (item[key] !== undefined && item[key] !== values[key]) {
                                item[key] = values[key];
                            }
                        }
                    }
                    resolve(response);
                });
            });
        }
    }
}
