<template>
    <v-simple-table dense>
        <thead>
            <tr>
                <th>
                    {{ $t('category') }}
                </th>
                <th>
                    {{ $t('items') }}
                </th>
                <th>
                    {{ $t('weight') }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(category, index) of categories" :key="index">
                <td>
                    <i class="mdi icon" :class="category.category[0]" :style="{color: getIconColor(index)}"></i> {{category.category[1]}}
                </td>
                <td>
                    {{category.itemCount}}
                </td>
                <td>
                    {{ formatWeight(category.weight) }}
                </td>
            </tr>
        </tbody>
    </v-simple-table>
</template>

<script>
import UnitConverter from "../../../lib/unit";
import Globals from "../../../lib/globals";

export default {
    name: 'WeightTableChart',
    props: ['list', 'unit'],
    computed: {
        categories() {
            const catTmp = [];
            const categories = [];
            for(const item of this.list.items) {
                if(!catTmp.includes(item.category[1])) {
                    catTmp.push(item.category[1]);
                    categories.push({
                        category: item.category,
                        itemCount: 1,
                        weight: item.weight * item.count
                    });
                } else {
                    const catIndex = catTmp.indexOf(item.category[1]);
                    categories[catIndex].itemCount++;
                    categories[catIndex].weight += item.weight * item.count;
                }
            }
            return categories;
        }
    },
    methods: {
                formatWeight(weight) {
            return UnitConverter.formatString(weight, this.unit);
        },
        getIconColor(index) {
            return Globals.getCategoryColor(index);
        }
    }
}
</script>

<i18n>
{
    "en": {
        "category": "Category",
        "items": "Items",
        "weight": "Weight"
    },
    "de": {
        "category": "Kategorie",
        "items": "Anzahl",
        "weight": "Gewicht"
    }
}
</i18n>