<template>
    <v-card>
        <v-card-text>
            <div class="d-flex">
            <v-icon v-text="icon" class="mr-2" :color="color"></v-icon>
            <slot></slot>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'InfoIconCard',
    props: ['icon', 'color']
}
</script>