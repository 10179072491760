<template>
  <div>
    <input type="file" @input="onInput($event)" accept="image/*" class="file-selector"/>
    <template v-if="canvasReady">
      <p class="mt-2 mb-0">{{ $t("crop") }}:</p>
      <ImageCropSelector v-if="canvasReady" :image="image" ref="imageCropper"/>
      <v-row class="mt-2">
          <v-spacer />
          <v-col>
          <v-btn color="success" @click="onSave()">
              <v-icon>mdi-content-save</v-icon> {{$t('save')}}
          </v-btn>
          </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import ImageCropSelector from "./ImageCropSelector";
export default {
  name: "ImageUploadForm",
  components: {
    ImageCropSelector,
  },
  data: () => ({
    canvasReady: false,
    loadError: false,
    loadingImage: false,
    image: new Image(),
    imageSize: 256
  }),
  methods: {
    onReadImageFile(event) {
      if (event.target.readyState == FileReader.DONE) {
        this.image.src = event.target.result;
        this.image.onload = this.onImageLoaded;
        this.image.onerror = () => {
          this.canvasReady = false;
          this.loadError = true;
          this.loadingImage = false;
        };
      }
    },
    onImageLoaded() {
      this.canvasReady = true;
      this.loadError = false;
      this.loadingImage = false;
    },
    onInput(event) {
      this.loadingImage = true;
      this.canvasReady = false;
      const file = event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = this.onReadImageFile;
    },
    onSave() {
        this.$emit('save', this.getImageData());
    },
    getImageData() {
      const dataURL = this.$refs.imageCropper.getCroppedImageData(
        this.imageSize
      );
      return dataURL;
    },
  },
};
</script>

<style scoped>
.file-selector {
  color: black;
  background-color: white;
}
</style>

<i18n>
{
    "en": {
        "crop": "Select area",
        "save": "Save"
    },
    "de": {
        "crop": "Auschnitt auswählen",
        "save": "Speichern"
    }
}
</i18n>