<template>
  <v-app class="app">
    <v-main>
      <InfoDialog ref="infoDialog" :bottom="true" />
      <UnitSelectDialog :client="client" ref="unitSelectDialog" />
      <EditPasswordDialog ref="editPasswordDialog" :client="client" />
      <v-tabs-items v-model="page" class="tab-items" touchless>
        <v-tab-item>
          <Home :client="client" />
        </v-tab-item>
        <v-tab-item>
          <EditorPanel :client="client" ref="editorPanel" />
        </v-tab-item>
        <v-tab-item>
          <PublicProfileView
            :client="client"
            :name="publicProfileName"
            :list="publicProfileList"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-main>
  </v-app>
</template>

<script>
import Home from "./components/home/Home";
import InfoDialog from "./components/generic/InfoDialog";
import EditPasswordDialog from "./components/account/EditPasswordDialog";
import EditorPanel from "./components/editor/EditorPanel";
import UnitSelectDialog from "./components/generic/UnitSelectDialog";
import PublicProfileView from "./components/profile/public/PublicProfileView";
import Globals from "./lib/globals";
import Client from "./lib/client";

export default {
  name: "App",

  components: {
    Home,
    InfoDialog,
    EditPasswordDialog,
    EditorPanel,
    UnitSelectDialog,
    PublicProfileView,
  },

  data: () => ({
    page: 0,
    publicProfileName: "",
    publicProfileList: undefined,
    client: undefined,
    backMethod: undefined,
    pages: {
      home: 0,
      editor: 1,
      profile: 2,
    },
  }),
  created() {
    window.setMobile = () => {
      this.$gtag.event('start_app', {
        'event_category': 'App',
        'event_label': 'Mobile app started',
        'value': 0
      });
      this.client.mobileApp = true;
    };
    window.setDebug = () => {
      this.client.debug = true;
    };
    window.goBack = () => {
      return this.goBack();
    };
    this.client = new Client(this);
    console.warn("NEVER PASTE ANY CODE GIVEN BY OTHER PEOPLE HERE.");
    if (localStorage.getItem("session")) {
      this.client.account.setSession(
        JSON.parse(localStorage.getItem("session"), false)
      );
    }
    this.$vuetify.theme.dark =
      localStorage.getItem("darkmode") &&
      localStorage.getItem("darkmode") === "true";
  },
  mounted() {
    if (location.hash.length > 1) {
      this.parseHash();
    }
  },
  methods: {
    setTitle(title) {
      const text = `${title} - ${Globals.app_name}`;
      document.title = text;
    },
    goBack() {
      if(this.backMethod !== undefined) {
        this.backMethod();
        this.backMethod = undefined;
        return true;
      } else {
        return false;
      }
    },
    parseHash() {
      const hash = window.location.hash.substring(1);
      const args = hash.split(":");
      switch (args[0]) {
        case "r":
          this.client.account.confirmRegister(args[1]).then((response) => {
            this.setPage("home");
            if (response.success) {
              this.showInfo("account_confirmed");
            }
          });
          location.hash = "";
          break;
        case "p":
          this.setPage("home");
          this.$refs.editPasswordDialog.show(args[1], args[2]);
          location.hash = "";
          break;
        default:
          if (args.length === 1) {
            this.loadPublicProfile(hash);
            this.$gtag.event("load_public_profile", {
              event_category: "share",
              event_label: "Loaded public profile",
              value: 0,
            });
          } else if (args.length === 2) {
            this.loadPublicProfileList(args[0], args[1]);
            this.$gtag.event("load_public_list", {
              event_category: "share",
              event_label: "Loaded public list",
              value: 0,
            });
          }
          break;
      }
    },
    loadPublicProfile(name) {
      this.publicProfileName = name;
      this.setPage("profile");
    },
    loadPublicProfileList(name, list) {
      this.publicProfileName = name;
      this.publicProfileList = list;
      this.setPage("profile");
    },
    openList(list) {
      this.$refs.editorPanel.openList(list);
    },
    setPage(name) {
      this.page = this.pages[name];
    },
    showInfo(msg) {
      this.$refs.infoDialog.show("info", msg);
    },
    handleServerError(error) {
      this.$refs.infoDialog.show("error", error.msg);
    },
    showError(msg) {
      this.$refs.infoDialog.show("error", msg);
    },
    showUnitSelect() {
      this.$refs.unitSelectDialog.show();
    },
  },
};
</script>

<style scoped>
.tab-items {
  max-height: 100vh;
}
</style>

<style lang="sass">
@import '../node_modules/typeface-roboto/index.css'
</style>
  
<style>
html {
  overflow-y: auto !important;
  scroll-behavior: smooth;
}

.main-panel {
  max-height: calc(100vh - 64px);
  overflow-y: auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@media (max-width: 600px) {
  .v-dialog {
    margin-right: 0;
    margin-left: 0;
  }
}

.no-text-transform {
  text-transform: none !important;
}
</style>