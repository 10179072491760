<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn fab color="primary" v-bind="attrs" v-on="on" class="button">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item @click="removeImage()" v-if="canRemove">
          <v-list-item-icon>
              <v-icon>mdi-trash-can</v-icon>
          </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("remove") }}</v-list-item-title>
          <v-list-item-subtitle>{{ $t("remove_desc") }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="$emit('select', 'image')">
          <v-list-item-icon>
              <v-icon>mdi-image</v-icon>
          </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("image") }}</v-list-item-title>
          <v-list-item-subtitle>{{ $t("image_desc") }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "EditImageButton",
  props: ['client'],
  computed: {
    canRemove() {
      return !(this.client && this.client.account.profile.image && this.client.account.profile.image.type === 'none');
    }
  },
  methods: {
    removeImage() {
      this.client.account.editProfile('image', {
        type: 'none'
      });
    }
  }
};
</script>

<style scoped>
.button {
    position: absolute;
    right: 10%;
    bottom: 10%;
}
</style>


<i18n>
{
    "en": {
        "remove": "Remove image",
        "remove_desc": "Remove your current profile image.",
        "image": "Upload image",
        "image_desc": "Use any photo from your device."
    },
    "de": {
        "remove": "Bild entfernen",
        "remove_desc": "Das aktuelle Profilbild entfernen.",
        "image": "Bild hochladen",
        "image_desc": "Nutze ein beliebiges Foto von deimem Gerät"
    }
}
</i18n>