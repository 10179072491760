<template>
  <div>
    <div class="d-flex">
      <v-autocomplete
        :value="value[1]"
        @input="onInput($event)"
        :items="items"
        :label="standalone ? undefined : $t('label')"
        :validate-on-blur="standalone"
        :disabled="disabled"
        :no-data-text="$t('no_data')"
        :prepend-icon="displayIcon"
        hide-details
        ref="autocomplete"
      >
        <template v-slot:item="{ item }">
          <v-icon
            left
            v-text="map[item].icon"
            :color="MODE_COLORS[map[item].mode]"
          ></v-icon>
          {{ item }}
          <v-spacer />
          <v-btn
            icon
            right
            @click.stop="removeItem(item)"
            v-if="map[item].user"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <template slot="prepend-item">
          <v-card class="px-2 py-2 mb-4" v-if="showAdd">
            <div class="d-flex">
              <div class="my-auto">
                <IconPicker v-model="newCategory[0]" />
              </div>
              <v-text-field
                v-model="newCategory[1]"
                :label="$t('label_new')"
                hide-details
                @keydown.enter="confirmAdd()"
                maxlength="50"
              >
              </v-text-field>
              <v-btn icon @click="showAdd = false" class="my-auto">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <!--
              <v-btn
                icon
                @click="confirmAdd()"
                color="success"
                class="my-auto"
                :disabled="!addValid"
              >
                <v-icon>mdi-check</v-icon>
              </v-btn>
              -->
            </div>
            <div class="d-flex">
              <v-btn
                @click="confirmAdd()"
                color="success"
                class="my-1 mx-auto"
                v-if="addValid"
                small
              >
                <v-icon>mdi-plus</v-icon> {{ $t("add") }}
              </v-btn>
            </div>
            <v-checkbox
              v-model="saveAdd"
              :label="$t('save_add')"
              hide-details
              class="ml-2"
            ></v-checkbox>
            <div class="ml-3" style="color: rgba(0, 0, 0, 0.6)">
              <small v-if="saveAdd">{{ $t("save_notice") }}</small>
              <small v-else>{{ $t("no_save_notice") }}</small>
            </div>
          </v-card>
          <v-list-item
            v-else
            block
            text
            color="success"
            class="my-auto"
            @click="showAdd = true"
          >
            <v-list-item-icon class="my-auto mr-2">
              <v-icon color="success">mdi-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-title color="success">
              <b>{{ $t("new") }}</b>
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-autocomplete>
    </div>
  </div>
</template>

<script>
import IconPicker from "./IconPicker";

export default {
  name: "CategoryPicker",
  props: ["value", "disabled", "client", "list", "initfocus", "standalone"],
  components: {
    IconPicker,
  },
  data: () => ({
    showAdd: false,
    newCategory: ["mdi-bag-personal", ""],
    selectedCategory: undefined,
    saveAdd: true,
    MODE: {
      ACCOUNT: 0,
      LIST: 1,
      DEFAULT: 2,
    },
    MODE_COLORS: ["info", "muted", "default"],
    defaultCategories: {
      en: [
        ["mdi-tent", "Sleeping"],
        ["mdi-silverware-fork-knife", "Cooking"],
        ["mdi-food-apple", "Food"],
        ["mdi-tshirt-crew", "Clothing"],
        ["mdi-power-plug", "Electronics"],
      ],
      de: [
        ["mdi-tent", "Übernachtung"],
        ["mdi-silverware-fork-knife", "Kochen"],
        ["mdi-food-apple", "Nahrung"],
        ["mdi-tshirt-crew", "Kleidung"],
        ["mdi-power-plug", "Elektronik"],
      ],
    },
  }),
  mounted() {
    if (this.initfocus) {
      this.$refs["autocomplete"].focus();
    }
  },
  computed: {
    categories() {
      const accountCategories = this.client.account.profile.categories.map(
        (value) => [value[0], value[1], 0]
      );
      const defaultCategories = this.defaultCategories[this.$i18n.locale].map(
        (value) => [value[0], value[1], 2]
      );
      let categories = [];
      if (this.list === undefined) {
        categories = accountCategories.concat(defaultCategories);
      } else {
        const listCategories = this.list.categories.map((value) => [
          value[0],
          value[1],
          1,
        ]);
        categories = accountCategories
          .concat(listCategories)
          .concat(defaultCategories);
      }
      for (const item of this.client.gear.list) {
        if (
          !categories.find(
            (value) =>
              value[0] === item.category[0] && value[1] === item.category[1]
          )
        ) {
          categories.push(item.category);
        }
      }
      if (this.value) {
        let valueFound = false;
        for (const category of categories) {
          if (category[0] === this.value[0] && category[1] === this.value[1]) {
            valueFound = true;
            break;
          }
        }
        if (!valueFound) {
          categories.push(this.value);
        }
      }
      return categories;
    },
    items() {
      return this.categories.map((value) => value[1]);
    },
    map() {
      const map = {};
      let index = [0, 0, 0];
      for (let category of this.categories) {
        map[category[1]] = {
          icon: category[0],
          user: category[2] <= 1,
          mode: category[2],
          index: index[category[2]],
        };
        index[category[2]]++;
      }
      return map;
    },
    displayIcon() {
      if (this.selectedCategory !== undefined) {
        return this.selectedCategory[0];
      } else if (this.value !== undefined) {
        return this.value[0];
      } else {
        return "mdi-image";
      }
    },
    addValid() {
      if (this.saveAdd) {
        const text = this.newCategory[1].toLowerCase().trim();
        for (const category of this.items) {
          if (category.toLowerCase() === text) {
            return false;
          }
        }
      }
      return this.newCategory[1].length >= 1 && this.newCategory[1].length < 50;
    },
  },
  methods: {
    onInput(category) {
      this.selectedCategory = this.getSelectedCategory(category);
      if (this.selectedCategory !== undefined) {
        this.showAdd = false;
        this.$emit("input", [
          this.selectedCategory[0],
          this.selectedCategory[1],
        ]);
      }
    },
    async confirmAdd() {
      if (this.addValid) {
        if (this.list !== undefined) {
          const response = await this.client.lists.addCategory(this.list._id, [
            ...this.newCategory,
          ]);
          if (response.success) {
            if (this.saveAdd) {
              await this.client.account.addCategory([...this.newCategory]);
            }
            this.showAdd = false;
            this.list.categories.push([...this.newCategory]);
            setTimeout(() => {
              this.onInput(this.newCategory[1]);
              this.newCategory[1] = "";
            }, 0);
          }
        } else {
          const response = await this.client.account.addCategory([
            ...this.newCategory,
          ]);
          if (response.success) {
            this.showAdd = false;
            setTimeout(() => {
              this.onInput(this.newCategory[1]);
              this.newCategory[1] = "";
            }, 0);
          }
        }
      }
    },
    getSelectedCategory(name) {
      for (const category of this.categories) {
        if (category[1] === name) {
          return category;
        }
      }
      return undefined;
    },
    removeItem(item) {
      if (this.map[item].mode === this.MODE.ACCOUNT) {
        this.client.account.removeCategory(this.map[item].index);
      } else if (this.map[item].mode === this.MODE.LIST) {
        this.client.lists
          .removeCategory(this.list._id, this.map[item].index)
          .then((response) => {
            if (response.success) {
              this.list.categories.splice(this.map[item].index, 1);
            }
          });
      }
    },
  },
};
</script>

<i18n>
{
    "en": {
        "label": "Category",
        "new": "New category",
        "label_new": "New category...",
        "save_add": "Save for other lists",
        "no_data": "No category found",
        "save_notice": "The category is saved for use in new lists.",
        "no_save_notice": "The category is only available in this list.",
        "own_saved": "Personal categories (Saved)",
        "own": "Personal categories (This list)",
        "suggesred": "Suggestions"
    },
    "de": {
        "label": "Kategorie",
        "new": "Neue Kategorie",
        "label_new": "Neue Kategorie...",
        "save_add": "Für andere Listen speichern",
        "no_data": "Keine Kategorie gefunden",
        "save_notice": "Die Kategorie wird für zukünftige Listen gespeichert.",
        "no_save_notice": "Die Kategorie ist nur in dieser Liste verfügbar.",
        "own_saved": "Eigene Kategorien (Gespeichert)",
        "own": "Eigene Kategorien (Diese Liste)",
        "suggesred": "Vorschläge"
    }
}
</i18n>