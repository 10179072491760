<template>
    <SimpleDialog :title="$t('title')" :permanent="true" :visible="visible">
        <LoaderOverlay :loading="client.loading" />
        <h2 class="my-3">{{$t('question')}}</h2>
        <div class="big-buttons">
            <div class="big-button" v-for="unit of units" :key="unit" @click="select(unit)">
                <div>{{$t(unit)}}</div>
            </div>
        </div>
    </SimpleDialog>
</template>

<script>
import SimpleDialog from "./SimpleDialog";
import LoaderOverlay from "./LoaderOverlay";
export default {
  name: "UnitSelectDialog",
  props: ["client"],
  components: {
    SimpleDialog,
    LoaderOverlay,
  },
  data: () => ({
    visible: false,
    units: ["grams", "pounds"],
  }),
  methods: {
    show() {
      this.visible = true;
    },
    select(unit) {
      this.client.account.editProfile("unit", unit).then(() => {
        this.visible = false;
      });
    },
  },
};
</script>

<style scoped>
.big-buttons {
    display: flex;
    width: 100%;
    height: 200px;
}
.big-button {
    cursor: pointer;
    flex: 1;
    background-color: lightgray;
    transition: 300ms;
}
.big-button:hover {
    background-color: gray;
    color: white;
}
.big-button > div {
    text-align: center;
    margin-top: 100px;
    transform: translateY(-50%);
    font-weight: bold;
}
.big-button:nth-child(1) {
    border-right: 1px solid gray;
}
.big-button:nth-child(2) {
    border-left: 1px solid gray;
}
</style>

<i18n>
{
    "en": {
        "title": "Weight unit",
        "question": "What unit do you prefer for weight measurement?",
        "grams": "Grams (g / kg)",
        "pounds": "Pound (lb / oz)"
    },
    "de": {
        "title": "Gewichtseinheit",
        "question": "Welche Einheit möchten sie für Gewichte nutzen?",
        "grams": "Gramm (g / kg)",
        "pounds": "Pfund (lb / oz)"
    }
}
</i18n>