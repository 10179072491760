<template>
  <v-text-field
    solo
    :readonly="disabled"
    large
    :label="$t('label')"
    :value="query"
    @keydown="onInputKeydown($event)"
    @input="onInput($event)"
  >
    <v-btn slot="append" color="primary" @click="submit()" :disabled="disabled">
      {{ $t("submit") }}
    </v-btn>
    <v-icon color="primary" slot="prepend-inner">mdi-magnify</v-icon>
  </v-text-field>
</template>

<script>
import Config from "../../config";

export default {
  name: "DiscoverSearchBar",
  props: ["disabled"],
  data: () => ({
    query: "",
  }),
  computed: {
    valid() {
      return (
        this.query.length >= Config.Discover.KeywordLength.Min &&
        this.query.length <= Config.Discover.KeywordLength.Max
      );
    },
  },
  methods: {
    submit() {
      if (this.valid) {
        this.$emit("input", this.query);
      }
    },
    onInput(text) {
      if (
        this.query.length >= Config.Discover.KeywordLength.Min &&
        text.length < Config.Discover.KeywordLength.Min
      ) {
        this.$emit("clear");
      }
      this.query = text;
    },
    onInputKeydown(event) {
      if (event.key === "Enter" && this.valid) {
        this.submit();
      }
    },
    setValue(text) {
      this.query = text;
    },
  },
};
</script>

<i18n>
{
    "en": {
        "label": "Search for keywords and lists...",
        "submit": "Search"
    },
    "de": {
        "label": "Suche nach Listen und Schlüsselwörter...",
        "submit": "Suchen"
    }
}
</i18n>