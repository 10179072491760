<template>
  <SimpleDialog
    :title="$t('title')"
    @hide="visible = false"
    :visible="visible"
    icon="mdi-bag-personal"
  >
    <LoadingOverlay :loading="client.loading" />
    <v-toolbar class="mt-3">
      <v-text-field
        v-model="filter"
        clearable
        @click:clear="filter = ''"
        flat
        hide-details
        prepend-inner-icon="mdi-magnify"
        :label="$t('search')"
      ></v-text-field>
    </v-toolbar>
    <v-virtual-scroll :items="items" :height="scrollHeight" item-height="50">
      <template v-slot:default="{ item }">
        <v-list-item>
          <v-checkbox
            @click.stop=""
            @change="setSelected(item, $event)"
            :value="isSelected(item)"
          ></v-checkbox>
          <v-list-item-icon class="list-icon">
            <img
              v-if="item.img && item.img.length > 0"
              :src="item.img"
              :title="item.category[1]"
              class="image"
            />
            <i
              v-else
              class="mdi icon info--text"
              :title="item.category[1]"
              :class="item.category[0]"
            ></i>
          </v-list-item-icon>
          <v-list-item-content>
            <div class="d-flex">
              <div>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ getSubLabel(item) }}
                </v-list-item-subtitle>
              </div>
            </div>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              icon
              color="success"
              @click="selectSingle(item)"
              :title="$t('add_single')"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-virtual-scroll>
    <template slot="actions">
      <v-btn text color="success" :disabled="!valid" @click="submit()">
        {{ $t("add_items") }} ({{ selectedItems.length }})
      </v-btn>
    </template>
  </SimpleDialog>
</template>

<script>
import SimpleDialog from "../../generic/SimpleDialog.vue";
import Globals from "../../../lib/globals";
import LoadingOverlay from "../../generic/LoaderOverlay.vue";
import Unit from "../../../lib/unit";

export default {
  name: "PickGearDialog",
  props: ["client"],
  components: {
    SimpleDialog,
    LoadingOverlay,
  },
  data: () => ({
    visible: false,
    filter: "",
    selectedItems: [],
  }),

  computed: {
    scrollHeight() {
      return window.innerHeight / 2 > 600 ? 600 : window.innerHeight / 2;
    },
    categories() {
      const categories = [];
      for (const item of this.client.gear.list) {
        let includes = false;
        for (const category of categories) {
          if (category[1] === item.category) {
            includes = true;
            break;
          }
        }
        if (!includes) {
          categories.push(item.category);
        }
      }
      return categories;
    },
    items() {
      let items = [];
      for (const category of this.categories) {
        if (this.filter.length > 0) {
          items = items.concat(
            this.client.gear.list.filter(
              (item) => item.category === category && this.matchesFilter(item)
            )
          );
        } else {
          items = items.concat(
            this.client.gear.list.filter((item) => item.category === category)
          );
        }
      }
      return items;
    },
    valid() {
      return this.selectedItems.length > 0;
    },
  },
  methods: {
    show() {
      this.visible = true;
      if (!this.client.gear.listLoaded) {
        this.client.gear.loadList();
      }
    },
    hide() {
      this.visible = false;
      this.selectedItems = [];
    },
    submit() {
      if (this.valid) {
        this.$emit("pick", this.selectedItems);
        this.visible = false;
      }
    },
    selectSingle(item) {
      this.selectedItems.push(item);
      this.submit();
    },
    getSubLabel(item) {
      if (item.brand.length > 0) {
        return `${item.brand}, ${Unit.formatString(
          item.weight,
          this.client.account.profile.unit
        )}`;
      } else {
        return Unit.formatString(item.weight, this.client.account.profile.unit);
      }
    },
    matchesFilter(item) {
      return (
        item.name.toLowerCase().includes(this.filter) ||
        item.brand.toLowerCase().includes(this.filter) ||
        item.category[1].toLowerCase().includes(this.filter)
      );
    },
    getCategoryColor(category) {
      return Globals.category_colors[category];
    },
    setSelected(item, state) {
      if (state) {
        this.selectedItems.push(item);
      } else {
        this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
      }
    },
    isSelected(item) {
      return this.selectedItems.includes(item);
    },
    getCategoryIcon(category) {
      return Globals.category_icons[category];
    },
  },
};
</script>

<style scoped>
.list-icon {
  margin-right: 0.5rem;
  margin-top: auto !important;
  margin-bottom: auto !important;
  font-size: 2rem;
}
.image {
  max-width: 2rem;
  max-height: 2rem;
  margin-top: auto;
  margin-bottom: auto;
}
</style>

<i18n>
{
    "en": {
        "title": "My gear",
        "search": "Search name, category or manufacturer",
        "add_items": "Add",
        "add_single": "Add this item"
    },
    "de": {
        "title": "Meine Ausrüstung",
        "search": "Namen, Kategorie oder Hersteller suchen",
        "add_items": "Hinzufügen",
        "add_single": "Diesen Gegenstand hinzufügen"
    }
}
</i18n>