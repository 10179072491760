import { render, staticRenderFns } from "./DescriptionText.vue?vue&type=template&id=306ac658&scoped=true&"
import script from "./DescriptionText.vue?vue&type=script&lang=js&"
export * from "./DescriptionText.vue?vue&type=script&lang=js&"
import style0 from "./DescriptionText.vue?vue&type=style&index=0&id=306ac658&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "306ac658",
  null
  
)

/* custom blocks */
import block0 from "./DescriptionText.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VCardTitle,VCol,VIcon,VRow})
