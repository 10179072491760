<template>
  <div>
    <LoadingOverlay :loading="client.loading" />
    <PrivacyDialog ref="privacyDialog" />
    <div v-if="submitted">
      <v-icon color="success">mdi-information-outline</v-icon>
      {{ $t("submitted") }}
    </div>
    <div v-else>
      <v-text-field
        :label="$t('mail')"
        maxlength="60"
        v-model="input.mail"
      ></v-text-field>
      <v-text-field
        :label="$t('name')"
        @input="onNameInput()"
        maxlength="60"
        v-model="input.name"
      ></v-text-field>
      <v-text-field
        :label="$t('password')"
        :type="showPass ? 'text' : 'password'"
        maxlength="50"
        v-model="input.pass"
        :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPass = !showPass"
      ></v-text-field>
      <v-text-field
        :label="$t('repeat_password')"
        type="password"
        maxlength="50"
        v-model="repeatPass"
      ></v-text-field>
      <p class="mb-0">{{ $t("security_question") }}:</p>
      <v-text-field
        :label="$t('how_much_is') + ' ' + firstSum + '+' + secondSum + '?'"
        maxlength="10"
        v-model="sumResult"
      ></v-text-field>
      <v-checkbox v-model="acceptPrivacy">
        <template v-slot:label>
          <div>
            {{ $t("i_accept") }}
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <a href="#" @click.stop="$refs.privacyDialog.show()" v-on="on">
                  {{ $t("privacy") }}
                </a>
              </template>
              {{ $t("show") }}
            </v-tooltip>
          </div>
        </template>
      </v-checkbox>
      <small class="danger">
        {{ $t(validationMessage) }}
      </small>
      <div class="d-flex mt-3">
        <v-spacer />
        <v-btn color="success" @click="submit()" :disabled="!valid">
          {{ $t("register") }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import LoadingOverlay from "../generic/LoaderOverlay";
import PrivacyDialog from "../home/imprint/PrivacyDialog";

export default {
  name: "RegisterDialog",
  props: ["client"],
  components: {
    LoadingOverlay,
    PrivacyDialog,
  },
  data: () => ({
    showPass: false,
    submitted: false,
    firstSum: (Math.random() * 10 + 1) | 0,
    secondSum: (Math.random() * 10 + 1) | 0,
    sending: false,
    acceptPrivacy: false,
    sumResult: "",
    input: {
      mail: "",
      pass: "",
      name: "",
    },
    repeatPass: "",
  }),
  computed: {
    mailValid() {
      const split = this.input.mail.split("@");
      if (split.length === 2 && this.input.mail.length <= 60) {
        const domainSplit = split[1].split(".");
        if (domainSplit.length >= 2) {
          return (
            split[0].length >= 2 &&
            domainSplit[0].length >= 2 &&
            domainSplit[1].length >= 2
          );
        }
      }
      return false;
    },
    sumValid() {
      try {
        return parseInt(this.sumResult) === this.firstSum + this.secondSum;
      } catch (ex) {
        return false;
      }
    },
    passValid() {
      return this.input.pass.length >= 6 && this.input.pass.length <= 50;
    },
    nameValid() {
      const trimmed = this.input.name.trim();
      return trimmed.length >= 2 && trimmed.length <= 60;
    },
    valid() {
      return (
        this.nameValid &&
        this.mailValid &&
        this.passValid &&
        this.sumValid &&
        this.acceptPrivacy
      );
    },
    validationMessage() {
      if (this.input.mail.length > 0 && !this.mailValid) {
        return "invalid_mail";
      }
      if (this.input.name.length > 0 && !this.nameValid) {
        return "invalid_name";
      }
      if (this.input.pass.length > 0 && !this.passValid) {
        return "invalid_password";
      }
      if (this.input.pass !== this.repeatPass) {
        return "passwords_not_equal";
      }
      return "";
    },
  },
  methods: {
    onNameInput() {
      while (this.input.name.includes(" ")) {
        this.input.name = this.input.name.replace(" ", "");
      }
    },
    submit() {
      this.input.mail = this.input.mail.trim();
      this.input.name = this.input.name.trim();
      this.sending = true;
      this.client.account.register({...this.input}).then((response) => {
        this.sending = false;
        if (response.success) {
          this.submitted = true;
          this.$gtag.event("register", {
            'event_category': 'account',
            'event_label': 'Account registred',
            'value': 0
          });
        }
      });
    },
  },
};
</script>

<i18n>
{
    "en": {
        "mail": "E-mail address",
        "password": "Password",
        "login": "Login",
        "forgot_pass": "Forgot password?",
        "username": "Username",
        "name": "Username",
        "repeat_password": "Repeat password",
        "register": "Sign up",
        "invalid_mail": "Invalid e-mail address",
        "invalid_name": "Name too short",
        "invalid_password": "Password too short",
        "passwords_not_equal": "Passwords do not match",
        "submitted": "Your account has been registred. Check your e-mails to confirm it.",
        "security_question": "Security question",
        "how_much_is": "How much is",
        "security_result": "Result",
        "i_accept": "Accept the",
        "privacy": "data protection",
        "show": "Show"
    },
    "de": {
        "mail": "E-Mail Adresse",
        "password": "Passwort",
        "login": "Anmelden",
        "forgot_pass": "Passwort vergessen?",
        "name": "Nutzername",
        "repeat_password": "Passwort wiederholen",
        "register": "Account registrieren",
        "invalid_mail": "Ungültige E-Mail Adresse",
        "invalid_name": "Der Name ist zu kurz",
        "invalid_password": "Das Passwort ist zu kurz",
        "passwords_not_equal": "Die Passwörter stimmen nicht überein",
        "submitted": "Dein Account wurde registriert. Schaue in deine E-Mails um deine Anmeldung zu bestätigen.",
        "security_question": "Sicherheitsfrage",
        "how_much_is": "Wie viel ist",
        "security_result": "Ergebnis",
        "i_accept": "Ich akzeptiere die",
        "privacy": "Datenschutzerklärung",
        "show": "Anzeigen"
    }
}
</i18n>