<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-on="on" v-bind="attrs">
        <v-icon>mdi-share-variant</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <v-text-field :value="link" readonly :label="$t('label')" @click.stop=""/>
        <v-btn color="info" small @click.stop="" v-if="copied"><v-icon left>mdi-check</v-icon> {{$t('copied')}}</v-btn>
        <v-btn color="info" small @click.stop="copy()" v-else
          ><v-icon left>mdi-content-copy</v-icon> {{ $t("copy") }}</v-btn
        >
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "ShareMenu",
  props: ["client", "list"],
  computed: {
    link() {
      return `${window.location.protocol}//${window.location.host}/#${this.client.account.profile.name}:${this.list._id}`;
    },
  },
  data: () => ({
      copied: false
  }),
  methods: {
      copy() {
          navigator.clipboard.writeText(this.link);
          this.copied = true;
          setTimeout(() => {
              this.copied = false;
          }, 2000);
      }
  }
};
</script>

<i18n>
{
    "en": {
        "label": "Link to share the list",
        "copy": "Copy",
        "copied": "Copied"
    },
    "de": {
        "label": "Link zum teilen der Liste",
        "copy": "Kopieren",
        "copied": "Kopiert"
    }
}
</i18n>