<template>
    <v-card class="create-first-button" @click="$emit('click')">
        <v-card-text>
        <v-row>
            <v-spacer />
            <v-col>
                <v-icon color="success" class="plus-icon">mdi-plus</v-icon>
            </v-col>
            <v-spacer />
        </v-row>
        <div class="label">
            {{text}}
        </div>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'CreateFirstButton',
    props: ['text'],
    methods: {
        onClick() {
            console.log("On click");
        }
    }
}
</script>

<style scoped>
.create-first-button {
    max-width: 100%;
    width: 200px;
    transition: 300ms;
    cursor: pointer;
    z-index: 1;
}
.create-first-button:hover {
    box-shadow: 0 0 1rem black !important;
}
.plus-icon {
    font-size: 5rem;

}
.label {
    text-align: center;
}
</style>