<template>
  <v-btn-toggle :value="list.view" mandatory color="primary" slot="append">
    <v-btn
      v-for="mode of modes"
      :key="mode.id"
      @click="setMode(mode.id)"
      small
      :value="mode.id"
      class="no-text-transform"
      >{{ $t(mode.id) }}</v-btn
    >
  </v-btn-toggle>
</template>

<script>
export default {
  name: "ViewModeSelector",
  props: ["client", "list"],
  data: () => ({
    modes: [
      { id: "list", icon: "mdi-format-list-bulleted" },
      { id: "table", icon: "mdi-table" },
    ],
  }),
  methods: {
    setMode(mode) {
      if (mode !== this.list.view) {
        this.client.lists
          .changeSetting(this.list._id, "view", mode, false, true)
          .then((response) => {
            if (response.success) {
              this.list.view = mode;
            }
          });
      }
    },
  },
};
</script>

<i18n>
{
    "en": {
        "label": "View",
        "list": "List",
        "table": "Table"
    },
    "de": {
        "label": "Ansicht",
        "list": "Liste",
        "table": "Tabelle"
    }
}
</i18n>