<template>
  <v-card flat>
    <v-card-text>
      {{ $t("title") }}
      <div class="d-flex">
      <v-progress-linear
        :value="packedPercentage"
        height="28"
        :color="packedPercentage === 100 ? 'success' : 'primary'"
      >
        <template v-slot:default>
          <strong>{{ itemsPacked }} / {{list.items.length}}</strong>
        </template>
      </v-progress-linear>
      <v-btn text small color="error" @click="$emit('reset')" v-if="itemsPacked > 0">
          {{ $t('reset') }}
      </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "PackingProgressBar",
  props: ["list"],
  computed: {
    itemsPacked() {
      let packed = 0;
      for(const item of this.list.items) {
          if(item.pack) packed++;
      }
      return packed;
    },
    packedPercentage() {
      return ((this.itemsPacked / this.list.items.length) * 100) | 0;
    },
  },
};
</script>

<i18n>
{
    "en": {
        "title": "Packing progress",
        "reset": "Reset"
    },
    "de": {
        "title": "Gepackte Ausrüstung",
        "reset": "Reset"
    }
}
</i18n>