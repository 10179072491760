<template>
  <SimpleDialog
    :title="$t('title')"
    icon="mdi-image"
    :visible="visible"
    @hide="hide()"
  >
    <LoadingOverlay :loading="client.loading" />
    <v-tabs-items :value="tab" class="mt-2" touchless>
      <v-tab-item>
        <p>{{ $t("select_mode_question") }}</p>
        <v-row>
          <v-col>
            <v-btn color="primary" @click="mode = 'avatar'">
              <v-icon>mdi-account</v-icon> {{ $t("create_avatar") }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn color="primary" @click="mode = 'upload'">
              <v-icon>mdi-upload</v-icon> {{ $t("upload_image") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-card flat style="padding: -10px">
          <v-card-title>
            <!--
                  <v-btn icon @click="mode=undefined">
                      <v-icon>mdi-arrow-left</v-icon>
                  </v-btn>
                  -->
            {{ $t("create_avatar") }}
          </v-card-title>
          <v-card-text>
            <AvatarCustomizer
              :avatarData="avatar"
              @avatarChanged="onAvatarChanged($event)"
              @save="saveAvatar()"
            />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-title>
            <!--
                  <v-btn icon @click="mode=undefined">
                      <v-icon>mdi-arrow-left</v-icon>
                  </v-btn>
                  -->
            {{ $t("upload_image") }}
          </v-card-title>
          <v-card-text>
            <ImageUploadForm @save="uploadImage($event)" />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </SimpleDialog>
</template>

<script>
import SimpleDialog from "../../generic/SimpleDialog";
import AvatarCustomizer from "./AvatarCustomizer";
import LoadingOverlay from "../../generic/LoaderOverlay";
import ImageUploadForm from "./ImageUploadForm";

export default {
  name: "EditImageDialog",
  props: ["image", "client"],
  components: {
    SimpleDialog,
    AvatarCustomizer,
    LoadingOverlay,
    ImageUploadForm,
  },
  data: () => ({
    visible: false,
    mode: undefined,
    avatar: undefined,
  }),
  created() {
    if (this.image && this.image.type === "avatar") {
      this.avatar = this.image.avatar;
    }
  },
  computed: {
    tab() {
      if (this.mode === undefined) {
        return 0;
      } else {
        return this.mode === "avatar" ? 1 : 2;
      }
    },
  },
  methods: {
    show(mode) {
      console.log("Showing", mode);
      this.mode = mode;
      this.visible = true;
    },
    hide() {
      this.visible = false;
      this.mode = undefined;
    },
    onAvatarChanged(avatar) {
      this.avatar = avatar;
    },
    saveAvatar() {
      console.log("On save avatar");
      this.client.account
        .editProfile("image", {
          type: "avatar",
          avatar: this.avatar,
        })
        .then((response) => {
          if (response.success) {
            this.hide();
            this.$gtag.event("update_avatar", {
              event_category: "profile",
              event_label: "Updated avatar",
              value: 0,
            });
          }
        });
    },
    uploadImage(image) {
      this.client.account
        .editProfile("image", {
          type: "image",
          image: image,
        })
        .then((response) => {
          if (response.success) {
            this.hide();
            this.$emit("imageChanged");
            this.$gtag.event("update_image", {
              event_category: "profile",
              event_label: "Updated profile image",
              value: 0,
            });
          }
        });
    },
  },
};
</script>

<i18n>
{
    "en": {
        "title": "Edit image",
        "select_mode_question": "How do you want to set your profile image?",
        "create_avatar": "Create avatar",
        "upload_image": "Upload image"
    },
    "de": {
        "title": "Bild bearbeiten",
        "select_mode_question": "Wie möchtest du dein Profilbild setzen?",
        "create_avatar": "Avatar erstellen",
        "upload_image": "Bild hochladen"
    }
}
</i18n>