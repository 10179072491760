import { Paired12 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.brewer';

const Globals = {
    app_name: 'PackTrek',
    gear_categories: [
        'sleeping',
        'cooking',
        'food',
        'clothing',
        'electronics',
        'other'
    ],
    category_icons: {
        'sleeping': 'mdi-tent',
        'cooking': 'mdi-silverware-fork-knife',
        'food': 'mdi-food-apple',
        'clothing': 'mdi-tshirt-crew',
        'electronics': 'mdi-power-plug',
        'other': 'mdi-circle-medium'
    },
    category_colors: {
        'sleeping': 'rgb(255, 99, 132)',
        'cooking': 'rgb(54, 162, 235)',
        'food': 'rgb(255, 206, 86)',
        'clothing': 'rgb(75, 192, 192)',
        'electronics': 'rgb(255, 159, 64)',
        'other': 'rgb(153, 102, 255)'        
    },    
    category_colors_lite: {
        'sleeping': 'rgba(255, 99, 132, 0.2)',
        'cooking': 'rgba(54, 162, 235, 0.2)',
        'food': 'rgba(255, 206, 86, 0.2)',
        'clothing': 'rgba(75, 192, 192, 0.2)',
        'electronics': 'rgba(255, 159, 64, 0.2)',
        'other': 'rgba(153, 102, 255, 0.2)'        
    },
    colors: Paired12,
    getCategoryColor(index) {
        return Globals.colors[index % (Globals.colors.length - 1)];
    }
}

//"rgba(255, 159, 64, 0.2)",

export default Globals;
