<template>
  <div>
    <v-toolbar>
      <v-btn icon @click="$emit('abort')">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title>
        {{ $t("title") }}
      </v-toolbar-title>
    </v-toolbar>
    <v-card flat>
      <v-card-subtitle>
        {{ $t("title_mode") }}
      </v-card-subtitle>
      <v-card-text>
        <v-radio-group
          v-model="mode"
          @change="
            loaded = false;
            loading = false;
            error = false;
          "
        >
          <v-radio value="url" :label="$t('url')"></v-radio>
          <v-radio value="upload" :label="$t('upload')"></v-radio>
        </v-radio-group>
      </v-card-text>
    </v-card>
    <v-card flat>
      <v-card-subtitle>
        {{ $t(mode) }}
      </v-card-subtitle>
      <v-card-text>
        <template v-if="mode === 'url'">
          {{ $t("url_explain") }}
          <v-text-field
            :label="$t('paste_url')"
            v-model="url"
            prepend-icon="mdi-web"
            :disabled="loading"
          >
            <template v-if="loading" slot="append">
              <v-progress-circular
                indeterminate
                color="info"
              ></v-progress-circular>
            </template>
            <template v-if="error" slot="append">
              <v-icon color="error"> mdi-alert-circle </v-icon>
            </template>
            <template v-if="loaded" slot="append">
              <v-icon color="success"> mdi-check </v-icon>
            </template>
          </v-text-field>
          <img
            class="img-preview"
            :src="url"
            @load="
              loaded = true;
              loading = false;
              error = false;
            "
            @loadstart="
              loading = true;
              loaded = false;
              error = false;
            "
            @error="
              loading = false;
              loaded = false;
              error = true;
            "
          />
        </template>
        <template v-if="mode === 'upload'">
          {{ $t("upload_explain") }}
          <br><br>
          <v-progress-circular
            indeterminate
            color="info"
            v-if="loading"
          ></v-progress-circular>
          <input
            type="file"
            accept="image/*"
            @input="onImageInput($event)"
            v-else
          />
          <img v-if="loaded" class="mt-1" :src="data" />
        </template>
      </v-card-text>
      <div class="d-flex">
        <v-spacer />
        <v-btn text color="success" :disabled="!loaded" @click="submit()">
          <v-icon left>mdi-check</v-icon> {{ $t("save") }}
        </v-btn>
      </div>
    </v-card>
  </div>
</template>  

<script>
import ImageUtil from "../../../../lib/image";

export default {
  name: "AddImageForm",
  props: ['client'],
  data: () => ({
    mode: "url",
    url: "",
    data: undefined,
    loading: false,
    loaded: false,
    error: false,
    fullImg: undefined,
  }),
  methods: {
    submit() {
      if(this.mode === 'url') {
        this.$emit("input", {url: this.url});
        this.reset();
      } else {
        this.client.gear.uploadImage(this.data).then(response => {
          if(response.success) {
            this.$emit("input", {url: this.idToUrl(response.image), id: response.image});
            this.reset();
          }
        });
      }
    },
    idToUrl(id) {
      return `${window.location.protocol}//${window.location.host}/images/gear/${id}.png`;
    },
    reset() {
      this.data = undefined;
      this.url = "";
      this.loading = false;
      this.loaded = false;
      this.error = false;
      this.mode = 'url';
    },
    onLoadFullImg() {
      this.data = ImageUtil.fitSize(this.fullImg, 512, 512);
      this.loaded = true;
      this.loading = false;
      this.error = false;
    },
    onLoadFullImgError() {
      this.loaded = false;
      this.error = true;
      this.loading = false;
    },
    onReadFile(event) {
      this.fullImg = new Image();
      this.fullImg.onload = this.onLoadFullImg;
      this.fullImg.onerror = this.onLoadFullImgError;
      this.fullImg.src = event.target.result;
    },
    onErrorFile() {
      this.error = true;
      this.loading = false;
    },
    onImageInput(event) {
      this.loaded = false;
      this.error = false;
      this.loading = false;
      const fr = new FileReader();
      fr.onload = this.onReadFile;
      fr.onerror = this.onErrorFile;
      fr.readAsDataURL(event.target.files[0]);
    },
  },
};
</script>

<style scoped>
.img-preview {
  max-width: 300px;
}
</style>

<i18n>
{
    "en": {
        "title": "Add image",
        "title_mode": "Image source",
        "loading": "Loading image...",
        "save": "Save",
        "url": "Paste URL",
        "upload": "Upload or take image",
        "paste_url": "Paste image url...",
        "url_explain": "You can past a url from any online image. Good sources are the manufacturers website or online shops.",
        "upload_explain": "You can upload any photo from your device. On mobile devices you can also capture a photo."
    },
    "de": {
        "title": "Foto hinzufügen",
        "title_mode": "Quelle des Fotos",
        "loading": "Foto wird geladen...",
        "save": "Übernehmen",
        "url": "URL einfügen",
        "upload": "Foto hochladen oder aufnehmen",
        "paste_url": "Bild-URL einfügen...",
        "url_explain": "Du kannst eine Adresse von einem beliebigen Online-BIld einfügen. Gute Quellen sind z.B. die Website des Herstellers oder Onlineshops.",
        "upload_explain": "Du kannst ein beliebiges Foto von deinem Gerät hochladen. Auf Mobilgeräten kannst du auch sofort ein Foto aufnehmen."
    }
}
</i18n>