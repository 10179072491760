<template>
  <div>
    <v-text-field
      :label="standalone ? undefined : $t('label')"
      type="number"
      :value="value"
      @input="onInput($event)"
      @keydown="onKeydown($event)"
      hide-details
      :disabled="disabled"
    >
      <template slot="append">
        <v-btn-toggle
          v-model="largeUnit"
          mandatory
          color="primary"
          slot="append"
        >
          <v-btn
            :value="false"
            small
            @click="toggleLargeUnit(false)"
            class="no-text-transform"
          >
            {{ unit === "pounds" ? "oz" : "g" }}
          </v-btn>
          <v-btn
            :value="true"
            small
            @click="toggleLargeUnit(true)"
            class="no-text-transform"
          >
            {{ unit === "pounds" ? "lb" : "kg" }}
          </v-btn>
        </v-btn-toggle>
      </template>
    </v-text-field>
    <v-btn
      small
      @click="onBlur()"
      v-if="standalone"
      color="success"
      class="mt-3"
    >
      <v-icon>mdi-content-save</v-icon> {{ $t("save") }}
    </v-btn>
  </div>
</template>

<script>
import UnitConverter from "../../../lib/unit";

export default {
  name: "WeightInput",
  props: ["value", "unit", "disabled", "standalone"],
  data: () => ({
    largeUnit: false,
    currentValue: 0,
    inititalValue: 0,
  }),
  created() {
    if (this.standalone) {
      this.inititalValue = this.value;
    }
  },
  computed: {
    displayValue() {
      return this.convertToDisplay(this.value);
    },
  },
  methods: {
    toggleLargeUnit(state) {
      this.largeUnit = state;
      this.onInput(this.value);
    },
    convertToDisplay(value) {
      if (this.unit === "pounds") {
        if (this.largeUnit) {
          return UnitConverter.convertToHigher(
            UnitConverter.toOunce(value),
            this.unit
          );
        } else {
          return UnitConverter.toOunce(value);
        }
      } else {
        if (this.largeUnit) {
          return UnitConverter.convertToHigher(value, this.unit);
        } else {
          return value;
        }
      }
    },
    applyGrams(grams) {
      this.onInput(this.convertToDisplay(grams));
    },
    convertToGrams(value) {
      if (this.unit === "pounds") {
        if (this.largeUnit) {
          return UnitConverter.toGrams(
            UnitConverter.convertToLower(value, this.unit),
            this.unit
          );
        } else {
          return UnitConverter.toGrams(value, this.unit);
        }
      } else {
        if (this.largeUnit) {
          console.log("kg -> g");
          return UnitConverter.convertToLower(value, this.unit);
        } else {
          return value;
        }
      }
    },
    reset() {
      this.largeUnit = false;
    },
    onBlur() {
      if (this.standalone && this.inititalValue !== this.currentValue) {
        this.$emit("input", this.convertToGrams(this.currentValue));
      } else {
        this.$emit("blur");
      }
    },
    onKeydown(event) {
      console.log(event.keyCode)
      if(event.keyCode === 32) {
        event.preventDefault();
      } else {
        this.$emit('keydown', event);
      }
      //if (this.standalone && event.key === "Enter") {
      //  this.$emit("input", this.convertToGrams(this.currentValue));
      //}
    },
    onInput(value) {
      if (value !== "") {
        console.log("Inpt value", value);
        if (this.standalone) {
          this.currentValue = value;
        } else {
          this.$emit("input", { value, grams: this.convertToGrams(value) });
        }
      }
    },
  },
};
</script>

<i18n>
{
    "en": {
        "label": "Weight",
        "gram": "gram",
        "kilogram": "kilogram",
        "ounce": "ounce",
        "pound": "pound",
        "save": "Save"
    },
    "de": {
        "label": "Gewicht",
        "gram": "gramm",
        "kilogram": "kilogramm",
        "ounce": "unzen",
        "pound": "pfund",
        "save": "Speichern"
    }
}
</i18n>