<template>
  <v-card class="profile-info">
    <LoadingOverlay :loading="client.loading" />
    <v-card-title>
      {{ profile.name }}
    </v-card-title>
    <v-card-subtitle v-if="profile.mail">
      {{ profile.mail }}
     
    </v-card-subtitle>
    <v-card-subtitle v-if="!profile.own">
      {{ linkDisplay }}
        <br>
        {{$t('last_seen')}}: {{ lastActiveTime }}
    </v-card-subtitle>
    <slot name="prepend"></slot>
    <v-card-text>
      <ProfileImageDisplay
        :image="profile.image"
        :userid="profile.id"
        :editable="profile.own"
        @editImage="$emit('editImage', $event)"
        :client="client"
        ref="profileImageDisplay"
      />
      <slot name="append"></slot>
      <template v-if="profile.own">
        <v-card v-if="profile.public">
          <v-card-text>
            <div class="mb-2">{{ $t("link_to_profile") }}:</div>
            <LinkShare :link="link" :display="linkDisplay" />
            <small>{{ $t("link_explain") }}</small>
          </v-card-text>
        </v-card>
        <v-switch
          @change="togglePublic($event)"
          :input-value="profile.public"
          inset
          :label="$t('profile_public')"
        ></v-switch>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import ProfileImageDisplay from "./image/ProfileImageDisplay";
import LinkShare from "../generic/LinkShare";
import LoadingOverlay from "../generic/LoaderOverlay";

export default {
  name: "ProfileInfoDisplay",
  props: ["profile", "client"],
  components: {
    ProfileImageDisplay,
    LinkShare,
    LoadingOverlay,
  },
  created() {
    console.log("Profile", this.profile);
  },
  computed: {
    link() {
      return `${location.protocol}//${location.host}/#${this.profile.name}`;
    },
    linkDisplay() {
      return `${location.host}/#${this.profile.name}`;
    },
    lastActiveTime() {
      if (this.profile) {
        const date = new Date(this.profile.active * 24 * 60 * 60000);
        return date.toLocaleDateString();
      } else {
        return "";
      }
    },
  },
  methods: {
    update() {
      this.$refs.profileImageDisplay.update();
    },
    togglePublic(value) {
      this.client.account.editProfile("public", value).then((response) => {
        if (response.success) {
          this.client.app.showInfo(
            value ? "profile_now_public" : "profile_now_private"
          );
        }
      });
    },
  },
};
</script>

<i18n>
{
    "en": {
        "link_to_profile": "Link to share the profile",
        "link_explain": "With this link you can share the profile & the public lists with other people.",
        "profile_public": "Profile visible via link",
        "last_seen": "Last seen"
    },
    "de": {
        "link_to_profile": "Link zum Teilen des Profils",
        "link_explain": "Mit diesem Link kannst du das Profil und die öffentlichen Listen mit anderen Personen teilen.",
        "profile_public": "Profil per Link sichtbar",
        "last_seen": "Zuletzt gesehen"
    }
}
</i18n>