<template>
<v-col cols="12" lg="6">
    <v-card flat>
        <v-card-title>
            {{ $t('title') }}
        </v-card-title>
        <v-card-text>
            <v-card>
                <v-card-text>
                    <div class="d-flex">
                    <v-icon color="info" class="mr-2" style="font-size: 4em;">mdi-message</v-icon>
                    <div class="mb-4">{{ $t('info') }}</div>
                    </div>
                    <FeedbackForm class="mt-2" :client="client" />
                </v-card-text>
            </v-card>
        </v-card-text>
    </v-card>
</v-col>
</template>

<script>
import FeedbackForm from "./FeedbackForm";

export default {
    name: 'FeedbackView',
    props: ['client'],
    components: {
        FeedbackForm
    }
}
</script>

<i18n>
{
    "en": {
        "title": "Feedback",
        "info": "This app is still under heavy development. Do you have any problems or feature suggestions? Or just general feedback? Let us know and help to improve PackTrek!"
    },
    "de": {
        "title": "Feedback",
        "info": "Diese Anwendung wird durchgehend weiterentwickelt. Hast du irgendwelche Probleme oder Vorschläge? Oder einfach allgemeines Feedback? Lass es uns wissen und hilf mit PackTrek zu verbessern!"
    }
}
</i18n>