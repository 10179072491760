<template>
  <div style="min-height: 100vh">
    <v-toolbar flat dark src="../../../assets/bg/bg_2_toolbar.webp">
      <v-btn icon @click="home()" v-if="client.account.profile.name === undefined"><v-icon>mdi-home</v-icon></v-btn>

      <v-toolbar-title>{{ $t("title") }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <LanguageSelector />
      <AccountDropdown :client="client" />
    </v-toolbar>

    <DualContentRow v-if="profile !== undefined">
      <template slot="small">
        <ProfileInfoDisplay :profile="profile" :client="client">
        </ProfileInfoDisplay>
      </template>
      <template slot="large">
        <v-card>
          <v-card-title>{{ $t("lists_title") }}</v-card-title>
          <v-card-text>
            <PublicListsSelector
              :lists="profile.lists"
              @select="$emit('selectList', $event)"
            />
          </v-card-text>
        </v-card>
      </template>
    </DualContentRow>
    <div class="footer">
      <div class="d-flex justify-center mb-1">
        <v-btn color="success" v-if="client.account.profile.name === undefined" @click="home()">
          <v-icon left>mdi-arrow-right</v-icon> {{ $t('try') }}
        </v-btn>
      </div>  
      <ImprintFooter :large="true"/>
    </div>
  </div>
</template>

<script>
import LanguageSelector from "../../generic/LanguageSelector";
import DualContentRow from "../../generic/layout/DualContentRow";
import ProfileInfoDisplay from "../../profile/ProfileInfoDisplay";
import PublicListsSelector from "./PublicListsSelector";
import AccountDropdown from "../../account/AccountDropdown";
import ImprintFooter from "../../home/ImprintFooter";

export default {
  name: "PublicProfile",
  props: ["client", "profile"],
  components: {
    LanguageSelector,
    DualContentRow,
    ProfileInfoDisplay,
    PublicListsSelector,
    AccountDropdown,
    ImprintFooter
  },
  methods: {
    home() {
      window.location.hash = "";
      this.client.app.setPage("home");
    },
  },
};
</script>

<style scoped>
.footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>

<i18n>
{
    "en": {
        "title": "User profile",
        "lists_title": "Shared lists",
        "try": "Try PackTrek"
    },
    "de": {
        "title": "Nutzerprofil",
        "lists_title": "Geteilte Listen",
        "try": "PackTrek ausprobieren"
    }
}
</i18n>