<template>
  <v-list>
    <v-list-item-group color="primary">
      <v-list-item
        v-for="(list, i) in lists"
        :key="i"
        @click="$emit('select', list)"
      >
        <v-list-item-icon>
          <v-icon>mdi-bag-personal</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ list.title }}</v-list-item-title>
          <v-list-item-subtitle
            >{{ list.itemCount }} {{ $t("items") }}</v-list-item-subtitle
          >
        </v-list-item-content>
          <v-list-item-action>
            <v-list-item-action-text>{{
              formatDate(list.date)
            }}</v-list-item-action-text>
          </v-list-item-action>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: "PublicListsSelector",
  props: ["lists"],
  methods: {
    formatDate(date) {
      return new Date(date * 24 * 60 * 60 * 1000).toLocaleDateString();
    }
  }
};
</script>

<i18n>
{
    "en": {
        "items": "Items"
    },
    "de": {
        "items": "Gegenstände"
    }
}
</i18n>