<template>
    <v-row class="mx-2 my-2">
        <v-col cols="12" md="4">
            <slot name="small"></slot>
        </v-col>
        <v-col cols="12" md="8">
            <slot name="large"></slot>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'DualContentRow',
}
</script>
