<template>
  <v-btn v-if="installable" :color="installing ? 'success' : 'info'" @click="prompt()" small>
    <v-icon v-text="installing ? 'mdi-check' : 'mdi-plus'"></v-icon> {{ installing ? $t('installing') : $t("text") }}
  </v-btn>
</template>

<script>
export default {
  name: "PWAInstallButton",
  data: () => ({
    event: undefined,
    installing: false,
  }),
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.event = e;
    });
  },
  computed: {
    installable() {
      return this.event !== undefined;
    },
  },
  methods: {
    async prompt() {
      if (!this.installing) {
        this.event.prompt();
        const { outcome } = await this.event.userChoice;
        if (outcome) {
            this.installing = true;
          setTimeout(() => {
              this.event = undefined;
          }, 2000);
        }
      }
    },
  },
};
</script>

<i18n>
{
    "en": {
        "text": "Install app",
        "installing": "App installed"
    },
    "de": {
        "text": "App installieren",
        "installing": "App installiert"
    }
}
</i18n>