<template>
    <div class="d-flex">
        <v-chip @click="copy()">{{display}}
            <v-icon right v-if="copied" color="success">mdi-check</v-icon>
            <v-icon right v-else color="success">mdi-content-copy</v-icon>
        </v-chip>
    </div>
</template>

<script>
export default {
    name: 'LinkShare',
    props: ['link', 'display'],
    data: () => ({
        copied: false
    }),
    methods: {
        copy() {
            navigator.clipboard.writeText(this.link);
            this.copied = true;
            setTimeout(() => {this.copied = false;}, 2000);
        },
        open() {
            window.open(this.link, '_blank');
        }
    }
}
</script>