import { render, staticRenderFns } from "./PublicProfile.vue?vue&type=template&id=03975057&scoped=true&"
import script from "./PublicProfile.vue?vue&type=script&lang=js&"
export * from "./PublicProfile.vue?vue&type=script&lang=js&"
import style0 from "./PublicProfile.vue?vue&type=style&index=0&id=03975057&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03975057",
  null
  
)

/* custom blocks */
import block0 from "./PublicProfile.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VIcon,VSpacer,VToolbar,VToolbarTitle})
