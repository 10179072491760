<template>
    <SimpleDialog :visible="visible" :title="$t('title')" @hide="visible = false" :langToggle="true">
        <br>
        <template v-if="lang === 'en'">
            <h1>Imprint</h1>
            <hr>
            <br>
            <b>Responsible for development and content:</b><br>
            David Traum<br>
            Sonnenstr. 46<br>
            Wuppertal<br>
            <br>
            <b>Contact:</b> david@traum.me
        </template>
        <template v-if="lang === 'de'">
            <h1>Impressum</h1>
            <hr>
            <br>
            <b>Verantwortlich für Entwicklung und Inhalt:</b><br>
            David Traum<br>
            Sonnenstr. 46<br>
            Wuppertal<br>
            <br>
            <b>Kontakt:</b> david@traum.me
        </template>
    </SimpleDialog>
</template>

<script>
import SimpleDialog from "../../generic/SimpleDialog";

export default {
    name: 'ImprintDialog',
    components: {
        SimpleDialog
    },
    data: () => ({
        visible: false
    }),
    computed: {
        lang() {
            return this.$i18n.locale;
        }
    },
    methods: {
        show() {
            this.visible = true;
        },
        hide() {
            this.visible = false;
        }
    }
}
</script>

<i18n>
{
    "en": {
        "title": "Imprint"
    },
    "de": {
        "title": "Impressum"
    }
}
</i18n>