 <template>
  <div>
    <v-card>
      <v-card-text>
        <div class="d-flex">
          <v-spacer />
          <v-icon class="large-icon" color="info">mdi-apple-safari</v-icon>
          <v-spacer />
        </div>
        <div class="text-center" style="font-size: 1.5em;">
            {{ $t('info') }}
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "DiscoverPlaceholder",
};
</script>

<style scoped>
.large-icon {
    font-size: 10em;
    animation-name: pulse;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1) rotate(180deg);
    }
    100% {
        transform: scale(1) rotate(360deg);

    }
}
</style>

<i18n>
{
    "en": {
        "info": "The discover area is not ready yet. If enough people shared public lists, you can search and discover them here."
    },
    "de": {
        "info": "Die Entdecken-Sektion ist noch nicht freigeschaltet. Wenn genug Mitglieder öffentliche Listen geteilt haben, kannst du diese hier suchen und entdecken."
    }  
}
</i18n>