<template>
  <div class="profile-image">
    <template v-if="image.type === 'image'">
      <div style="position: relative; width: fit-content">
        <LoadingOverlay :loading="imageLoading" />
        <img
          :src="imageSrc"
          @load="imageLoading = false"
          :class="{ 'img-icon': icon }"
        />
      </div>
    </template>
    <template v-else>
      <div style="position: relative; width: fit-content">
        <img
          src="../../../assets/user/no-user-image.gif"
          :class="{ 'img-icon': icon }"
        />
      </div>
    </template>
    <!--
    -->

    <EditImageButton
      v-if="editable"
      @select="$emit('editImage', $event)"
      :client="client"
    />
  </div>
</template>

<script>
import LoadingOverlay from "../../generic/LoaderOverlay";
import EditImageButton from "./EditImageButton";

export default {
  name: "ProfileImageDisplay",
  components: {
    LoadingOverlay,
    EditImageButton,
  },
  props: ["image", "userid", "editable", "icon", "client"],
  data: () => ({
    imageSrc: "",
    imageLoading: false,
  }),
  created() {
    this.update();
  },
  methods: {
    update() {
      if (this.image.type === "image") {
        this.imageLoading = true;
        this.imageSrc = `images/profile/${this.userid}.jpg?t=${Date.now()}`;
      }
    },
  },
};
</script>

<style scoped>
.profile-image {
  position: relative;
}
img {
  max-height: 256px;
  max-width: 256px;
  border-radius: 50%;
}
.img-icon {
  height: 32px !important;
  width: 32px !important;
}
</style>

<i18n>
{
    "en": {
        "edit_image": "Edit image"
    },
    "de": {
        "edit_image": "Bild bearbeiten"
    }

}
</i18n>