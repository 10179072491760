<template>
  <div>
    <div>
      {{ $t("total_weight") }}: <b>{{ totalWeight }}</b>
    </div>
    <div>
      {{ $t("weight_on_body") }}: <b>{{ weightOnBody }}</b>
    </div>
    <div>
      {{ $t("weight_packed") }}: <b>{{ packedWeight }}</b>
    </div>
  </div>
</template>

<script>
import Unit from "../../../lib/unit";

export default {
  name: "TotalWeightLabel",
  props: ["list", "unit"],
  computed: {
    totalWeight() {
      let total = 0;
      for (const item of this.list.items) {
        total += item.weight * item.count;
      }
      return Unit.formatString(total, this.unit);
    },
    packedWeight() {
      let total = 0;
      for (const item of this.list.items) {
        if (!item.wear) {
          total += item.weight * item.count;
        }
      }
      return Unit.formatString(total, this.unit);
    },
    weightOnBody() {
      let total = 0;
      for (const item of this.list.items) {
        if (item.wear) {
          total += item.weight * item.count;
        }
      }
      return Unit.formatString(total, this.unit);
    },
  },
};
</script>

<style scoped>
div {
    text-align: center;
}
</style>

<i18n>
{
    "en": {
        "total_weight": "Total weight",
        "weight_on_body": "Weight on body",
        "weight_packed": "Weight packed"
    },
    "de": {
        "total_weight": "Gesamtgewicht",
        "weight_on_body": "Gewicht am Körper",
        "weight_packed": "Gepacktes Gewicht"
    }
}
</i18n>