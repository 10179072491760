var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-simple-table',[_c('CreateGearDialog',{ref:"editDialog",attrs:{"client":_vm.client,"list":_vm.list}}),_c('thead',[_c('tr',[_c('th'),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("manufacturer")))]),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("name")))]),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("category")))]),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("weight")))]),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("count")))]),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("worn")))]),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t("pack")))])])]),_c('tbody',_vm._l((_vm.items),function(item,index){return _c('tr',{key:item.id},[_c('td',[(item.img && item.img.length > 0)?_c('img',{staticClass:"icon",attrs:{"src":item.img},on:{"click":function($event){return _vm.$emit('showImage', item)}}}):_vm._e()]),_c('v-menu',{attrs:{"offset-y":""},on:{"input":function($event){return _vm.onMenuToggle($event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('td',_vm._g(_vm._b({staticClass:"edit-cell",class:{
              'active-cell': _vm.edit.item === item && _vm.edit.field === 'brand',
            },on:{"click":function($event){return _vm.setEdit(item, 'brand', index)}}},'td',attrs,false),on),[_vm._v(" "+_vm._s(item.brand)+" ")])]}}],null,true)},[(!_vm.smallView && _vm.edit.item === item && _vm.edit.field === 'brand')?_c('v-card',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t("manufacturer"))+": "),_c('ManufacturerPicker',{ref:"manufacturerPicker",refInFor:true,staticStyle:{"margin-top":"-10px","margin-bottom":"-10px"},attrs:{"value":item.brand,"hidelabel":true,"initfocus":true},on:{"blur":function($event){return _vm.cancelEdit()},"input":function($event){return _vm.editValue('brand', $event)}}})],1)],1):_vm._e()],1),_c('v-menu',{attrs:{"offset-y":""},on:{"input":function($event){return _vm.onMenuToggle($event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('td',_vm._g(_vm._b({staticClass:"edit-cell",class:{
              'active-cell': _vm.edit.item === item && _vm.edit.field === 'name',
            },on:{"click":function($event){return _vm.setEdit(item, 'name', index)}}},'td',attrs,false),on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},[(!_vm.smallView && _vm.edit.item === item && _vm.edit.field === 'name')?_c('v-card',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t("name"))+": "),_c('RestrictedInlineTextfield',{staticStyle:{"margin-top":"-10px"},attrs:{"value":_vm.edit.item.name,"minlength":2,"maxlength":50},on:{"input":function($event){return _vm.editValue('name', $event)},"blur":function($event){return _vm.cancelEdit()}}})],1)],1):_vm._e()],1),_c('v-menu',{attrs:{"offset-y":""},on:{"input":function($event){return _vm.onMenuToggle($event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('td',_vm._g(_vm._b({staticClass:"edit-cell",class:{
              'active-cell': _vm.edit.item === item && _vm.edit.field === 'category',
            },on:{"click":function($event){return _vm.setEdit(item, 'category', index)}}},'td',attrs,false),on),[_c('div',{staticClass:"d-flex",staticStyle:{"flex-wrap":"nowrap"}},[_c('i',{staticClass:"mdi fast-icon",class:item.category[0],style:({ color: _vm.getIconColor(item.category) })}),_c('div',{staticClass:"my-auto"},[_vm._v(" "+_vm._s(item.category[1])+" ")])])])]}}],null,true)},[(!_vm.smallView && _vm.edit.item === item && _vm.edit.field === 'category')?_c('v-card',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t("category"))+": "),_c('CategoryPicker',{attrs:{"list":_vm.list,"client":_vm.client,"value":item.category,"initfocus":true,"standalone":true},on:{"input":function($event){return _vm.editValue('category', $event)}}})],1)],1):_vm._e()],1),_c('v-menu',{attrs:{"offset-y":""},on:{"input":function($event){return _vm.onMenuToggle($event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('td',_vm._g(_vm._b({staticClass:"edit-cell",class:{
              'active-cell': _vm.edit.item === item && _vm.edit.field === 'weight',
            },on:{"click":function($event){return _vm.setEdit(item, 'weight', index)}}},'td',attrs,false),on),[_vm._v(" "+_vm._s(_vm.formatWeight(item.weight))+" "),(item.count > 1)?[_vm._v("("+_vm._s(_vm.formatWeight(item.weight * item.count))+")")]:_vm._e()],2)]}}],null,true)},[(!_vm.smallView && _vm.edit.item === item && _vm.edit.field === 'weight')?_c('v-card',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t("weight"))+": "),_c('WeightInput',{attrs:{"value":item.weight,"standalone":true,"unit":_vm.unit},on:{"input":function($event){return _vm.editValue('weight', $event)},"blur":function($event){return _vm.cancelEdit()}}})],1)],1):_vm._e()],1),_c('v-menu',{attrs:{"offset-y":""},on:{"input":function($event){return _vm.onMenuToggle($event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('td',_vm._g(_vm._b({staticClass:"edit-cell",class:{
              'active-cell': _vm.edit.item === item && _vm.edit.field === 'count',
            },on:{"click":function($event){return _vm.setEdit(item, 'count', index)}}},'td',attrs,false),on),[_vm._v(" "+_vm._s(item.count)+" ")])]}}],null,true)},[(!_vm.smallView && _vm.edit.item === item && _vm.edit.field === 'count')?_c('v-card',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t("count"))+": "),_c('CountInput',{attrs:{"value":_vm.edit.item.count},on:{"input":function($event){return _vm.editValue('count', $event)}}})],1)],1):_vm._e()],1),_c('td',[_c('div',{staticClass:"checkbox",on:{"click":function($event){_vm.$emit('toggleWear', _vm.setItemIndex(item, index))}}},[(item.wear)?_c('i',{staticClass:"mdi mdi-checkbox-marked info--text fast-icon"}):_c('i',{staticClass:"mdi mdi-checkbox-blank-outline fast-icon"})])]),_c('td',[_c('div',{staticClass:"checkbox",attrs:{"icon":""},on:{"click":function($event){_vm.$emit('togglePack', _vm.setItemIndex(item, index))}}},[(item.pack)?_c('i',{staticClass:"mdi mdi-checkbox-marked success--text fast-icon"}):_c('i',{staticClass:"mdi mdi-checkbox-blank-outline fast-icon"})])]),_c('td',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('delete', item)}}},[_c('i',{staticClass:"mdi mdi-trash-can fast-icon"})])],1)],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }