<template>
  <div>
    <SimpleDialog
      :visible="createDialogVisible"
      :title="$t('create')"
      @hide="createDialogVisible = false"
    >
      <v-text-field
        :label="$t('new_list_title')"
        :hint="$t('new_list_hint')"
        class="mt-2"
        persistent-hint
        v-model="createInput.title"
        @keydown="createTitleKeydown($event)"
        maxlength="50"
      >
      </v-text-field>
      <br />
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t("keywords") }} ({{createInput.keywords.length}})
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <KeywordSelector v-model="createInput.keywords" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <template v-slot:actions>
        <v-btn
          text
          color="success"
          @click="submitCreate()"
          :disabled="!listTitleValid"
        >
          {{ $t("submit_create") }}
        </v-btn>
      </template>
    </SimpleDialog>
    <div>
      <v-toolbar>
        <v-toolbar-title v-if="selectedLists.length > 0">
          {{ $t("selected") }}: {{ selectedLists.length }}
        </v-toolbar-title>
        <v-text-field
          v-model="filter"
          v-if="selectedLists.length <= 0"
          clearable
          @click:clear="filter = ''"
          flat
          hide-details
          prepend-inner-icon="mdi-magnify"
          :label="$t('search')"
        ></v-text-field>
        <v-spacer />
        <v-scale-transition>
          <DeleteButton
            v-if="selectedLists.length"
            :question="$t('delete')"
            @confirm="deleteSelection()"
          />
        </v-scale-transition>
        <v-scale-transition>
          <v-tooltip bottom v-if="selectedLists.length > 0">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="toggleSelectionPublic()"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{
                  selectionPublic ? "mdi-share-variant" : "mdi-share-variant-outline"
                }}</v-icon>
              </v-btn>
            </template>
            <span>{{
              selectionPublic ? $t("make_private") : $t("make_public")
            }}</span>
          </v-tooltip>
        </v-scale-transition>
        <v-btn
          :color="selectedLists.length <= 0 ? 'success' : ''"
          :class="{ 'ml-2': selectedLists.length <= 0 }"
          v-if="createable"
          :icon="selectedLists.length > 0"
          @click="
            selectedLists.length > 0
              ? clearSelection()
              : (createDialogVisible = true)
          "
        >
          <v-icon v-if="selectedLists.length <= 0">mdi-plus</v-icon>
          <v-icon v-else>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <CreateFirstButton
        v-if="lists.length <= 0"
        :text="$t('create_first')"
        class="center-button"
        @click="createDialogVisible = true"
      />
      <v-virtual-scroll
        :items="filteredLists"
        :height="scrollerHeight"
        item-height="88"
        id="list-selector"
      >
        <template v-slot:default="{ item, index }">
          <v-list-item :key="item._id" @click="select(item)" three-line :id="'list-' + index">
            <template>
              <v-list-item-action v-if="createable">
                <v-checkbox
                  @click.stop=""
                  @change="setSelected(item, $event)"
                  :value="isSelected(item)"
                ></v-checkbox>
              </v-list-item-action>
              <v-list-item-content v-if="item">
                <v-list-item-title v-text="item.title"></v-list-item-title>

                <v-list-item-subtitle class="text--primary">
                  {{ item.itemCount }} {{ $t("items") }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="item.keywords.length > 0">
                  {{ formatKeywords(item.keywords) }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-action-text>{{
                  formatDate(item.date)
                }}</v-list-item-action-text>

 <v-tooltip left v-if="item.public">
      <template v-slot:activator="{ on, attrs }">
                <v-icon color="yellow darken-3" v-on="on" v-bind="attrs">
                  mdi-share-variant
                </v-icon>
      </template>
      <span>{{ $t('public_list') }}</span>
    </v-tooltip>
              </v-list-item-action>
            </template>
          </v-list-item>

          <v-divider></v-divider>
        </template>
      </v-virtual-scroll>
    </div>
  </div>
</template>

<script>
import SimpleDialog from "../../generic/SimpleDialog";
import Formatter from "../../../lib/formatter";
import CreateFirstButton from "../../generic/CreateFirstButton";
import DeleteButton from "../../generic/DeleteButton";
import KeywordSelector from "../input/KeywordSelector";

export default {
  name: "ListSelector",
  props: ["lists", "createable", "client"],
  components: {
    SimpleDialog,
    CreateFirstButton,
    DeleteButton,
    KeywordSelector,
  },
  data: () => ({
    createDialogVisible: false,
    filter: "",
    createInput: {
      title: "",
      keywords: [],
    },
    scrollerHeight: window.innerHeight - 200,
    selectedLists: [],
  }),
  computed: {
    filteredLists() {
      if (this.filter.length > 0) {
        const filtered = [];
        const query = this.filter.toLowerCase();
        for (const list of this.lists) {
          if (query === "::debug-render") {
            for (let i = 0; i < 100000; i++) filtered.push(list);
          } else if (query === "::debug-unit") {
            this.client.app.showUnitSelect();
          } else if (list.title.toLowerCase().includes(query)) {
            filtered.push(list);
          }
        }
        return filtered;
      }
      return this.lists;
    },
    listTitleValid() {
      return (
        this.createInput.title.trim().length >= 2 &&
        this.createInput.title.trim().length <= 50
      );
    },
    selectionPublic() {
      let pub = 0;
      for (const list of this.selectedLists) {
        pub += list.public ? 1 : -1;
      }
      return pub > 0;
    },
  },
  methods: {
    submitCreate() {
      this.$emit("createList", this.createInput);
      this.createInput.title = "";
      this.createInput.keywords = [];
      this.createDialogVisible = false;
    },
    select(list) {
      this.clearSelection();
      this.$emit("selectList", list._id);
    },
    formatDate(date) {
      return Formatter.formatDay(date);
    },
    formatKeywords(keywords) {
      return '#' + keywords.join(', #');
    },
    createTitleKeydown(event) {
      if (event.key === "Enter" && this.listTitleValid) {
        this.submitCreate();
      }
    },
    setSelected(list, selected) {
      if (selected) {
        this.selectedLists.push(list);
      } else {
        this.selectedLists.splice(this.selectedLists.indexOf(list), 1);
      }
    },
    isSelected(list) {
      return this.selectedLists.includes(list);
    },
    clearSelection() {
      this.selectedLists = [];
    },
    deleteSelection() {
      this.client.lists
        .deleteMulti(this.selectedLists.map((list) => list._id))
        .then((response) => {
          if (response.success) {
            this.client.lists.loadInformationList().then((response) => {
              if (response.success) {
                this.clearSelection();
              }
            });
          }
        });
    },
    async toggleSelectionPublic() {
      const setPublic = !this.selectionPublic;
      this.client.lists
        .changeSettingMulti(
          this.selectedLists.map((list) => list._id),
          "public",
          setPublic
        )
        .then((response) => {
          if (response.success) {
            this.clearSelection();
            this.$emit("listsUpdated", response.lists);
          }
        });
    },
  },
};
</script>

<style scoped>
.list-selector {
  overflow: scroll;
}
.center-button {
  position: absolute;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
}
</style>

<i18n>
{
    "en": {
        "search": "Search",
        "create": "Create list",
        "submit_create": "Create",
        "new_list_title": "List title",
        "items": "Items",
        "new_list_hint": "e.g. \"trekking backpack\"",
        "selected": "Selected",
        "delete": "Delete selection",
        "make_public": "Make public",
        "make_private": "Make private",
        "confirm_delete": "Do you really want to delete the selected lists?",
        "create_first": "Create first list",
        "keywords": "Keywords",
        "public_list": "Visible"
    },
    "de": {
        "search": "Suchen",
        "create": "Neue Liste",
        "submit_create": "Erstellen",
        "new_list_title": "Titel der Liste",
        "items": "Gegenstände",
        "new_list_hint": "z.B. \"Trekkingrucksack\"",
        "selected": "Ausgewählt",
        "delete": "Auswahl löschen",
        "make_public": "Öffentlich anzeigen",
        "make_private": "Als privat markieren",
        "confirm_delete": "Möchten sie die ausgewählten Listen wirklich löschen?",
        "create_first": "Erste Liste erstellen",
        "keywords": "Schlüsselwörter",
        "public_list": "Sichtbar"
    }
}
</i18n>