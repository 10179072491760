<template>
  <div>
      {{$t('title')}}
    <div class="d-flex my-4" style="flex-wrap: wrap" v-if="value.length > 0">
      <v-chip v-for="(keyword, index) of value" :key="keyword" small class="mb-1 mr-1">
        {{ keyword }} <v-icon @click="remove(index)">mdi-close</v-icon>
      </v-chip>
    </div>
    <v-text-field
      :label="$t('new')"
      v-model="newKeyword"
      maxlength="25"
      @input="onInput()"
      @keydown="onKeydown($event)"
      :disabled="value.length >= 10"
    >
      <v-icon slot="prepend">#</v-icon>
      <v-icon color="success" :disabled="!valid" @click="add()" slot="append" v-if="value.length < 10">
        mdi-plus
      </v-icon>
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: "KeywordSelector",
  props: ["value"],
  data: () => ({
    newKeyword: "",
  }),
  computed: {
    valid() {
      return this.value.length < 10 && this.newKeyword.length >= 2 && this.newKeyword.length <= 25 && !this.value.includes(this.newKeyword);
    },
  },
  methods: {
    onInput() {
      this.newKeyword = this.newKeyword.toLowerCase();
      while(this.newKeyword.includes(' ')) {
        this.newKeyword = this.newKeyword.replace(' ', '');
      }
    },
    onKeydown(event) {
      if (event.key === "Enter" && this.valid) {
        this.add();
      }
    },
    remove(index) {
        const copy = [...this.value];
        copy.splice(index, 1);
        this.$emit('input', copy);
    },
    add() {
      if (this.valid) {
        const copy = [...this.value];
        copy.push(this.newKeyword);
        this.$emit("input", copy);
        this.newKeyword = '';
      }
    },
  },
};
</script>

<i18n>
{
    "en": {
        "title": "For example: travel destination, season, trip type...",
        "new": "Add keyword..."
    },
    "de":  {
        "title": "Zum Beispiel: Reiseziele, Jahreszeit, Art der Reise...",
        "new": "Schlüsselwort hinzufügen..."
    }
}
</i18n>