<template>
  <SimpleDialog
    :visible="item !== undefined"
    :title="item ? item.name + ' ' + item.brand : ''"
    @hide="hide()"
  >
    <template v-if="item !== undefined">
      <img class="img" :src="item.img" />
      <small>Src:</small> <a :href="item.img" target="_blank"
        ><small>{{ item.img }}</small></a
      >
    </template>
  </SimpleDialog>
</template>

<script>
import SimpleDialog from "../../generic/SimpleDialog";
export default {
  name: "GearImageDialog",
  components: {
    SimpleDialog,
  },
  data: () => ({
    item: undefined,
  }),
  methods: {
    show(item) {
      this.item = item;
    },
    hide() {
      this.item = undefined;
    },
  },
};
</script>

<style scoped>
.img {
  width: 100%;
}
</style>