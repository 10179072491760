<template>
  <v-card flat>
    <LoadingOverlay :loading="client.loading" />
    <template v-if="true">
      <v-card-title>
        {{ $t("title") }}
      </v-card-title>
      <v-card-text>
        <DiscoverSearchBar
          ref="searchBar"
          v-if="tab < 2"
          :disabled="keywordOnly"
          @input="onSearch($event)"
          @clear="onSearchCleared()"
        />
        <v-tabs-items class="px-1 py-1" :value="tab">
          <v-tab-item>
            <DiscoverTrendList :data="data" @input="onSelectKeyword($event)" />
          </v-tab-item>
          <v-tab-item>
            <DiscoverSearchResults
              :results="results"
              :query="query"
              @select="onSelectList($event)"
              @back="onSearchCleared()"
            />
          </v-tab-item>
          <v-tab-item>
            <template v-if="selectedList !== undefined">
              <v-toolbar>
                <v-btn icon @click="closeList()">
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-toolbar-title>
                  {{ selectedList.title }}
                </v-toolbar-title>
              </v-toolbar>
              <PublicListView
                :list="selectedList"
                :client="client"
                :embed="true"
              />
            </template>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </template>
    <template v-else>
      <DiscoverPlaceholder />
    </template>
  </v-card>
</template>

<script>
import LoadingOverlay from "../generic/LoaderOverlay";
import DiscoverSearchBar from "./DiscoverSearchBar";
import DiscoverTrendList from "./DiscoverTrendList";
import DiscoverSearchResults from "./DiscoverSearchResults";
import DiscoverPlaceholder from "./DiscoverPlaceholder";
import PublicListView from "../profile/public/list/PublicListView.vue";

export default {
  name: "DiscoverView",
  props: ["client"],
  components: {
    LoadingOverlay,
    DiscoverSearchBar,
    DiscoverTrendList,
    DiscoverSearchResults,
    DiscoverPlaceholder,
    PublicListView,
  },
  data: () => ({
    data: { top: [] },
    results: [],
    query: "",
    keywordOnly: false,
    selectedList: undefined,
  }),
  created() {
    this.client.discover.getDisplayData().then((response) => {
      if (response.success) {
        this.data = response.data;
      }
    });
  },
  computed: {
    tab() {
      if (this.query.length > 0) {
        if (this.selectedList !== undefined) {
          return 2;
        } else {
          return 1;
        }
      }
      return 0;
    },
  },
  methods: {
    onSearch(query) {
      this.query = query;
      this.keywordOnly = false;
      this.search();
    },
    closeList() {
      this.selectedList = undefined;
    },
    onSelectList(list) {
      this.selectedList = list;
    },
    onSearchCleared() {
      this.query = "";
      if (this.keywordOnly) {
        this.$refs.searchBar.setValue("");
      }
      this.keywordOnly = false;
    },
    onSelectKeyword(keyword) {
      this.$refs.searchBar.setValue(keyword.key);
      this.query = keyword.key;
      this.keywordOnly = true;
      this.search();
    },
    search() {
      this.client.discover
        .search(this.query, this.keywordOnly)
        .then((response) => {
          if (response.success) {
            console.log(response.results);
            this.results = response.results;
          }
        });
    },
  },
};
</script>

<i18n>
{
    "en": {
        "title": "Discover"
    },
    "de": {
        "title": "Entdecken"
    }
}
</i18n>