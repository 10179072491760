<template>
  <v-dialog max-width="600" :value="visible" :persistent="permanent" @click:outside="$emit('hide')">
    <template>
      <v-card>
        <v-toolbar
          >
          <v-icon v-if="icon" v-text="icon" class="mr-2"></v-icon>
          <span>{{ title}}</span>
          <v-spacer />
          <LanguageSelector v-if="langToggle" />
          <v-btn icon @click="$emit('hide')" v-if="!permanent">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <slot></slot>
        </v-card-text>
        <v-card-actions class="justify-end">
          <slot name="actions"></slot>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import LanguageSelector from "./LanguageSelector";

export default {
  name: "SimpleDialog",
  props: ['visible', 'title', 'permanent', 'icon', 'langToggle'],
  components: {
    LanguageSelector
  }
};
</script>