<template>
  <div>
    <v-list
      two-line
      dense
      subheader
      v-for="(category, categoryIndex) of categories"
      :key="category[1]"
    >
      <v-subheader
        >{{ category[1] }} ({{ getCategoryWeight(category) }})</v-subheader
      >
      <v-list-item v-for="(item, index) of getItems(category)" :key="index">
        <v-list-item-icon class="mr-2 list-icon">
          <img
            v-if="item.img && item.img.length > 0"
            :src="item.img"
            class="item-img"
            @click="$emit('showImage', item)"
          />
          <i
            class="mdi fast-icon"
            :class="category[0]"
            :style="{ color: getIconColor(categoryIndex) }"
            v-else
          ></i>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.brand }} {{ item.name }} <template v-if="item.note && item.note.length > 0">({{item.note}})</template>
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ getWeightLabel(item) }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <div class="d-flex" v-if="editable">
            <div
              class="clickable my-auto"
              @click="$emit('toggleWear', item.original)"
            >
              <i
                class="fast-icon mdi mdi-tshirt-crew info--text"
                v-if="item.wear"
              ></i>
              <i class="fast-icon mdi mdi-tshirt-crew-outline" v-else></i>
            </div>
            <div
              class="clickable my-auto"
              @click="$emit('togglePack', item.original)"
            >
              <i
                class="fast-icon mdi mdi-bag-personal success--text"
                v-if="item.pack"
              ></i>
              <i class="fast-icon mdi mdi-bag-personal-outline" v-else></i>
            </div>
            <div
              class="clickable my-auto"
              @click="$emit('edit', { item: item.original, index: item.index })"
            >
              <i class="fast-icon mdi mdi-pencil"></i>
            </div>
            <div class="clickable my-auto" @click="$emit('delete', item)">
              <i class="fast-icon mdi mdi-trash-can error--text"></i>
            </div>
          </div>
          <!--
          <div v-else>
            <v-btn
              small
              @click="$emit('addToGear', item)"
              text
              color="success"
              v-if="addable"
            >
              <v-icon>mdi-plus</v-icon> {{ $t("add_to_gear") }}
            </v-btn>
          </div>
          -->
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import Globals from "../../../lib/globals";
import UnitConverter from "../../../lib/unit";

export default {
  name: "GearDisplayList",
  props: ["list", "editable", "unit", "addable"],
  computed: {
    items() {
      const items = [];
      let index = 0;
      for (let item of this.list.items) {
        const newItem = { ...item };
        newItem.original = item;
        newItem.index = index;
        items.push(newItem);
      }
      return items;
    },
    categories() {
      const categories = [];
      for (const item of this.items) {
        let includes = false;
        for (const category of categories) {
          if (
            category[0] === item.category[0] &&
            category[1] === item.category[1]
          ) {
            includes = true;
            break;
          }
        }
        if (!includes) {
          categories.push(item.category);
        }
      }
      return categories;
    },
  },
  methods: {
    getItems(category) {
      return this.items.filter(
        (item) =>
          item.category[0] === category[0] && item.category[1] === category[1]
      );
    },
    getIconColor(index) {
      return Globals.getCategoryColor(index);
    },
    getWeightLabel(item) {
      if (item.count > 1) {
        return `${UnitConverter.formatString(
          item.weight * item.count,
          this.unit
        )} (${item.count} x ${UnitConverter.formatString(
          item.weight,
          this.unit
        )})`;
      } else {
        return UnitConverter.formatString(item.weight * item.count, this.unit);
      }
    },
    getCategoryWeight(category) {
      let weight = 0;
      for (const item of this.getItems(category)) {
        weight += item.weight * item.count;
      }
      return UnitConverter.formatString(weight, this.unit);
    },
  },
};
</script>

<style scoped>
.list-icon {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.fast-icon {
  font-size: 1.5rem;
}
.clickable {
  cursor: pointer;
}
.item-img {
  max-width: 1.5rem;
  max-height: 1.5rem;
  cursor: zoom-in;
}
</style>

<i18n>
{
  "en": {
    "add_to_gear": "My gear"
  },
  "de": {
    "add_to_gear": "Meine Ausrüstung"
  }
}
</i18n>