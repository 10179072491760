<template>
    <v-snackbar
      :value="isVisible"
      absolute
      right
      shaped
      :top="!bottom"
      :timeout="5000"
      @input="onStateChange($event)"
    >
    <v-icon v-if="type==='error'" :color="colorTheme" class="my-auto mr-2">mdi-alert-circle-outline</v-icon>
    <v-icon v-else :color="colorTheme" class="my-auto mr-2">mdi-information-outline</v-icon>
      {{ $t(message) }}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="colorTheme"
          text
          v-bind="attrs"
          @click="hide()"
        >
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
</template>

<script>
export default {
  name: "InfoDialog",
  props: ['bottom'],
  data: () => ({
    message: undefined,
    type: 'error'
  }),
  computed: {
    isVisible() {
      return this.message !== undefined;
    },
    colorTheme() {
      switch(this.type) {
        case 'error':
          return 'warning';
        default:
          return 'primary';
      }
    }
  },
  methods: {
    show(type, message) {
      this.message = message;
      this.type = type;
    },
    hide() {
      this.message = undefined;
    },
    onStateChange(state) {
        if(!state) {
            this.hide();
        }
    }
  },
};
</script>

<i18n>
{
    "en": {
        "title": "An error occured",
        "close": "Close",
        "invalid_data": "Invalid data sent.",
        "invalid_path": "Invalid request.",
        "invalid_user": "User not found.",
        "invalid_action": "Invalid action.",
        "register_mail_exists": "There is already an account with this e-mail address registred.",
        "already_confirmed_registration": "This account has already been confirmed.",
        "invalid_pass_reset_link": "The password reset link is invalid.",
        "pass_reset_interval": "You can only request a new password once every 10 minutes.",
        "invalid_login": "Invalid login.",
        "item_not_found": "Item not found",
        "account_confirmed": "Registration confirmed. You can log into your account now.",
        "login_successful": "Logged in successfully.",
        "logged_out": "Logged out",
        "list_deleted": "List deleted",
        "register_name_exists": "This username is already in use.",
        "profile_now_public": "Your profile is now visible to the public.",
        "profile_now_private": "Your profile is no longer visible to the public.",
        "request-denied-spam": "Too many requests. (Spam detected)",
        "invalid_session": "Invalid login"
    },
    "de": {
        "title": "Es ist ein Fehler aufgetreten",
        "close": "Schließen",
        "invalid_data": "Ungültige Daten gesendet.",
        "invalid_path": "Ungültige Anfrage.",
        "invalid_user": "Nutzer nicht gefunden.",
        "invalid_action": "Ungültige Aktion.",
        "register_mail_exists": "Es existiert bereits ein Account mit dieser E-Mail Adresse.",
        "already_confirmed_registration": "Dieser Account wurde bereits aktiviert, der Link ist nicht mehr gültig.",
        "invalid_pass_reset_link": "Der Link zum Ändern des Passworts ist ungültig.",
        "pass_reset_interval": "Du kannst nur alle 10 Minuten ein neues Passwort anfordern.",
        "invalid_login": "Ungültige Zugangsdaten",
        "item_not_found": "Eintrag nicht gefunden.",
        "account_confirmed": "Anmeldung bestätigt. Du kannst dich jetzt anmelden.",
        "login_successful": "Erfolgreich angemeldet.",
        "logged_out": "Erfolgreich abgemeldet",
        "list_deleted": "Liste gelöscht",
        "register_name_exists": "Dieser Nutzername wird schon vewerndet.",
        "profile_now_public": "Dein Profil kann jetzt öffentlich abgerufen werden.",
        "profile_now_private": "Dein Profil kann nicht mehr öffentlich abgerufen werden.",
        "request-denied-spam": "Zu viele Anfragen. (Spam erkannt)",
        "invalid_session": "Ungültige Anmeldung"
    }
}
</i18n>