<template>
  <div class="main-panel" style="position: relative">
    <LoaderOverlay :loading="client.loading" />
    <v-tabs-items :value="list === undefined ? 0 : 1" touchless>
      <v-tab-item>
        <v-card>
          <v-card-title v-if="list === undefined">
            {{ $t("title") }}
          </v-card-title>
          <v-card-text>
            <ListSelector
              :lists="client.lists.lists"
              :client="client"
              :createable="true"
              @createList="onCreateList($event)"
              @selectList="onSelectList($event)"
              @listsUpdated="onListsUpdated($event)"
            />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <ListEditor :list="list" :client="client" @goBack="exitEdit()" @listChanged="onListChange($event)"/>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import ListSelector from "./ListSelector";
import ListEditor from "./ListEditor";
import LoaderOverlay from "../../generic/LoaderOverlay";

export default {
  name: "ListsEditor",
  props: ["client"],
  components: {
    ListSelector,
    ListEditor,
    LoaderOverlay
  },
  data: () => ({
    list: undefined,
  }),
  computed: {
    lists() {
      return [];
    },
  },
  methods: {
    onCreateList(data) {
      this.client.lists.create(data.title, data.keywords).then((response) => {
        if (response.success) {
          this.onSelectList(response.created);
          this.$gtag.event('create_list', {
            'event_category': 'list',
            'event_label': 'List created',
            'value': 0
          });
        }
      });
    },
    onSelectList(id) {
      this.client.lists.load(id).then((response) => {
        if (response.success) {
          this.list = response.list;
          this.client.app.setTitle(this.list.title);
          this.client.app.backMethod = () => {
            this.exitEdit();
          };
        }
      });
    },
    onListsUpdated(lists) {
      this.client.lists.lists = lists;
    },
    exitEdit() {
      this.list = undefined;
      this.client.lists.loadInformationList();
      this.client.app.setTitle(this.$i18n.t('title'));
    },
    onListChange(list) {
      console.log("On list change", list);
      this.list = list;
      this.client.app.setTitle(list.title);
    }
  },
};
</script>

<i18n>
{
    "en": {
        "title": "My lists"
    },
    "de": {
        "title": "Meine Listen"
    }
}
</i18n>