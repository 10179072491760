<template>
  <div>
    <v-toolbar flat dark src="../../../../assets/bg/bg_2_toolbar.webp" v-if="!embed">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click="$emit('back')" v-bind="attrs" v-on="on"
            ><v-icon>mdi-arrow-left</v-icon></v-btn
          >
        </template>
        <span>{{ $t("to_profile") }}</span>
      </v-tooltip>

      <v-toolbar-title>{{ list.title }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <LanguageSelector />
      <AccountDropdown :client="client" />
    </v-toolbar>
    <div style="max-height: calc(100vh - 56px); overflow-y: auto">
      <DualContentRowReverse class="px-2 py-2">
        <template slot="large">
          <div>
            <v-card>
              <v-card-title>
                {{ $t("gear_title") }}
              </v-card-title>
              <v-card-text>
                <GearDisplayList
                  :list="list"
                  :editable="false"
                  :unit="unit"
                  :addable="loggedIn"
                />
              </v-card-text>
            </v-card>
          </div>
        </template>
        <template slot="small">
          <v-card>
            <v-card-title>
              {{ $t("weight_distribution") }}
            </v-card-title>
            <v-card-text>
              <v-tabs v-model="chartTab">
                <v-tab>
                  {{ $t("chart") }}
                </v-tab>
                <v-tab>
                  {{ $t("table") }}
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="chartTab">
                <v-tab-item>
                  <WeightPieChart :list="list" :unit="unit" />
                </v-tab-item>
                <v-tab-item>
                  <WeightTableChart :list="list" :unit="unit" />
                </v-tab-item>
              </v-tabs-items>
              <TotalWeightLabel class="mt-2" :list="list" :unit="unit" />
            </v-card-text>
          </v-card>
        </template>
      </DualContentRowReverse>
      <ImprintFooter :large="true" v-if="!embed"/>
    </div>
  </div>
</template>

<script>
import LanguageSelector from "../../../generic/LanguageSelector";
import DualContentRowReverse from "../../../generic/layout/DualContentRowReverse";
import GearDisplayList from "../../../editor/gear/GearDisplayList";
import WeightPieChart from "../../../editor/lists/WeightPieChart";
import TotalWeightLabel from "../../../editor/lists/TotalWeightLabel";
import AccountDropdown from "../../../account/AccountDropdown";
import ImprintFooter from "../../../home/ImprintFooter";
import WeightTableChart from "../../../editor/lists/WeightTableChart";

export default {
  name: "PublicListView",
  props: ["list", "client", "embed"],
  data: () => ({
    unit: "grams",
    chartTab: 0,
  }),
  components: {
    LanguageSelector,
    DualContentRowReverse,
    GearDisplayList,
    WeightPieChart,
    TotalWeightLabel,
    AccountDropdown,
    ImprintFooter,
    WeightTableChart,
  },
  computed: {
    loggedIn() {
      return this.client.session !== undefined;
    },
  },
};
</script>

<i18n
        ActionToolbar>
{
    "en": {
        "gear_title": "Gear",
        "weight_distribution": "Weight distribution",
        "to_profile": "To user profile",
        "chart": "Chart",
        "table": "Table"
    },
    "de": {
        "gear_title": "Ausrüstung",
        "weight_distribution": "Gewichtsverteilung",
        "to_profile": "Zum Nutzerprofil",
        "chart": "Diagramm",
        "table": "Tabelle"
    }
}
</i18n>