<template>
  <v-card>
    <v-card-title>
      {{ $t("title") }}
    </v-card-title>
    <v-card-text>
      {{ $t("unit") }}:
      <v-radio-group
        @change="selectUnit($event)"
        :value="client.account.profile.unit"
      >
        <v-radio :label="$t('unit_grams')" value="grams"></v-radio>
        <v-radio :label="$t('unit_ounce')" value="pounds"></v-radio>
      </v-radio-group>
      {{ $t("theme") }}:
      <v-radio-group
        @change="setDarkDesign($event)"
        :value="$vuetify.theme.dark"
      >
        <v-radio :label="$t('dark')" :value="true" />
        <v-radio :label="$t('light')" :value="false" />
      </v-radio-group>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "GenericSettings",
  props: ["client"],
  methods: {
    selectUnit(unit) {
      this.client.account.editProfile("unit", unit);
      this.$gtag.event('unit_changed', {
        'event_category': 'account',
        'event_label': 'Unit changed',
        'value': 0
      })
    },
    setDarkDesign(value) {
      this.$vuetify.theme.dark = value;
      localStorage.setItem('darkmode', value);
    }
  },
};
</script>

<i18n>
{
    "en": {
        "title": "General",
        "unit": "Weight unit",
        "unit_grams": "Gram (g) / Kilogram (kg)",
        "unit_ounce": "Ounce (oz) / Pound (lb)",
        "theme": "Application design",
        "dark": "Dark design",
        "light": "Light design"
    },
    "de": {
        "title": "Allgemein",
        "unit": "Gewichtseinheit",
        "unit_grams": "Gramm (g) / Kilogramm (kg)",
        "unit_ounce": "Unze (oz) / Pfund (lb)",
        "theme": "Aussehen der Anwendung",
        "dark": "Dunkles Design",
        "light": "Helles Design"
    }
}
</i18n>