<template>
  <v-text-field
    :value="value"
    :minlength="minlength"
    :maxlength="maxlength"
    @keydown="onKeydown($event)"
    @blur="onBlur()"
    @input="onInput($event)"
    hide-details
    ref="textfield"
  >
  </v-text-field>
</template>

<script>
export default {
  name: "RestrictedInlineTextfield",
  props: ["value", "minlength", "maxlength", "initfocus"],
  data: () => ({
    initialValue: undefined,
    currentValue: undefined,
  }),
  created() {
    this.initialValue = this.value;
    this.currentValue = this.value;
    if (this.initfocus) {
      this.$refs.textfield.focus();
    }
  },
  methods: {
    submit() {
      if (
        this.initialValue !== this.currentValue &&
        this.currentValue.length >= this.minlength &&
        this.currentValue.length <= this.maxlength
      ) {
        this.$emit("input", this.currentValue);
      } else {
        this.$emit("blur");
      }
    },
    onKeydown(event) {
      if (event.key === "Enter") {
        this.submit();
      }
    },
    onInput(value) {
      this.currentValue = value;
    },
    onBlur() {
      if (this.initialValue !== this.currentValue) {
        this.submit();
      }
    },
  },
};
</script>