<template>
    <div>
     <v-text-field
          @input="onInput($event)"
          @blur="onBlur()"
          @keydown="onKeydown()"
          :value="currentValue"
          type="number"
          min="1"
          max="1000"
        >
          <div slot="append">
            <v-icon v-if="currentValue > 1" @click="currentValue--"
              >mdi-minus</v-icon
            >
            <v-icon v-if="currentValue < 1000" @click="currentValue++"
              >mdi-plus</v-icon
            >
          </div>
        </v-text-field>
        <v-btn small color="success" @click="onBlur()">
            <v-icon left>mdi-content-save</v-icon> {{ $t('save') }}
        </v-btn>
    </div>
</template>

<script>
export default {
    name: 'CountInput',
    props: ['value'],
    data: () => ({
        initialValue: 1,
        currentValue: 1
    }),
    created() {
        this.initialValue = this.value;
        this.currentValue = this.value;
        console.log(this.initialValue)
    },
    methods: {
        onBlur() {
            if(this.currentValue && this.initialValue !== this.currentValue) {
                this.$emit('input', this.currentValue);
                this.initialValue = this.currentValue;
            }
        },
        onKeydown(event) {
            if(event.key === 'Enter') {
                this.onBlur();
            }
        },
        onInput(value) {
            this.currentValue = value;
        }
    }
}
</script>

<i18n>
{
    "en": {
        "save": "Save"
    },
    "de": {
        "save": "Speichern"
    }
}
</i18n>