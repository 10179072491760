<template>
  <div>
    <LoadingOverlay :loading="client.loading" />
    <v-tabs-items :value="tabIndex" touchless>
      <v-tab-item>
        <PublicProfile
          :client="client"
          :profile="profile"
          @selectList="onSelectList($event)"
        />
      </v-tab-item>
      <v-tab-item>
        <PublicListView
          v-if="selectedList !== undefined"
          :list="selectedList"
          :client="client"
          @back="resetList()"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import LoadingOverlay from "../../generic/LoaderOverlay";
import PublicProfile from "./PublicProfile";
import PublicListView from "./list/PublicListView";

export default {
  name: "PublicProfileView",
  props: ["name", "list", "client"],
  components: {
    LoadingOverlay,
    PublicListView,
    PublicProfile,
  },
  data: () => ({
    profile: undefined,
    selectedList: undefined,
  }),
  created() {
    this.client.account.getPublicProfile(this.name).then((response) => {
      if (response.success) {
        this.profile = response.profile;
        this.resetList();
        if(this.list) {
          this.onSelectList({_id: this.list});
        }
      } else {
        window.location.hash = "";
        this.client.app.setPage("home");
      }
    });
  },
  methods: {
    home() {
      this.client.app.setPage("home");
      window.location.hash = "";
    },
    resetList() {
      this.client.app.setTitle(
        `${this.profile.name}'s ${this.$i18n.t("profile")}`
      );
      window.location.hash = this.profile.name;
      this.selectedList = undefined;
    },
    onSelectList(list) {
      this.client.lists.getPublic(list._id).then((response) => {
        if (response.success) {
          this.selectedList = response.list;
          this.client.app.setTitle(response.list.title);
          window.location.hash = this.profile.name + ':' + this.selectedList._id;
        }
      });
    },
  },
  computed: {
    tabIndex() {
      return this.selectedList !== undefined ? 1 : 0;
    },
  },
};
</script>

<i18n>
{
    "en": {
        "profile": "profile"
    },
    "de": {
      "profile": "Profil"
    }
}
</i18n>