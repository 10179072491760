import { render, staticRenderFns } from "./DiscoverPlaceholder.vue?vue&type=template&id=7e7b764f&scoped=true&"
import script from "./DiscoverPlaceholder.vue?vue&type=script&lang=js&"
export * from "./DiscoverPlaceholder.vue?vue&type=script&lang=js&"
import style0 from "./DiscoverPlaceholder.vue?vue&type=style&index=0&id=7e7b764f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e7b764f",
  null
  
)

/* custom blocks */
import block0 from "./DiscoverPlaceholder.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VIcon,VSpacer})
