<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <flag :iso="isoCode" :squared="false"/>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(lang, index) in languages"
        :key="index"
        @click="select(lang.code)"
      >
        <v-list-item-title
          ><flag :iso="lang.iso" :squared="false" />
          {{ lang.name }}</v-list-item-title
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "LanguageSelector",
  data: () => ({
    languages: [
      {
        code: "en",
        iso: "us",
        name: "English",
      },
      {
        code: "de",
        iso: "de",
        name: "Deutsch",
      },
    ],
  }),
  computed: {
    isoCode() {
      return this.getISO(this.$i18n.locale);
    },
  },
  created() {
    if(localStorage.getItem('selected_lang')) {
      this.$i18n.locale = localStorage.getItem('selected_lang');
    } else {
      if(navigator.language.toLowerCase().includes('de')) {
        this.$i18n.locale = 'de';
      }
    }
  },
  methods: {
    getISO(lang) {
      switch (lang) {
        case "en":
          return "us";
        default:
          return lang;
      }
    },
    select(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem('selected_lang', lang);
    },
  },
};
</script>
