import UnitConverter from "./unit";
const parse = require('csv-parse/lib/sync')

const DataImport = {
    fields: {
        geargrams: {
            name: 0,
            brand: -1,
            category: 3,
            weight: 4,
            unit: 5,
            count: 6,
            wear: 7,
            pack: -1,
            image: -1

        },
        packtrek: {
            name: 0,
            brand: 1,
            category: 5,
            icon: 6,
            weight: 2,
            unit: -1,
            count: 3,
            wear: 7,
            pack: 8,
            image: 9
        },
        lighterpack: {
            name: 0,
            brand: -1,
            category: 1,
            count: 3,
            weight: 4,
            unit: 5,
            wear: 8,
            pack: -1,
            image: -1
        }
    },
    getField(name, data, service) {
        const fieldIndex = DataImport.fields[service][name];
        if (fieldIndex !== undefined && fieldIndex >= 0 && data[fieldIndex] !== undefined) {
            return data[fieldIndex].trim();
        } else {
            return '';
        }
    },
    importLighterpack(text) {
        const lines = text.split('\n');
        const header = lines[0];
        const data = [];
        let failed = [];
        for (let i = 1; i < lines.length; i++) {
            if (lines[i].length > 0) {
                const packet = header + '\n' + lines[i];
                try {
                    const parsed = parse(packet)[1];
                    const name = DataImport.getField('name', parsed, 'lighterpack');
                    const brand = DataImport.getField('brand', parsed, 'lighterpack');
                    const category = ['mdi-bag-personal', DataImport.getField('category', parsed, 'lighterpack')];
                    const unit = DataImport.getField('unit', parsed, 'lighterpack');
                    let weight = 0;
                    const weightValue = parseFloat(DataImport.getField('weight', parsed, 'lighterpack'));
                    if (unit === 'ounce') {
                        weight = UnitConverter.toGrams(weightValue, 'pounds');
                    } else if (unit === 'pound') {
                        weight = UnitConverter.toGrams(UnitConverter.convertToLower(weightValue, 'pounds'), 'pounds');
                    } else if (unit === 'kilogram') {
                        weight = UnitConverter.convertToLower(weightValue, 'grams');
                    } else {
                        weight = weightValue;
                    }

                    const count = parseInt(DataImport.getField('count', parsed, 'lighterpack'));
                    const wear = DataImport.getField('wear', parsed, 'lighterpack') !== '';
                    const pack = false;
                    data.push({
                        name,
                        brand,
                        category,
                        weight,
                        count,
                        wear,
                        pack
                    });
                } catch (error) { console.error(error); failed.push(lines[i]); }
            }
        }
        return { data, failed };
    },
    importItemLighterpackOld(line) {
        const split = line.split(',')
        if (split.length >= 8) {
            const name = DataImport.getField('name', split, 'lighterpack');
            if (name !== undefined && name.length > 0) {
                const brand = undefined;
                let categoryValue = DataImport.getField('category', split, 'lighterpack');
                if (categoryValue === '') {
                    categoryValue = 'Import';
                }
                const category = ['mdi-bag-personal', categoryValue];
                const unit = DataImport.getField('unit', split, 'lighterpack');
                let weight = 0;
                const weightValue = parseInt(DataImport.getField('weight', split, 'lighterpack'));
                if (unit === 'ounce') {
                    weight = UnitConverter.toGrams(weightValue, 'pounds');
                } else if (unit === 'pound') {
                    weight = UnitConverter.toGrams(UnitConverter.convertToLower(weightValue, 'pounds'), 'pounds');
                } else if (unit === 'kilogram') {
                    weight = UnitConverter.convertToLower(weightValue, 'grams');
                } else {
                    weight = weightValue;
                }
                const count = parseInt(DataImport.getField('count', split, 'lighterpack'));
                const wear = DataImport.getField('wear', split, 'lighterpack') !== '';
                const pack = false;
                return {
                    name,
                    brand,
                    category,
                    weight,
                    count,
                    wear,
                    pack
                };
            }
        }
    },
    importItemPacktrek(line) {
        while (line.includes('"')) line = line.replace('"', '');
        const split = line.split(',');
        if (split.length >= 8) {
            const name = DataImport.getField('name', split, 'packtrek');
            const brand = DataImport.getField('brand', split, 'packtrek');
            const category = [DataImport.getField('icon', split, 'packtrek'), DataImport.getField('category', split, 'packtrek')];
            const weight = parseInt(DataImport.getField('weight', split, 'packtrek'));
            const count = parseInt(DataImport.getField('count', split, 'packtrek'));
            const wear = DataImport.getField('wear', split, 'packtrek') === 'true';
            const pack = DataImport.getField('pack', split, 'packtrek') === 'true';
            const img = DataImport.getField('image', split, 'packtrek');
            return {
                name,
                brand,
                category,
                weight,
                count,
                wear,
                pack,
                img
            };
        } else {
            console.error("Argument length < 8:", split.length);
        }
    },
    importCSV(textData, service) {
        const lines = textData.split('\n');
        const data = [];
        let failed = 0;
        for (let i = 1; i < lines.length; i++) {
            if (lines[i].length > 8) {
                try {
                    let item = undefined;
                    switch (service) {
                        case 'lighterpack':
                            item = this.importItemLighterpack(lines[i]);
                            break;
                        case 'packtrek':
                            item = this.importItemPacktrek(lines[i]);
                            break;
                    }
                    if (item !== undefined) {
                        data.push(item);
                    } else {
                        console.error("Failed to import:", lines[i]);
                        failed++;
                    }
                } catch (ex) {
                    console.error(ex);
                    failed++;
                }
            }
        }
        return { data, failed };
    }
}

export default DataImport;