<template>
  <v-navigation-drawer
    v-model="showMenu"
    :temporary="small && false"
    style="z-index: 2"
    :absolute="small"
    :expand-on-hover="!small && !showMenu"
    :permanent="!small"
    :style="{ height: small ? '100%' : 'calc(100vh - 64px)' }"
    :class="{ 'v-card v-sheet': !small }"
  >
    <v-list-item class="px-2">
      <v-list-item-title class="d-flex"
        ><ProfileImageDisplay
          v-if="client.account.profile.image"
          :image="client.account.profile.image"
          :userid="client.account.profile.id"
          :icon="true"
          style="max-width: 2rem"
        />
        <div class="my-auto ml-2">
          {{ client.account.profile.name }}
        </div></v-list-item-title
      >
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense>
      <v-list-item-group v-model="selectedItem" color="primary">
        <v-list-item
          v-for="item in items"
          :key="item.key"
          link
          @click="onNav(item.key)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t(item.key) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item color="warning" @click="client.account.logout()">
          <v-list-item-icon>
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t("logout") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <div style="position: absolute; bottom: 0" v-if="showMenu">
      <ImprintFooter />
    </div>
  </v-navigation-drawer>
</template>

<script>
import ImprintFooter from "../home/ImprintFooter";
import ProfileImageDisplay from "../profile/image/ProfileImageDisplay";

export default {
  name: "EditorMenu",
  props: ["small", "client"],
  components: {
    ImprintFooter,
    ProfileImageDisplay,
  },
  data: () => ({
    selectedItem: 0,
    items: [
      {
        key: "lists",
        icon: "mdi-clipboard-list",
      },
      {
        key: "gear",
        icon: "mdi-bag-personal",
      },
      {
        key: "discover",
        icon: "mdi-apple-safari",
      },
      {
        key: "profile",
        icon: "mdi-account",
      },
      {
        key: "settings",
        icon: "mdi-cog",
      },
      {
        key: "feedback",
        icon: "mdi-message",
      },
    ],
    showMenu: true,
  }),
  created() {
    this.showMenu = !this.small;
  },
  methods: {
    getIndexOf(key) {
      let index = 0;
      for (let item of this.items) {
        if (item.key === key) {
          return index;
        }
        index++;
      }
      return -1;
    },
    onNav(tab) {
      this.$emit("nav", tab);
      this.client.app.setTitle(this.$i18n.t(tab));
      if (this.small) {
        this.toggle();
      }
    },
    onInputTab(tab) {
      this.selectedItem = this.getIndexOf(tab);
      this.$emit("nav", tab);
    },
    toggle() {
      this.showMenu = !this.showMenu;
    },
  },
};
</script>

<i18n>
{
    "en": {
        "title": "Menu",
        "lists": "My lists",
        "gear": "My gear",
        "discover": "Discover",
        "profile": "Profile",
        "settings": "Settings",
        "feedback": "Feedback",
        "logout": "Logout"
    },
    "de": {
        "title": "Menü",
        "lists": "Meine Listen",
        "gear": "Meine Ausrüstung",
        "discover": "Entdecken",
        "profile": "Profil",
        "settings": "Einstellungen",
        "feedback": "Feedback",
        "logout": "Abmelden"
    }
}
</i18n>