<template>
    <v-card flat class="headline">
        <slot></slot>
        <hr>
    </v-card>
</template>

<script>
export default {
    name: 'Headline'
}
</script>

<style scoped>
.headline {
    text-align: center;
    font-size: 3rem;
    text-transform: uppercase;
    margin-bottom: 2rem;
    margin-top: 2rem;
}
hr {
    width: 90vw;
    max-width: 400px;
    margin-right: auto;
    margin-left: auto;
    opacity: .5;
}
</style>