<template>
  <v-dialog max-width="600" v-model="visible">
    <template>
      <LoadingOverlay :loading="client.loading" />
      <v-card>
        <v-toolbar
          >{{ $t("title") }}
          <v-spacer />
          <v-btn icon @click="hide()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text v-if="submitted" class="pt-2">
          <v-icon color="success">mdi-information-outline</v-icon>
          {{ $t('submitted') }}
        </v-card-text>
        <v-card-text v-else>
          <p class="my-2">
            {{ $t("desc") }}
          </p>
          <v-text-field
            :label="$t('mail')"
            :disabled="sending"
            maxlength="60"
            hide-details="auto"
            v-model="input.mail"
            @keydown="onInputKeydown($event)"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="hide()" v-if="submitted">{{ $t('close') }}</v-btn>
          <v-btn text v-else :disabled="!valid || sending" color="success" @click="submit()">
            {{ $t("submit") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import LoadingOverlay from "../generic/LoaderOverlay";
export default {
  name: "ForgotPasswordDialog",
  props: ["client"],
  components: {
    LoadingOverlay
  },
  data: () => ({
    visible: false,
    sending: false,
    submitted: false,
    input: {
      mail: "",
    },
  }),
  computed: {
    valid() {
      const split = this.input.mail.split("@");
      if (split.length === 2 && this.input.mail.length <= 60) {
        const domainSplit = split[1].split(".");
        if (domainSplit.length >= 2) {
          return (
            split[0].length >= 2 &&
            domainSplit[0].length >= 2 &&
            domainSplit[1].length >= 2
          );
        }
      }
      return false;
    },
  },
  methods: {
    show() {
      this.visible = true;
    },
    submit() {
      this.sending = true;
      this.client.account
        .requestResetPassword(this.input.mail)
        .then((response) => {
          this.input.mail = '';
          this.sending = false;
          if (response.success) {
            this.submitted = true;
          } else {
            this.client.app.handleServerError(response);
            this.hide();
          }
        });
    },
    onInputKeydown(event) {
      if(event.key === 'Enter' && this.valid) {
        this.submit();
      }
    },
    hide() {
      this.visible = false;
    },
  },
};
</script>

<i18n>
{
    "en": {
        "title": "Reset password",
        "desc": "Enter your e-mail address to restore your password.",
        "mail": "E-mail address",
        "submit": "Confirm",
        "close": "Done",
        "submitted": "If the e-mail address belongs to your account, you have received an e-mail with further instructions."
    },
    "de": {
        "title": "Passwort zurücksetzen",
        "desc": "Gebe deine E-Mail Adresse ein, um dein Passwort zurückzusetzen.",
        "mail": "E-Mail Adresse",
        "submit": "Senden",
        "close": "Fertig",
        "submitted": "Wenn die E-Mail Adresse zu deinem Konto gehört hast du eine E-Mail mit weiteren Hinweisen erhalten."
    }
}
</i18n>