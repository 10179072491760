 <template>
  <SimpleDialog :visible="visible" :title="$t('title')" @hide="hide()">
    <br />
    <b>{{ $t("confirm_text") }}</b>
    <v-text-field
      :type="showPassword ? 'text' : 'password'"
      maxlength="50"
      v-model="password"
      :label="$t('password')"
    >
      <v-icon
        slot="append"
        @click="showPassword = !showPassword"
        v-text="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
      >
      </v-icon>
    </v-text-field>
    <div color="error">
      {{ $t("warning") }}
    </div>
    <v-row class="mt-4">
      <v-spacer />
      <v-btn text small @click="hide()">
        {{ $t("abort") }}
      </v-btn>
      <v-btn
        text
        color="error"
        small
        :disabled="password.length < 6"
        @click="confirm()"
      >
        {{ $t("confirm") }}
      </v-btn>
    </v-row>
  </SimpleDialog>
</template>

<script>
import SimpleDialog from "../../generic/SimpleDialog";

export default {
  name: "DeleteAccountDialog",
  props: ["client"],
  components: {
    SimpleDialog,
  },
  data: () => ({
    visible: false,
    password: "",
    showPassword: false,
  }),
  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    confirm() {
      this.client.account.delete(this.password).then((response) => {
        if (response.success) {
          this.client.app.setPage("home");
        }
        this.hide();
      });
    },
  },
};
</script>

<i18n>
{
    "en": {
        "title": "Delete Account",
        "confirm_text": "To delete your account, please confirm your password.",
        "password": "Password",
        "warning": "You can not restore your account if you delete it.",
        "confirm": "Delete account",
        "abort": "Abort"
    },
    "de": {
        "title": "Account löschen",
        "confirm_text": "Bitte bestätigen sie ihr Passwort um ihren Account zu löschen.",
        "password": "Passwort",
        "warning": "Sie können ihren Account nicht wiederherstellen nachdem sie ihn gelöscht haben.",
        "confirm": "Account löschen",
        "abort": "Abbrechen"
    }
}
</i18n>