<template>
      <v-card class="login-card">
          <v-card-title>
            <v-tabs v-model="tab">
              <v-tab>
                {{ $t("login") }}
              </v-tab>
              <v-tab>
                {{ $t("register") }}
              </v-tab>
            </v-tabs>
          </v-card-title>
          <v-card-text>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <LoginDialog :client="client"/>
              </v-tab-item>
              <v-tab-item>
                <RegisterDialog :client="client"/>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
</template>

<script>
import LoginDialog from "./LoginDialog";
import RegisterDialog from "./RegisterDialog";

export default {
    name: 'LoginRegisterCard',
    props: ['client'],
    data: () => ({
        tab: 0
    }),
    components: {
        LoginDialog,
        RegisterDialog
    },
    methods: {
      showRegister() {
        this.tab = 1;
      }
    }
}
</script>

<style scoped>
</style>

<i18n>
{
    "en": {
        "login": "Login",
        "register": "Sign up",
        "mail": "E-Mail address"
    },
    "de": {
        "login": "Anmelden",
        "register": "Registrieren",
        "mail": "E-Mail Adresse"
    }
}
</i18n>