<template>
  <v-tabs-items touchless :value="tab">
    <v-tab-item>
      <v-card>
        <v-card-text>
          <v-list>
            <!--
            <v-list-item @click="mode = 'packtrek'">
              <v-list-item-icon>
                <v-icon>mdi-upload</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> PackTrek </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t("import_packtrek") }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            -->
            <!--
            <v-list-item @click="mode = 'geargrams'">
              <v-list-item-icon>
                <v-icon>mdi-upload</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> GearGrams </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t("import_geargrams") }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            -->
            <v-list-item @click="mode = 'lighterpack'">
              <v-list-item-icon>
                <v-icon>mdi-upload</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Lighterpack </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t("import_lighterpack") }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-tab-item>
    <v-tab-item>
      <v-toolbar class="my-2 mx-2">
        <v-btn icon @click="mode = undefined">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title> {{ $t(mode) }} {{ $t("import") }} </v-toolbar-title>
      </v-toolbar>
      <input
        type="file"
        class="file-input mx-2 mt-2"
        ref="fileInput"
        accept=".csv"
        @input="onInputFile($event)"
      />
    </v-tab-item>
    <v-tab-item>
      <v-toolbar class="my-2 mx-2">
        <v-btn icon @click="loaded = false">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>
          {{ $t("loaded_items") }} ({{ importedData.length }})
        </v-toolbar-title>
      </v-toolbar>
      <ImportListEdit :list="importedData" @remove="removeItem($event)" />
      <v-expansion-panels v-if="failed.length > 0">
            <v-expansion-panel
    >
      <v-expansion-panel-header >
        <div style="max-width: 2rem;">
        <v-icon color="error" left>mdi-alert</v-icon>
        </div> {{$t('failed')}}: {{failed.length}}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div v-for="(entry, index) of failed" :key="index">
          {{index+1}}: {{entry}}
        </div>
        <br>
        {{$t('fail_reasons')}}
      </v-expansion-panel-content>
    </v-expansion-panel>
      </v-expansion-panels>
      <v-checkbox
        :label="$t('add_to_library')"
        v-model="addToLibrary"
      ></v-checkbox>
      <div class="d-flex">
        <v-spacer />
        <v-btn
          text
          color="success"
          @click="submit()"
          :disabled="importedData.length <= 0 || disabled"
        >
          <v-icon left>mdi-check</v-icon> {{ $t("confirm") }}
        </v-btn>
      </div>
    </v-tab-item>
  </v-tabs-items>
</template>

<script>
import DataImport from "../../../lib/import";
import ImportListEdit from "./ImportListEdit";

export default {
  name: "Import",
  props: ["disabled"],
  components: {
    ImportListEdit,
  },
  data: () => ({
    mode: undefined,
    loaded: false,
    importedData: [],
    failed: [],
    addToLibrary: false,
  }),
  computed: {
    tab() {
      if (this.mode === undefined) {
        return 0;
      } else if (!this.loaded) {
        return 1;
      } else {
        return 2;
      }
    },
  },
  methods: {
    reset() {
      this.mode = undefined;
      this.loaded = false;
    },
    onInputFile(event) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        switch (this.mode) {
          case "lighterpack":
            {
              const result = DataImport.importLighterpack(fileReader.result);
              this.importedData = result.data;
              this.failed = result.failed;
              this.loaded = true;
              this.$refs.fileInput.value = "";
            }
            break;
          case "geargrams":
          case "packtrek":
            {
              const result = DataImport.importCSV(fileReader.result, this.mode);
              this.importedData = result.data;
              this.failed = result.failed;
              this.loaded = true;
              this.$refs.fileInput.value = "";
            }
            break;
          default:
            console.error("Mode not implemented", this.mode);
            break;
        }
      };
      fileReader.readAsText(event.target.files[0]);
    },
    removeItem(index) {
      this.importedData.splice(index, 1);
      if (this.importedData.length <= 0) {
        this.loaded = false;
      }
    },
    submit() {
      this.$emit("import", {
        items: this.importedData,
        save: this.addToLibrary,
      });
    },
  },
};
</script>

<style scoped>
.file-input {
  color: black;
  background-color: white;
}
</style>

<i18n>
{
  "en": {
    "packtrek": "PackTrek",
    "geargrams": "GearGrams",
    "lighterpack": "Lighterpack",
    "import": "import",
    "import_packtrek": "Import .csv file from PackTrek",
    "import_geargrams": "Import .csv file from GearGrams",
    "import_lighterpack": "Import .csv file from Lighterpack",
    "loaded_items": "Loaded items",
    "confirm": "Import",
    "failed": "Failed to read / ignored items",
    "add_to_library": "Add to my gear library",
    "fail_reasons": "Possible reasons: invalid characters (e.g. '\"') or invalid formatting."
  },
  "de": {
    "packtrek": "PackTrek",
    "geargrams": "GearGrams",
    "lighterpack": "Lighterpack",
    "import": "Import",
    "import_packtrek": "Importiere eine .csv Datei von PackTrek",
    "import_geargrams": "Importiere eine .csv Datei von GearGrams",
    "import_lighterpack": "Importiere eine .csv Datei von Lighterpack",
    "loaded_items": "Geladene Gegenstände",
    "confirm": "Übernehmen",
    "failed": "Fehlerhafte ignorierte Gegenstände",
    "add_to_library": "Zu meiner Ausrüstung hinzufügen",
    "fail_reasons": "Mögliche Gründe: Ungültige Zeichen (z.B '\"') oder fehlerhafte Formatierung."
  }
}
</i18n>