<template>
  <div>
    <LoadingOverlay :loading="client.loading" />
    <CreateFirstButton
      v-if="client.gear.list.length <= 0"
      class="center-button"
      :text="$t('create_first')"
      @click="onCreateNew()"
    />
    <CreateGearDialog
      :client="client"
      ref="createGearDialog"
      @createdItem="onCreatedItem($event)"
      mode="gear"
    />
    <GearImageDialog ref="gearImageDialog" />
    <v-tabs-items :value="selectedList === undefined ? 0 : 1">
      <v-tab-item>
        <v-card>
          <v-card-title>
            {{ $t("title") }}
          </v-card-title>
          <v-card-subtitle v-if="client.gear.list.length <= 0">
            {{ $t("subtitle") }}
          </v-card-subtitle>
          <v-card-text>
            <v-toolbar>
              <v-toolbar-title v-if="selectedItems.length > 0">
                {{ $t("selected") }}: {{ selectedItems.length }}
              </v-toolbar-title>
              <v-text-field
                v-model="filter"
                v-if="selectedItems.length <= 0"
                clearable
                @click:clear="filter = '';"
                flat
                hide-details
                prepend-inner-icon="mdi-magnify"
                :label="$t('search')"
              ></v-text-field>
              <v-spacer />
              <v-scale-transition>
                <DeleteButton
                  v-if="selectedItems.length > 0"
                  :question="$t('delete_selection')"
                  @confirm="deleteSelection()"
                />
              </v-scale-transition>
              <v-btn
                :color="selectedItems.length <= 0 ? 'success' : ''"
                :class="{ 'ml-2': selectedItems.length <= 0 }"
                :icon="selectedItems.length > 0"
                @click="
                  selectedItems.length > 0 ? clearSelection() : onCreateNew()
                "
              >
                <v-icon v-if="selectedItems.length <= 0">mdi-plus</v-icon>
                <v-icon v-else>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-btn small v-if="selectedItems.length > 0" @click="selectAll()" class="mt-2">
              <v-icon left>mdi-plus</v-icon> {{ $t("all") }}
            </v-btn>
            <v-expansion-panels accordion class="mt-2" :value="0">
              <v-expansion-panel
                v-for="(category, index) of categories"
                :key="category[1]"
                :expanded="index === 0"
                :disabled="getItemsByCategory(category).length <= 0"
              >
                <v-expansion-panel-header
                  ><v-icon
                    v-text="category[0]"
                    style="max-width: min-content"
                    class="mr-2"
                  ></v-icon>
                  {{ category[1] }}</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-list dense>
                    <v-list-item
                      v-for="item of getItemsByCategory(category)"
                      :key="item.id"
                    >
                      <v-list-item-action>
                        <v-checkbox
                          @click.stop=""
                          @change="setSelected(item, $event)"
                          :value="isSelected(item)"
                        ></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-icon class="category-icon my-auto">
                        <img
                          v-if="item.img && item.img.length > 0"
                          class="image"
                          :src="item.img"
                          @click="$refs.gearImageDialog.show(item)"
                        />
                        <i
                          v-else
                          class="mdi icon"
                          :class="category[0]"
                          :style="{ color: getCategoryColor(index) }"
                        ></i>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.brand }} {{ item.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ formatWeight(item) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              @click="showEdit(item)"
                              v-on="on"
                              v-bind="attrs"
                            >
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t("edit") }}</span>
                        </v-tooltip>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Unit from "../../../lib/unit";
import Globals from "../../../lib/globals";
import LoadingOverlay from "../../generic/LoaderOverlay";
import CreateFirstButton from "../../generic/CreateFirstButton";
import DeleteButton from "../../generic/DeleteButton";
import CreateGearDialog from "./CreateGearDialog";
import GearImageDialog from "./GearImageDialog";

export default {
  name: "GearListEditor",
  props: ["client"],
  components: {
    LoadingOverlay,
    CreateFirstButton,
    DeleteButton,
    CreateGearDialog,
    GearImageDialog,
  },
  data: () => ({
    selectedList: undefined,
    selectedItems: [],
    filter: "",
  }),
  created() {
    if (!this.client.gear.listLoaded) {
      this.client.gear.loadList();
    }
  },
  computed: {
    categories() {
      let categories = [];
      let categoryNames = [];
      for (let item of this.client.gear.list) {
        if (!categoryNames.includes(item.category[1])) {
          categories.push(item.category);
          categoryNames.push(item.category[1]);
        }
      }
      return categories;
    },
  },
  methods: {
    showEdit(item) {
      this.$refs.createGearDialog.showEdit(item, -1);
    },
    clearSelection() {
      this.selectedItems = [];
    },
    onCreateNew() {
      this.$refs.createGearDialog.show();
    },
    onCreatedItem(item) {
      console.log(item);
    },
    selectAll() {
      this.clearSelection();
      for (const item of this.client.gear.list) {
        this.selectedItems.push(item);
      }
    },
    getItemsByCategory(category) {
      return this.client.gear.list.filter(
        (item) =>
          item.category[0] === category[0] && item.category[1] === category[1] && (this.filter.length > 0 ? (item.name.includes(this.filter) || item.brand.includes(this.filter)) : true)
      );
    },
    setSelected(item, state) {
      if (state && !this.isSelected(item)) {
        this.selectedItems.push(item);
      } else {
        this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
      }
    },
    isSelected(item) {
      return this.selectedItems.includes(item);
    },
    formatWeight(item) {
      return Unit.formatString(item.weight, this.client.account.profile.unit);
    },
    getCategoryIcon(category) {
      return Globals.category_icons[category];
    },
    getCategoryColor(index) {
      return Globals.getCategoryColor(index);
    },
    deleteSelection() {
      let items = [];
      for (const item of this.selectedItems) {
        items.push(item._id);
      }
      this.client.gear.deleteItems(items).then((response) => {
        if (response.success) {
          this.selectedItems = [];
        }
      });
    },
    getBackgroundColor(category) {
      return Globals.category_colors_lite[category];
    },
  },
};
</script>

<style scoped>
.category-icon {
  margin-right: 0.5rem;
  margin-top: auto;
  margin-bottom: auto;
}

.center-button {
  position: absolute;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
}
.icon {
  font-size: 1.5rem;
}
.image {
  max-width: 1.5rem;
  max-height: 1.5rem;
  cursor: zoom-in;
}
</style>

<i18n>
{
    "en": {
        "title": "My gear",
        "subtitle": "You can reuse your saved gear in all of your lists. If you update an item here it will get changed in every list too.",
        "search": "Search for title or manufacturer...",
        "selected": "Selected",
        "delete_selection": "Delete selection",
        "delete_confirm": "Do you really want to delete the selected gear entries?",
        "create_first": "Create first gear item",
        "edit": "Edit",
        "all": "Select all"
    },
    "de": {
        "title": "Meine Ausrüstung",
        "subtitle": "Du kannst deine gespeicherte Ausrüstung in deinen Listen einfügen. Wenn du einen gespeicherten Gegenstand bearbeitest wird er in allen Listen ebenfalls geändert.",
        "search": "Suche nach Titel oder Hersteller...",
        "selected": "Ausgewählt",
        "delete_selection": "Auswahl löschen",
        "delete_confirm": "Möchten sie die ausgewählten Ausrüstungsgegenstände wirklich löschen?",
        "create_first": "Ersten Gegenstand erstellen",
        "edit": "Bearbeiten",
        "all": "Alle auswählen"
    }
}
</i18n>