<template>
  <v-card flat>
    <v-tabs-items :value="tab" class="mt-2">
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-text-field
              :label="$t('input_query')"
              solo
              maxlength="50"
              class="mb-0"
              hide-details
              v-model="query"
              @keydown="onQueryKeydown($event)"
            >
              <template slot="append">
                <v-btn
                small
                  :disabled="!queryValid"
                  @click="search(query)"
                  color="primary"
                >
                  <v-icon>mdi-magnify</v-icon>
                  
                </v-btn>
              </template>
            </v-text-field>
            <div class="d-flex my-2" style="flex-wrap: wrap;">
              <v-chip
                small
                v-for="qry of recent"
                :key="qry"
                class="mx-1 my-1"
                @click="search(qry)"
                >{{ qry }}</v-chip
              >
            </div>
            <InfoIconCard icon="mdi-information-outline" color="info">
              {{ $t("input_notice") }}
            </InfoIconCard>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-toolbar dense>
          <v-btn icon @click="reset()">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title> {{ $t("results") }}: {{ query }} </v-toolbar-title>
        </v-toolbar>
        <v-card flat>
          <v-card-text>
            <div v-if="items.length <= 0">
              <v-icon color="red">mdi-emoticon-sad-outline</v-icon>
              {{ $t("no_results") }}
            </div>
            <v-virtual-scroll
              :height="scrollHeight"
              :item-height="itemHeight"
              :items="items"
              v-else
            >
              <template v-slot:default="{ item }">
                <v-list-item :key="item._id" dense>
                  <v-list-item-icon class="my-auto">
                    <img :src="item.img" class="image"/>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.brand }} {{ item.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ formatWeight(item.weight) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <div>
                    <v-btn color="success" icon @click="addItem(item)">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import Globals from "../../../../lib/globals";
import UnitConverter from "../../../../lib/unit";
import InfoIconCard from "../../../generic/layout/InfoIconCard";

export default {
  name: "PublicGearSearch",
  props: ["client"],
  components: {
    InfoIconCard,
  },
  data: () => ({
    query: "",
    searched: false,
    items: [],
    itemHeight: 50,
    recent: [],
  }),
  created() {
    if (localStorage.getItem("recent_search")) {
      this.recent = JSON.parse(localStorage.getItem("recent_search"));
    }
  },
  computed: {
    tab() {
      return this.searched ? 1 : 0;
    },
    queryValid() {
      return this.query.length >= 2 && this.query.length <= 50;
    },
    scrollHeight() {
      const totalHeight = this.itemHeight * this.items.length;
      if (totalHeight > 600) {
        if (window.innerHeight - 100 < 600) {
          return window.innerHeight - 100;
        } else {
          return 600;
        }
      } else {
        return totalHeight;
      }
    },
  },
  methods: {
    search(query) {
      if (this.query !== query) {
        this.query = query;
      }
      this.client.gear.queryPublic(query).then((response) => {
        if (response.success) {
          this.searched = true;
          this.items = response.items;
          if (response.items.length > 0) {
            console.log("Results.", response.items);
            this.addRecent(query);
          }
        }
      });
    },
    addRecent(search) {
      if (search.length >= 2) {
        if (!this.recent.includes(search)) {
          this.recent.push(search);
          if (this.recent.length > 5) {
            this.recent.splice(this.recent.length - 1, 1);
          }
          localStorage.setItem("recent_search", JSON.stringify(this.recent));
        }
      }
    },
    getCategoryIcon(category) {
      return Globals.category_icons[category];
    },
    getCategoryColor(category) {
      return Globals.category_colors[category];
    },
    reset() {
      this.query = "";
      this.searched = false;
    },
    onQueryKeydown(event) {
      if (event.key === "Enter" && this.queryValid) {
        this.search(this.query);
      }
    },
    formatWeight(weight) {
      return UnitConverter.formatString(
        weight,
        this.client.account.profile.unit
      );
    },
    addItem(item) {
      this.$emit("select", item._id);
    },
  },
};
</script>

<style scoped>
.image {
  width: 2rem;
  height: 2rem;
}
</style>

<i18n>
{
    "en": {
        "input_query": "Search name or manufacturer...",
        "search": "Search",
        "results": "Results",
        "no_results": "No results found for this keyword. :(",
        "input_notice": "The public database is automatically filled with gear from all users. It grows over time automatically."
    },
    "de": {
        "input_query": "Namen oder Hersteller suchen...",
        "search": "Suchen",
        "results": "Ergebnisse",
        "no_results": "Es wurden keine Ergebnisse für diesen Begriff gefunden. Die Datenbank wächst mit der Zeit, daher kann es sein das dieser Gegenstand noch nicht erfasst wurde.",
        "input_notice": "Die öffentliche Datenbank wird automatisch mit Gegenständen von anderen Nutzern gefüllt. Sie wächst automatisch mit der Zeit."
    }
}
</i18n>