<template>
  <v-card>
            <v-card-subtitle>{{ $t("title") }}</v-card-subtitle>
    <v-card-text>
      <v-chip
        class="mr-1 mb-1"
        text-color="white"
        v-for="keyword of data.top"
        :key="keyword.key"
        color="green"
        @click="$emit('input', keyword)"
      >
        <v-avatar color="green darken-4" class="mr-1">{{ keyword.count }}</v-avatar>
        #{{ keyword.key }}
      </v-chip>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "DiscoverTrendList",
  props: ["data"],
};
</script>

<i18n>
{
    "en": {
        "title": "Trends"
    },
    "de": {
        "title": "Trends"
    }
}
</i18n>