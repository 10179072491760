<template>
  <SimpleDialog
    :title="$t('title')"
    :visible="visible"
    @hide="hide()"
    icon="mdi-upload"
  >
    <Import
      :client="client"
      class="my-2"
      ref="importMenu"
      :unit="client.account.profile.unit"
      @import="onImport($event)"
      :disabled="importing"
    />
  </SimpleDialog>
</template>

<script>
import SimpleDialog from "../../generic/SimpleDialog";
import Import from "./Import";

export default {
  name: "ImportDialog",
  props: ["client", "listId"],
  components: {
    SimpleDialog,
    Import,
  },
  data: () => ({
    visible: false,
    importing: false
  }),
  methods: {
    show() {
      if (this.$refs.importMenu) {
        this.$refs.importMenu.reset();
      }
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    onImport(event) {
      this.importing = true;
      this.client.lists.import(this.listId, event.items, event.save).then((response) => {
        if (response.success) {
          this.hide();
          this.$emit("listChanged", response.list);
          if(event.save) {
            this.client.gear.listLoaded = false;
          }
          this.client.app.showInfo(event.items.length + ' ' + this.$i18n.t('imported') + '.');
          this.importing = false;
        }
      });
    },
  },
};
</script>

<i18n>
{
    "en": {
        "title": "Import items",
        "imported": "items imported"
    },
    "de": {
        "title": "Gegenstände importieren",
        "imported": "Gegenstände importiert"
    }
}
</i18n>