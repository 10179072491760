<template>
  <div>
    <v-subheader>{{ $t("add_item") }}</v-subheader>
    <v-list-item dense @click="$emit('createNew')">
      <v-list-item-icon>
        <v-icon color="success">mdi-plus</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t("new_item") }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item dense @click="$emit('pick')">
      <v-list-item-icon>
        <v-icon>mdi-book</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t("my_gear") }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item dense @click="$emit('public')">
      <v-list-item-icon>
        <v-icon>mdi-magnify-plus-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t("public_library") }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item dense @click="$emit('import')">
      <v-list-item-icon>
        <v-icon>mdi-upload</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t("import") }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: "AddGearMenu",
  props: ["list", "client"],
};
</script>

<i18n>
{
  "en": {
    "my_gear": "My gear",
    "new_item": "New item",
    "public_library": "Public library",
    "add_item": "Add gear",
    "import": "Import items"
  },
  "de": {
    "my_gear": "Meine Ausrüstung",
    "new_item": "Neuer Gegenstand",
    "public_library": "Öffentliche Bibliothek",
    "add_item": "Gegenstände hinzufügen",
    "import": "Importieren"
  }
}
</i18n>