<script>
import { Doughnut } from "vue-chartjs";
import UnitConverter from "../../../lib/unit";
import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes';
import Globals from "../../../lib/globals";


export default {
  name: "WeightPieChart",
  props: ["list", "unit"],
  extends: Doughnut,
  computed: {
    categories() {
      let categories = [];
      for(const item of this.list.items) {
        let includes = false;
        for(const category of categories) {
          if(category[0] === item.category[0] && category[1] === item.category[1]) {
            includes = true;
            break;
          }
        }
        if(!includes) {
          categories.push(item.category);
        }
      }
      return categories;
    },
    displayCategories() {
      return this.categories.filter(category => this.getWeightByCategory(category) > 0);
    },
    labels() {
      return this.displayCategories.map(category => category[1]);
    },
    weights() {
      const weights = [];
      for (const category of this.displayCategories) {
        weights.push(this.getWeightByCategory(category));
      }
      return weights;
    },
    totalWeight() {
      let weight = 0;
      for (const w of this.weights) {
        weight += w;
      }
      return weight;
    },
  },
  methods: {
    getItemsByCategory(category) {
      return this.list.items.filter((item) => item.category[0] === category[0] && item.category[1] === category[1]);
    },
    getWeightByCategory(category) {
      let weight = 0;
      for (const item of this.getItemsByCategory(category)) {
        if (!item.wear) weight += item.weight * item.count;
      }
      return weight;
    },
    getPercentageByCategory(category) {
      return (
        (100 * (this.getWeightByCategory(category) / this.totalWeight)) | 0
      );
    },
    render() {
      this.renderChart(
        {
          labels: this.labels,
          datasets: [
            {
              label: "Weight chart",
              data: this.weights,
              borderWidth: 1,
            },
          ],
        },
        {
          plugins: {
            colorschemes: {
                scheme: Globals.colors
            }
          },
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          tooltips: {
            callbacks: {
              title: (tooltipItem, data) => {
                return data["labels"][tooltipItem[0]["index"]];
              },
              label: (tooltipItem, data) => {
                const weight =
                  data.datasets[tooltipItem.datasetIndex].data[
                    tooltipItem.index
                  ];
                return UnitConverter.formatString(weight, this.unit);
              },
              afterLabel: (tooltipItem, data) => {
                const weight =
                  data.datasets[tooltipItem.datasetIndex].data[
                    tooltipItem.index
                  ];
                const percentage = (100 * (weight / this.totalWeight)) | 0;
                return `${percentage}%`;
              },
            },
          },
        }
      );
    },
    renderAfter(time) {
      setTimeout(() => this.render(), time);
    },
  },
  mounted() {
    this.render();
  },
};
</script>

<style>
</style>
