import Config from "../config";

export default class DiscoverClient {
    constructor(client) {
        this.client = client;
        this.lastLoaded = -1;
        this.displayData = undefined;
    }

    getDisplayData() {
        if (this.displayData === undefined || (Date.now() - this.lastLoaded >= Config.Discover.ReloadMinDelay * 60000)) {
            return new Promise((resolve) => {
                this.client.post('discover', {
                    action: 'get'
                }).then(response => {
                    if(response.success) {
                        this.displayData = response.data;
                        this.lastLoaded = Date.now();
                    }
                    resolve(response);
                }); 
            });
        } else {
            return new Promise((resolve) => {
                resolve({success: true, data: this.displayData});
            });
        }
    }

    search(query, keywordOnly) {
        return this.client.post('discover', {
            action: 'search',
            query,
            keywordOnly
        });
    }
}
