<template>
  <div>
    <LoadingOverlay :loading="client.loading" />
    <template v-if="sent">
        <InfoIconCard icon="mdi-information" color="success">
            {{ $t('sent') }}
        </InfoIconCard>
    </template>
    <template v-else>
    <v-textarea
      maxlength="2000"
      solo
      rows="10"
      hide-details
      :label="$t('message')"
      v-model="message"
    ></v-textarea>
    <v-checkbox :label="$t('label_reply')" class="mb-2" v-model="receiveAnswer"></v-checkbox>
    <div>{{ $t("hint") }}</div>
    <div class="d-flex">
      <v-spacer />
      <v-btn color="success" :disabled="!valid" @click="send()">
        <v-icon left>mdi-send</v-icon> {{ $t("send") }}
      </v-btn>
    </div>
    </template>
  </div>
</template>

<script>
import LoadingOverlay from "../generic/LoaderOverlay";
import InfoIconCard from "../generic/layout/InfoIconCard";

export default {
  name: "FeedbackForm",
  props: ["client"],
  components: {
    LoadingOverlay,
    InfoIconCard
  },
  data: () => ({
    message: "",
    sent: false,
    receiveAnswer: false
  }),
  computed: {
    valid() {
      return (
        this.message.trim().length >= 2 && this.message.trim().length <= 5000
      );
    },
  },
  methods: {
    send() {
      if (this.valid) {
        let msg = this.message;
        while(msg.includes('\n')) {
            msg = msg.replace('\n', '<br>');
        }
        this.client.account.sendFeedback(msg, {answer: this.receiveAnswer}).then(response => {
            this.sent = response.success;
        });
      }
    },
  },
};
</script>

<i18n>
{
    "en": {
        "message": "Your message...",
        "send": "Send",
        "hint": "Your message will only be sent to the developers of this page and not be shared with others.",
        "sent": "Your message has been sent. Thank you for your feedback!",
        "label_reply": "I would be happy to receive a reply."
    },
    "de": {
        "message": "Deine Nachricht...",
        "send": "Senden",
        "hint": "Deine Nachricht wird ausschließlich an die Entwickler dieser Anwendung gesendet und nicht mit Dritten geteilt.",
        "sent": "Deine Nachricht wurde gesendet. Vielen Dank für dein Feedback!",
        "label_reply": "Ich würde mich freuen eine Antwort zu erhalten."
    }
}
</i18n>