<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-on="on" v-bind="attrs">
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </template>
    <v-card>
      <LoadingOverlay :loading="client.loading" />
      <v-card-text>
        <v-switch
          @click.stop=""
          :label="$t('public_list')"
          @change="onTogglePublic($event)"
          :input-value="list.public"
        />
        <v-btn class="mb-2" small @click="$emit('editKeywords')"><v-icon left>mdi-pencil</v-icon> {{ $t('edit_keywords') }}</v-btn><br>
        <DeleteButton :question="$t('delete_list')" @confirm="deleteList()" :showLabel="true" :small="true" />
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import LoadingOverlay from "../../generic/LoaderOverlay";
import DeleteButton from "../../generic/DeleteButton";

export default {
  name: "SettingsMenu",
  props: ["client", "list"],
  components: {
    LoadingOverlay,
    DeleteButton,
  },
  methods: {
    onTogglePublic(value) {
      this.client.lists.changeSetting(this.list._id, "public", value);
    },
    deleteList() {
      this.client.lists.delete(this.list._id).then(() => {
        this.client.app.showInfo("list_deleted");
        this.$emit("deleted");
      });
    },
  },
};
</script>

<i18n>
{
    "en": {
        "public_list": "Share public",
        "delete_list": "Delete list",
        "edit_keywords": "Edit keywords"
    },
    "de": {
        "public_list": "Öffentlich teilen",
        "delete_list": "Liste löschen",
        "edit_keywords": "Schlüsselwörter bearbeiten"
    }
}
</i18n>