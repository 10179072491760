import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import VueGtag from "vue-gtag";

if (window.location.host !== 'localhost') {
  Vue.use(VueGtag, {
    config: { id: "G-TB2WY9L9JX" }
  });
}


Vue.config.productionTip = false

import FlagIcon from 'vue-flag-icon';
Vue.use(FlagIcon);

new Vue({
  vuetify,
  i18n,
  render: function (h) { return h(App) }
}).$mount('#app')
