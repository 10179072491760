<template>
  <v-card flat>
    <EditImageDialog
      :image="client.account.profile.image"
      :client="client"
      ref="editImageDialog"
      @imageChanged="$refs.profileInfo.update()"
    />
    <v-card-title>
      {{ $t("title") }}
    </v-card-title>
      <DualContentRow>
        <template slot="small">
          <ProfileInfoDisplay
            :client="client"
            :profile="client.account.profile"
            @editImage="$refs.editImageDialog.show('image')"

            ref="profileInfo"
          />
        </template>
        <template slot="large">
          <v-card>
            <v-card-title>
              {{ $t("public_lists") }}
            </v-card-title>
            <v-card-text>
              <v-chip v-if="publicLists.length <= 0">{{
                $t("no_public_lists")
              }}</v-chip>
              <PublicListsSelector
                :lists="publicLists"
                @select="onOpenList($event)"
              />
              <small>{{ $t("public_lists_hint") }}</small>
            </v-card-text>
          </v-card>
        </template>
      </DualContentRow>
  </v-card>
</template>

<script>
import ProfileInfoDisplay from "./ProfileInfoDisplay";
import EditImageDialog from "./image/EditImageDialog";
import PublicListsSelector from "./public/PublicListsSelector";
import DualContentRow from "../generic/layout/DualContentRow";

export default {
  name: "ProfileEditor",
  props: ["client"],
  components: {
    ProfileInfoDisplay,
    EditImageDialog,
    PublicListsSelector,
    DualContentRow,
  },
  computed: {
    publicLists() {
      return this.client.lists.lists.filter((list) => list.public);
    },
  },
  methods: {
    onOpenList(list) {
      this.client.app.openList(list);
    },
  },
};
</script>

<i18n>
{
    "en": {
        "title": "User profile",
        "public_lists": "Public lists",
        "public_lists_hint": "Other people can not edit your shared lists.",
        "no_public_lists": "You have not published any lists yet."
    },
    "de": {
        "title": "Nutzerprofil",
        "public_lists": "Geteilte Listen",
        "public_lists_hint": "Andere Personen können die geteilten Listen nur ansehen, nicht bearbeiten.",
        "no_public_lists": "Du hast noch keine Liste veröffentlicht."
    }
}
</i18n>