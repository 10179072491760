
const UnitConverter = {
    convertToLower(higher, unit) { //Convert kg to g, or lb to oz
        if(unit === 'pounds') {
            return higher * 16;
        } else {
            return higher * 1000;
        }
    },
    convertToHigher(lower, unit) { //Convert g to kg , or oz to lb
        if(unit === 'pounds') {
            return lower / 16;
        } else {
            return lower / 1000;
        }
    },
    toGrams(value, unit) { //Convert oz to grams
        console.log("Converting", value, unit, "to g")
        if(unit === 'pounds') {
            return value * 28.3495;
        } else {
            return value;
        }
    },
    toOunce(value) { //Convert grams to oz
        return value * 0.035274;
    },
    roundValue(value) { //Round to 2 decimals
        return ((value * 100) | 0) / 100;
    },
    formatString(value, unit) { //Auto format weight string
        if(unit === 'pounds') {
            if(value >= UnitConverter.toGrams(UnitConverter.convertToLower(1, 'pounds'), 'pounds')) {
                return `${this.roundValue(this.convertToHigher(this.toOunce(value), 'pounds'), 'pounds')}lbs`;
            } else {
                return this.roundValue(this.toOunce(value)) + 'oz';
            }
        } else {
            if(value >= 1000) {
                return this.roundValue(value / 1000) + 'kg';
            } else {
                return this.roundValue(value) + 'g';
            }
        }
    }
}

export default UnitConverter;