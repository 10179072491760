<template>
     <v-toolbar
      color="cyan"
      flat
      dark
      src="../../assets/bg/bg_2_toolbar.webp"
    >
      <v-app-bar-nav-icon @click="$emit('toggleMenu')"></v-app-bar-nav-icon>

      <v-toolbar-title>Dashboard</v-toolbar-title>

      <v-spacer></v-spacer>

      <LanguageSelector />
    </v-toolbar>
</template>

<script>
import LanguageSelector from '../generic/LanguageSelector';

export default {
    name: 'EditorToolbar',
    components: {
      LanguageSelector
    }
}
</script>