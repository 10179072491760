<template>
  <v-overlay :absolute="true" :value="loading && !hidden" :opacity="0.001">
    <v-progress-circular
      :size="70"
      :width="7"
      color="purple"
      indeterminate
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: "LoaderOverlay",
  props: ["loading"],
  data: () => ({
    hidden: false
  }),
  created() {
    setTimeout(() => {this.hidden = true;}, 4000);
  }
};
</script>